import axios from 'axios';

async function fetchContractDetail(niceNo) {
    try {
        return axios.get(`/v1/api/register/${niceNo}`);
    } catch (error) {
        console.log(error)
    }
}


async function createCompletedContractPDF(mgNo, signedDatas, receiveEmail, emailAddress) {
    try {
        return axios.post(`/v1/api/complete`, {
            mgNo,
            signedDatas,
            receiveEmail,
            emailAddress,
        })
    } catch (error) {
        console.log(error)
    }
}

async function contractWorkTimeChecker(mgNo) {
    try {
        return axios.post(`/v1/api/register/work/${mgNo}`)
    } catch (error) {
        console.log(error)
    }
}

async function contractCancel(mgNo) {
    try {
        return axios.post(`/v1/api/cancel/expired/${mgNo}`)
    } catch (error) {
        console.log(error)
    }
}

async function contractInicisFailed(data) {
    try {
        return axios.post(`/v1/api/cancel/inicis`, {
            bizCode: data.bizCode,
            loanNo: data.loanNo,
            docuNo: data.docuNo,
        })
    } catch (error) {
        console.log(error)
    }
}

async function updateCertificationCount(mgNo, count) {
    try {
        return axios.post(`/v1/api/register/certificate`, {
            mgNo,
            count
        })
    } catch (error) {
        console.log(error)
    }
}

async function completeCertification(mgNo) {
    try {
        return axios.post(`/v1/api/register/certificate/${mgNo}`)
    } catch (error) {
        console.log(error)
    }
}

async function failedCertification(mgNo) {
    try {
        return axios.post(`/v1/api/register/certificate/failed/${mgNo}`)
    } catch (error) {
        console.log(error)
    }
}

export {
    fetchContractDetail,
    createCompletedContractPDF,
    contractWorkTimeChecker,
    contractCancel,
    contractInicisFailed,
    updateCertificationCount,
    completeCertification,
    failedCertification,
}