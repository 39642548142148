<template>
    <div class="signature">
        <div class="backdrop" @click="closePopup"></div>
        <div class="pop_sign _pop_sign01">
            <button class="cancelBtn_icon" @click="closePopup"></button>
            <div class="canvas_wrap">
                <div class="contract_customer_name" :style="{ fontSize: fontSize + 'rem' }">
                    {{ text }}
                </div>
                <canvas
                    class="sign_canvas"
                    ref="signatureCanvas"
                    width="310"
                    height="150"
                ></canvas>
            </div>
            <p class="sign_comment">본인 성명을 정자체로 써주세요.</p>
            <ul class="pop_sign_btn">
                <li>
                    <button class="sign_clear" type="button" @click="clearSignature">
                        다시쓰기
                    </button>
                </li>
                <li>
                    <button class="sign_save" type="button" @click="$emit('save')">
                        저장
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapState,} from "vuex";
import SignaturePad from "signature_pad";

export default {
    data() {
        return {
            signaturePad: null,
            text: '',
            fontSize: 0,
            bizCode: '',
        }
    },
    created() {
        this.text = this.propsName;
        this.bizCode = this.propsBizCode;
        if (this.bizCode === 'tflain001') {
            this.text = ''
        }
        this.calculateFontSize();
    },
    mounted() {
        this.signaturePad = new SignaturePad(this.$refs.signatureCanvas, {
            minWidth: 2,
            maxWidth: 2,
            penColor: "rgb(0, 0, 0)"
        });
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = null;
    },
    props: ['propsName', 'propsBizCode'],
    computed: {
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
        }),
    },
    watch: {
        text() {
            this.calculateFontSize();
        },
    },
    methods: {
        clearSignature() {
            this.signaturePad.clear()
        },
        closePopup() {
            this.$emit('close')
        },
        saveSignature() {
            const isEmpty = this.signaturePad.isEmpty()
            const data = this.signaturePad.toDataURL('image/png')
            return {
                isEmpty,
                data,
            }
        },
        calculateFontSize() {
            const textLength = this.text.length;
            if (textLength <= 3) {
                this.fontSize = 9
            } else if (textLength === 4) {
                this.fontSize = 8
            } else if (textLength === 5) {
                this.fontSize = 6
            } else {
                this.fontSize = 4
            }
        },
    }
}
</script>
