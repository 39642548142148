<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보의 필수적 수집·이용 동의">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">개인(신용)정보의 필수적 수집·이용 동의</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div>
                            본인은「개인정보 보호법」제15조, 제22조, 제24조,「신용정보의 이용 및 보호에 관한 법률」제32조, 제33조, 제34조,「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조, 제26조의2에 따라 귀사가 본인이 신청한 대부거래와 관련하여 본인의 개인(신용)정보를 아래와 같이 수집·이용하는 것에 대하여 동의합니다.
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">수집·이용 목적</div>
                        <div>
                            거래관계의 설정여부 판단, 본인확인, 신용조회, 대금결제 등 대부계약의 체결·유지·이행 - 관리, 신청 금융상품 서비스 제공, 법령상 의무 이행, 분쟁처리, 민원해결, 금융사고 조사 등
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">수집·이용할 정보</div>
                        <div>
                            ▪ 개인식별정보 : 성명, 자택(직장)소, 고유식별정보, 직업, 연락처, 직장명 및 사업자번호, 입금계좌 등<br/>
                            ▪ 신용거래정보 : 본 계약 이전 및 이후의 대부 - 보증·담보제공 등 거래내용을 판단할 수 있는 정보<br/>
                            ▪ 신용능력정보 : 재산·채무·소득의 총액·납세실적 등<br/>
                            ▪ 공공기관정보 : 개인회생, 파산, 면책, 채무불이행 등 법원의 재판·결정 정보, 체납정보, 경매 관련 정보, 사회보험·공공요금 관련 정보, 행정처분에 관한 정보 등<br/>
                            ▪ 채무보증현황: 본 계약 이전 및 이후의 보증현황 포함·신용인증송부로 제출하는 신용정보<br/>
                            ▪ 기타 금융거래의 설정·유지·이행 - 관리를 위한 상담, 채권관리 등을 통해 생성되는 정보 등 (※ 동의 이전에 발생한 상기 개인(신용)정보 포함)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">보유 및 이용기간</div>
                        <div>
                            위 개인(신용)정보는 수집·이용에 관한 동의일로부터 본인이 신청한 대부거래가 귀사에 의해 거절된 시점까지 또는 거래종료(채권·채무관계가 해소된 시점) 후 5년까지 금융사고 조사, 분쟁해결, 민원처리 및 법령상 의무이행을 위해서만 보유 - 이용됩니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>