<template>
    <div>

    </div>
</template>
<script>
export default {
    name: 'DuplicateContractError',
    data() {
        return {
            message: '이미 등록된 계약서 입니다',
        };
    },
    created() {
        alert(this.message);
        window.close()
    },
};
</script>