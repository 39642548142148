<template>
    <div class="container" data-contract-company="aone" data-html-name="신용정보 제공,활용에 대한 고객권리안내">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">신용정보 제공,활용에 대한 고객권리안내</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">
                    <div class="title_wrap">
                        <div class="article_title underline">1. 서비스 이용 범위</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div class="area_block">
                            <div>고객의 신용정보는 고객이 동의한 활용목적만으로 사용되며, 제휴회사 등에 대한 정보의 제공·활용 동의여부와 관계없이 금융서비스를 이용하실 수 있습니다. 다만,
                                제휴회사 등에 대한 정보의 제공·활용에 동의하지 않으시는 경우 제휴 및 부가서비스, 신상품·서비스 등은 제공받지 못할 수도 있습니다.
                            </div>
                        </div>
                    </article>

                    <div class="title_wrap">
                        <div class="article_title underline">2. 고객 신용정보의 제공 및 활용 중단 신청</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div class="flex">
                            <div class="alphabet_align">가.</div>
                            <div>고객은 가입신청시 동의한 본인정보의 제3자(정보제공업체)앞 제공 또는 귀사의 금융상품(서비스) 소개 등 영업목적 사용에 대하여 전체 또는 사안별로
                                제공·활용을 중단 시킬 수 있습니다. 본인정보의 활용 제한·중단을 원하시는 고객은 아래의 매체를 통하여 신청하여 주시기 바랍니다.
                            </div>
                        </div>
                        <div class="flex">
                            <div class="hyphen_align">-</div>
                            <div>신청방법</div>
                        </div>
                        <div class="paragraph_align">·당사 : 전화번호 02&#41; 5533-111 www.lonio.co.kr</div>
                        <div class="flex">
                            <div class="hyphen_align">-</div>
                            <div>신청자 제한 : <span class="emp_red">신규 거래고객은 계약 체결일로부터 3개월간은 신청할 수 없습니다.</span></div>
                        </div>
                        <div class="flex">
                            <div class="alphabet_align">나.</div>
                            <div>위의 신청과 관련하여 불편함을 느끼시거나 애로가 있으신 경우 당사의 정보관리·보호인, 또는 협회 정보보호담당자 앞으로 연락하여 주시기 바랍니다.</div>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="hyphen_align">-</div>
                                <div>연락처</div>
                            </div>
                            <div class="paragraph_align">·당사 고객 정보관리·보호인 연락처 : 고객센터장 전화 1566-0700, 서울 강남구 테헤란로 409 (삼성동,
                                동신빌딩 5, 6층)
                            </div>
                            <div class="paragraph_align">·협회 정보보호담당자 연락처 : 소비자민원상담센터장 전화 02-3487-5800, 서울시 중구 소월로10,
                                13층(남대문로 5가, 단암빌딩)
                            </div>
                        </div>
                    </article>

                    <div class="title_wrap">
                        <div class="article_title underline">3. 고객정보 제공사실 통보 요구 및 오류정보 정정 요구</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div class="flex">
                            <div class="alphabet_align">가.</div>
                            <div>고객은「신용정보의 이용 및 보호에 관한 법률」및「대부업등의 신용정보보호 관리에 관한 권고안」등에 따라 아래의 권리가 부여되어 있습니다. 동권리의 세부내용에
                                대해서는 당사 홈페이지(www.lonio.co.kr) 또는 협회 홈페이지(www.clfa.or.kr)에 게시되어 있으며, 동 권리를 행사하고자 하는 고객은
                                신용정보제공사실 통보 요구권의 경우 당사 고객정보보호담당자에게, 신용정보 열람 및 정정청구는 당사 각 영업점 앞으로 신청하여 주시기 바랍니다.
                            </div>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="hyphen_align">-</div>
                                <div>신용정보제공사실 통보 요구권(법 제35조)</div>
                            </div>
                            <div class="paragraph_align">·고객이 본인의 신용정보를 신용정보업자 등에게 제공한 주요정보 내용 등을 통보 요구할 수 있는 권리</div>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="hyphen_align">-</div>
                                <div>신용정보 열람 및 정정 요구권(법 제38조)</div>
                            </div>
                            <div class="paragraph_align">·고객은 신용정보업자 등이 보유하는 본인정보의 열람 청구가 가능하며 본인정보가 사실과 다른 경우 이의 정정요구 및
                                정정 처리결과에 이의가 있는 경우 금감위에 시정 요청 가능
                            </div>
                        </div>
                        <div class="flex">
                            <div class="alphabet_align">나.</div>
                            <div>고객은 본인 신용정보를 개인신용평가회사를 통하여 연간 일정범위내에서 무료로 확인할 수 있습니다. 자세한 사항은 각 개인신용평가회사에 문의하시기 바랍니다.
                            </div>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="hyphen_align">-</div>
                                <div>연락처</div>
                            </div>
                            <div class="paragraph_align">·NICE평가정보(주) : ☎ 02-2122-4000 www.niceinfo.co.kr</div>
                            <div class="paragraph_align">·서울신용평가정보(주) : ☎ 1577-1006 www.sci.co.kr</div>
                            <div class="paragraph_align">·코리아크레딧뷰로(주) : ☎ 02-708-1000 www.koreacb.com</div>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="nextPage()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
import {mapState,} from 'vuex'

export default {
    data() {
        return {
            activeBtn: true,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
        }),
    },
}
</script>

<style>

</style>