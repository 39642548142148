<template>
    <div>

    </div>
</template>
<script>
export default {
    created() {
        const message = this.$route.query.message;
        alert(message)
    }
};
</script>