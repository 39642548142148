<template>
    <div class="container" data-contract-company="tflain" data-html-name="대출 상품 설명서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">대출 상품 설명서</div>
            </header-black>

            <main class="bottom_btn_case">

                <section class="default">
                    <article>
                        <div class="title_wrap">
                            <div class="article_title underline">1. 상품 개요 및 특성</div>
                        </div>

                        <table class="terms_table">
                            <tbody>
                            <tr>
                                <th>대출기간</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_1" class="terms_depth1_checkbox"
                                               :checked="checkedLoanTermOptions['belowYear'].value" disabled>
                                        <label for="depth1_index_1">
                                            <div class="icon depth1_checkbox_icon"></div>
                                            <div><span class="underline">&nbsp;&nbsp;{{
                                                    checkedLoanTermOptions.belowYear.month
                                                }}&nbsp;&nbsp;</span>개월
                                            </div>
                                        </label>
                                    </div>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_1" class="terms_depth1_checkbox"
                                               :checked="checkedLoanTermOptions['1year'].value" disabled>
                                        <label for="depth1_index_1">
                                            <div class="icon depth1_checkbox_icon"></div>
                                            <div>1년</div>
                                        </label>
                                    </div>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_2" class="terms_depth1_checkbox"
                                               :checked="checkedLoanTermOptions['3year'].value" disabled>
                                        <label for="depth1_index_2">
                                            <div class="icon depth1_checkbox_icon"></div>
                                            <div>3년</div>
                                        </label>
                                    </div>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_4" class="terms_depth1_checkbox"
                                               :checked="checkedLoanTermOptions['etc'].value" disabled>
                                        <label for="depth1_index_4">
                                            <div class="icon depth1_checkbox_icon"></div>
                                            <div>기타( <span
                                                class="underline">&nbsp;&nbsp;{{ checkedLoanTermOptions.etc.month }}&nbsp;&nbsp;</span>개월)
                                            </div>
                                        </label>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>채권보전</th>
                                <td class="terms_checkbox_wrap">

                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_5" class="terms_depth1_checkbox"
                                               :checked="contractDetail.bondPreservation === '01'"
                                               disabled>
                                        <label for="depth1_index_5">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div>담보</div>
                                        </label>
                                    </div>

                                    <div class="checkbox_group with_depth2">
                                        <div>
                                            <input type="checkbox" id="depth1_index_6" class="terms_depth1_checkbox"
                                                   checked
                                                   disabled>
                                            <label for="depth1_index_6">
                                                <div class="depth1_checkbox_icon"></div>
                                                <div>신용</div>
                                            </label>
                                        </div>

                                        <div class="flex">
                                            <span class="mr4">&#40;</span>
                                            <span>
                                                <input type="checkbox" id="depth2_index_1" class="terms_depth2_checkbox"
                                                       disabled>
                                                <label for="depth2_index_1" class="mr8">
                                                    <div class="depth2_checkbox_icon"></div>
                                                    <div>보증</div>
                                                </label>
                                            </span>
                                            <span>
                                                <input type="checkbox" id="depth2_index_2" class="terms_depth2_checkbox"
                                                    checked>
                                                    <label for="depth2_index_2">
                                                        <div class="depth2_checkbox_icon"></div>
                                                        <div>비보증</div>
                                                    </label>
                                                </span>
                                            <span class="ml4">&#41;</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>대부신청금액</th>
                                <td>₩{{ contractDetail.loanAmt | comma }}원</td>
                            </tr>

                            <tr>
                                <th>실제수령금액</th>
                                <td>₩{{ contractDetail.loanAmt | comma }}원</td>
                            </tr>

                            <tr>
                                <th>대출개시일</th>
                                <td>{{ contractDetail.cntrctDt | formatContractDate }}</td>
                            </tr>

                            <tr>
                                <th>대출만기일</th>
                                <td>{{ contractDetail.matDt | formatContractDate }}</td>
                            </tr>

                            <tr>
                                <th>대부이자율</th>
                                <td>고정금리 (연{{ contractDetail.aplyRate }}%)</td>
                            </tr>

                            <tr>
                                <th>거래구분</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_7" class="terms_depth1_checkbox"
                                               checked
                                               disabled>
                                        <label for="depth1_index_7">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div>개별거래</div>
                                        </label>
                                    </div>
                                    <br/>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_8" class="terms_depth1_checkbox"
                                               disabled>
                                        <label for="depth1_index_8">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div>한도거래</div>
                                        </label>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>대부이자율</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_9" class="terms_depth1_checkbox"
                                               checked
                                               disabled>
                                        <label for="depth1_index_9">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div>고정 &#40;연 {{ contractDetail.aplyRate }}%&#41;</div>
                                        </label>
                                    </div>
                                    <br/>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_10" class="terms_depth1_checkbox"
                                               :checked="contractDetail.loanIntRateType === '02'"
                                               disabled>
                                        <label for="depth1_index_10">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div class="flex">
                                                <div>변동</div>
                                                <div>&#40; {{ }} &#41;</div>
                                            </div>
                                        </label>
                                    </div>
                                    <br/>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_11" class="terms_depth1_checkbox"
                                               :checked="contractDetail.loanIntRateType === '03'"
                                               disabled>
                                        <label for="depth1_index_11">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div class="flex">
                                                <div>기타</div>
                                                <div>&#40; {{ }} &#41;</div>
                                            </div>
                                        </label>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>연체이자율</th>
                                <td>
                                    <div>
                                        연체기간 XX일 이하 <span class="underline">연{{ contractDetail.aplyRate }}%</span><br/>
                                        연체기간 XX일 이상 <span class="underline">연{{ contractDetail.aplyRate }}%</span><br/>
                                    </div>
                                    <div class="subtext">* 대부업법상 연체이자율은 "약정금리+3%P 이내"</div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <div class="colspan_case">
                                        <div class="infocheck_active_icon"></div>
                                        <div class="subtext">
                                            대출금리 변동 등으로 상품설명 단계에서 고객님께 적용될 것으로 예상되는 금리와 <span class="emp_bold">최종적으로 적용되는 금리는 달라질 수 있습니다.</span>
                                        </div>
                                    </div>
                                    <div class="colspan_case">
                                        <div class="infocheck_active_icon"></div>
                                        <div class="subtext">
                                            대출계약 체결로 고객님께서 부담해야 하는 금액은 <span class="emp_bold">대출금액과 이자, 수수료 등</span>을
                                            합산한 <span class="emp_bold">총액</span>이며, 정확한 금액은 <span class="emp_bold">대출심사 후에 확인
                                            가능</span>하므로 심사 후 담당자의 안내를 받으시기 바랍니다.
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th rowspan="2">대출상환방식</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_12" class="terms_depth1_checkbox"
                                               :checked="(contractDetail.regType === '01') || (contractDetail.repayMthd === '11301')"
                                               disabled>
                                        <label for="depth1_index_12">
                                            <div class="depth1_checkbox_icon"></div>
                                            <div>일시상환</div>
                                        </label>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth2_index_3" class="terms_depth2_checkbox"
                                                   :checked="(contractDetail.regType === '01') || (contractDetail.repayMthd === '11301')"
                                                   disabled>
                                            <label for="depth2_index_3">
                                                <div class="depth2_checkbox_icon column_text_case"></div>
                                                <div class="subtext">건별거래 : 대부기간 중 이자만 납부하고 대부기간 만료일에 대부원금을 전액 상환</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth2_index_4" class="terms_depth2_checkbox"
                                                   disabled>
                                            <label for="depth2_index_4">
                                                <div class="depth2_checkbox_icon column_text_case"></div>
                                                <div class="subtext">한도거래 : 자유로이 상환하되 대부기간만료일에 대부원금을 전액상환</div>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_13" class="terms_depth1_checkbox"
                                               :checked="contractDetail.repayMthd === '11304'"
                                               disabled>
                                        <label for="depth1_index_13">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>
                                                <div>분할상환</div>
                                                <div class="subtext">
                                                    매 ()개월마다 아래의 방법으로 이자지급일에 분할상환
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth2_index_5" class="terms_depth2_checkbox"
                                                   :checked="contractDetail.repayMthd === '11304'"
                                                   disabled>
                                            <label for="depth2_index_5">
                                                <div class="depth2_checkbox_icon"></div>
                                                <div class="subtext">원리금 균등 분할상환</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth2_index_6" class="terms_depth2_checkbox"
                                                   disabled>
                                            <label for="depth2_index_6">
                                                <div class="depth2_checkbox_icon"></div>
                                                <div class="subtext">원금균등 분할상환</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth2_index_7" class="terms_depth2_checkbox"
                                                   disabled>
                                            <label for="depth2_index_7">
                                                <div class="depth2_checkbox_icon"></div>
                                                <div class="flex">
                                                    <div>기타</div>
                                                    <div>&#40; {{ }} &#41;</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                </td>
                            </tr>

                            <tr>
                                <th rowspan="2">이자지급시기</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_14" class="terms_depth1_checkbox"
                                               disabled>
                                        <label for="depth1_index_14">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>
                                                최초이자는 대부개시일로부터 ( {{ }} )개월 이내에 지급하고,
                                                그 이후 이자는 최종이자계산일 다음날부터 ( {{ }} )개월 이내에 지급
                                            </div>
                                        </label>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_15" class="terms_depth1_checkbox"
                                                   disabled>
                                            <label for="depth1_index_15">
                                                <div class="depth1_checkbox_icon"></div>
                                                <div>이자는 매월 대부개시 해당일에 지급</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_16" class="terms_depth1_checkbox"
                                                   checked
                                                   disabled>
                                            <label for="depth1_index_16">
                                                <div class="depth1_checkbox_icon"></div>
                                                <div>이자는 매월 ( {{ contractDetail.pymtDd }} )일에 지급</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_17" class="terms_depth1_checkbox"
                                                   disabled>
                                            <label for="depth1_index_17">
                                                <div class="depth1_checkbox_icon"></div>
                                                <div class="flex">
                                                    <div>기타</div>
                                                    <div>&#40; {{ }} &#41;</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div class="text_group subtext">
                                        <div>※ 이자지급일 해당월에 지급기일이 없는 경우 그 달의 마지막날을 지급일로 함</div>
                                        <div>※ 대부기간만료일이 토요일 또는 공휴일인 경우 그 만료일은 토요일 또는 공휴일로 하되, 상환기일은 그 다음 영업일로 이연되며 이연기간의
                                            이자는 약정이자로 부담함
                                        </div>
                                        <div>※ 대부이자율 및 연체이자율은 1년을 365일(윤년은 366일)로 보고 1일단위로 계산함</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>이자율등의 제한</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_18" class="terms_depth1_checkbox"
                                                   checked
                                                   disabled>
                                            <label for="depth1_index_18">
                                                <div class="depth1_checkbox_icon column_text_case"></div>
                                                <div>
                                                    이자율(연체이자율 포함)은 법정최고이자율(연20%)를 초과할 수 없으며, 법정최고이자율을 초과하는 부분에 대한 이자는
                                                    무효임. 이때 이자율을 산정함에 있어 사례금, 할인금, 수수료, 공제금, 연체이자, 체당금, 그 밖의 그 명칭에 불구하고
                                                    대부와 관련하여 대부업자가 받는 것은 이를 이자로 봄
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="subtext">
                                        ※ 법정최고금리&#40;연 20%&#41;의 위반 여부는 대부금의 실제사용기간을 대상으로 판단하되, 연 이율과 더불어 이자납입주기가 있는 경우
                                        6개월 10%, 3개월 5%, 월 1.666%, 일 0.0547%임
                                    </div>

                                    <div class="comment_text">
                                        &lt;예시&gt;대부원금 1,000만원인 경우 기간별 이자납입 최고액&#40;소수점 단위 절사&#41;
                                    </div>
                                    <div class="comment_text">
                                        연체일수에 따른 연체이자 한도 :<br/>
                                        1일 5,479원,<br/>
                                        10일 54,794원,<br/>
                                        30일 : 164,383원<br/>
                                        90일 : 493,150원
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>상환계좌</th>
                                <td>
                                    <div>
                                        <div class="mb4">은행명 : {{ contractDetail.repayCatNo }}</div>
                                        <div class="mb4">계좌번호 : {{ contractDetail.repaySsn }}</div>
                                        <div>예금주 : {{ '티플레인대부(주)' }}</div>
                                    </div>
                                    <div class="subtext">※ &#40;대부원금 및 이자는 대출받은 대부업자의 계좌로 입금&#41;</div>
                                </td>
                            </tr>

                            <tr>
                                <th>중도상환 수수료</th>
                                <td class="terms_checkbox_wrap">

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_19" class="terms_depth1_checkbox"
                                                   checked
                                                   disabled>
                                            <label for="depth1_index_19">
                                                <div class="depth1_checkbox_icon column_text_case"></div>
                                                <div>중도상환수수료 없음</div>
                                            </label>
                                        </div>
                                    </div>

                                    <br/>

                                    <div class="checkbox_group">
                                        <div>
                                            <input type="checkbox" id="depth1_index_20" class="terms_depth1_checkbox"
                                                   disabled>
                                            <label for="depth1_index_20">
                                                <div class="depth1_checkbox_icon column_text_case"></div>
                                                <div>
                                                    중도상환대부금액 &#215; &#40;{{ contractDetail.earlyRepayFee }}%&#41; &#215;
                                                    &#40;잔여일수 ÷ 대부기간&#41;
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="subtext text_group">
                                        <div>※ 중도상환수수료란 대출의 상환기일이 도래하기 전에 대출금을 상환할 경우 <span
                                            class="emp_bold">고객이 부담</span>하는 금액임
                                        </div>
                                        <div>※ 다만, 기존 대출 계약을 해지하고 동일 대부회사와 사실상 동일한 계약&#40;기존 계약에 지급된 금전등을 상환 받는 새로운 계약&#41;을
                                            체결한 경우, 양 계약의 유지기간을 합하여 <span class="emp_bold">3년이 경과한 후 해지할 경우</span>에는
                                            <span class="emp_bold">중도상환수수료가 면제됨</span>
                                        </div>
                                        <div>※ 대부기간이 3년을 초과하는 경우 대부기간은 3년으로 함</div>
                                        <div>※ 대부이자, 연체이자, 중도상환수수료 합계금액은 법정최고금리 연 20%내 임</div>
                                    </div>

                                </td>
                            </tr>

                            <tr>
                                <th rowspan="5">비용 및 수수료</th>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_21" class="terms_depth1_checkbox"
                                               :checked="contractDetail.nationalBondPurchaseAmt !== null && contractDetail.nationalBondPurchaseAmt !== ''"
                                               disabled>
                                        <label for="depth1_index_21">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>국민주택채권매입비 - 채무자&#40;또는 설정자&#41;가 부담</div>
                                        </label>
                                    </div>

                                    <div class="textbox_group"
                                         v-if="contractDetail.nationalBondPurchaseAmt !== null && contractDetail.nationalBondPurchaseAmt !== ''">
                                        <input type="text" :placeholder="contractDetail.nationalBondPurchaseAmt | comma"
                                               disabled>
                                        <div>원</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_22" class="terms_depth1_checkbox"
                                               :checked="contractDetail.regLicenseTaxAmt !== null && contractDetail.regLicenseTaxAmt !== ''"
                                               disabled>
                                        <label for="depth1_index_22">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>담보권설정비용(저당권 등 설정과 관련하여 발생하는 등록세, 지방교육세등 담보권 설정에 직접 필요한 비용 및 말소비용) -
                                                채무자(설정자)가 부담
                                            </div>
                                        </label>
                                    </div>
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_23" class="terms_depth1_checkbox"
                                               :checked="contractDetail.regLicenseTaxAmt !== null && contractDetail.regLicenseTaxAmt !== ''"
                                               disabled>
                                        <label for="depth1_index_23">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>법무사 수수료 – 대부업자가 부담</div>
                                        </label>
                                    </div>

                                    <div class="textbox_group"
                                         v-if="contractDetail.regLicenseTaxAmt !== null && contractDetail.regLicenseTaxAmt !== ''">
                                        <input type="text" :placeholder="contractDetail.regLicenseTaxAmt | comma"
                                               disabled>
                                        <div>원</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_24" class="terms_depth1_checkbox"
                                               :checked="contractDetail.mortgAppraisalFee !== null && contractDetail.mortgAppraisalFee !== ''"
                                               disabled>
                                        <label for="depth1_index_24">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>
                                                <div>근저당물건의 조사 또는 감정수수료</div>
                                                <div>설정시 - 대부업자가 부담</div>
                                                <div>행사시 - 채무자&#40;설정자&#41;가 부담</div>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="textbox_group"
                                         v-if="contractDetail.mortgAppraisalFee !== null && contractDetail.mortgAppraisalFee !== ''">
                                        <input type="text" :placeholder="contractDetail.mortgAppraisalFee | comma"
                                               disabled>
                                        <div>원</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="terms_checkbox_wrap">
                                    <div class="checkbox_group">
                                        <input type="checkbox" id="depth1_index_25" class="terms_depth1_checkbox"
                                               :checked="contractDetail.otherCostAmt !== null && contractDetail.otherCostAmt !== ''"
                                               disabled>
                                        <label for="depth1_index_25">
                                            <div class="depth1_checkbox_icon column_text_case"></div>
                                            <div>
                                                <div>기타비용</div>
                                                <div>&#40;항목 : {{ }}&#41;</div>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="textbox_group"
                                         v-if="contractDetail.otherCostAmt !== null && contractDetail.otherCostAmt !== ''">
                                        <input type="text" :placeholder="contractDetail.otherCostAmt | comma" disabled>
                                        <div>원</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div class="text_group subtext">
                                        <div>※ <span class="emp_bold">채무자의 채무불이행</span>으로 인해 발생하는 비용은 <span
                                            class="emp_bold">채무자가 부담</span></div>
                                        <div>※ <span class="emp_bold">대출계약을 철회하는 경우</span> 대부회사가 부담한 제3자 지급비용 등은 「금융소비자
                                            보호에 관한 별률」제46조 및 관련 규정에서 정하는
                                            바에 따라 <span class="emp_bold">채무자가 부담</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </article>

                    <article>
                        <div class="title_wrap">
                            <div class="article_title underline">2. 상환방법 등에 관한 사항</div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">① 상환방법별 특징</div>
                            <div class="chart_img"></div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading">
                                <div class="emp_bold">② 상환방법별 원리금 부담액 예시</div>
                                <div class="comment_text">(1,200만원을 대출기간 5년으로 하여 연 20%로 대출받은 경우)</div>
                            </div>
                            <div class="tablelist_img mb8"></div>
                            <div class="subtext mb8">주&#41; <span class="emp_bold">대출기간</span> 및 <span class="emp_bold">상환방식</span>에
                                따라 <span class="emp_bold">부담</span>하는 <span class="emp_bold">이자</span>는 <span
                                    class="emp_bold">달라집니다.</span></div>
                            <div class="text_group subtext">
                                <div>1. <span class="emp_bold underline">대출기간이 길수록 부담이자는 많아집니다.</span></div>
                                <div>2. 상환방식에 따라 부담이자는 달라집니다. : <span class="emp_bold underline">원금균등상환방식 &lt; 원리금균등상환방식 &lt; 만기일시상환방식</span>
                                </div>
                                <div>3. <span class="emp_bold">만기일시상환’이란</span> 이자납입일을 정하여 일정기간마다 이자를 납입하고, 대출기간 만료일에 전액
                                    상환하는 방식이며, <span class="emp_bold">‘분할상환’이란</span> 대출원금 또는 원리금을 대출 개시일부터 익월 또는 일정주기마다
                                    분할하여 상환하는 방식입니다.
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="title_wrap">
                                <div class="article_title underline">3. 대부이용자 주의사항</div>
                            </div>
                            <div class="emp_bold">① (기한의 이익 상실) 대부기한 전에 채무를 상환해야 하는 경우(대부거래표준약관 제12조 일부 내용)</div>
                            <div class="border_text_box">
                                「기한의 이익」이란 대부업자와의 대부거래에서 채무자인 대부이용자가 당초 약정한 대부기한까지는 대부금을 상환하지 않아도 되는 이익을 말하며, <span
                                class="emp_bold">아래와 같은 사유</span>로 기한의 이익이 상실되면 <span class="emp_bold">대출만료일 이전</span>이라도
                                <span class="emp_bold">대출금 상환 등의 의무가 부여</span>되므로 <span
                                class="emp_bold">주의하셔야 합니다.</span>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">가.</div>
                                <div>채무자에게 다음 각 호의 사유가 발생한 경우에는 대부업자로부터의 독촉ㆍ통지 등이 없어도, 채무자는 기한의 이익을 상실하므로 <span
                                    class="emp_bold">대출만료일 이전</span>이라도 <span class="emp_bold">대출금</span>을 <span
                                    class="emp_bold">상환</span>하여야 합니다.
                                </div>
                            </div>
                            <div class="border_text_box">
                                1. 채무자가 제공한 담보재산에 대하여 압류명령이나 체납처분 압류 통지가 발송된 때 또는 기타의 방법에 의한 강제집행개시나 체납처분착수가 있는 때 2.
                                채무불이행자 명부 등재 신청이 있는 때 3. 어음교환소의 거래정지처분이 있을 때 4. 도피 또는 기타의 사유로 금융기관에서 채무자에 대한 지급을 정지한 것으로
                                인정된 때 5. 파산신청이 있는 때
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">나.</div>
                                <div>채무자에게 다음 각 호의 사유가 발생한 경우 채무자는 기한의 이익 상실로 <span
                                    class="emp_bold">대출만료일 이전이라도 대출금을 상환</span>하여야 합니다. 다만, 대부업자는 기한의 이익상실일 7영업일전까지 다음 각
                                    호의 채무이행지체사실과 이에 따라 기한의 이익이 상실된다는 사실을 채무자에게 통지하여야 하며, 기한의 이익상실일 7영업일전까지 통지하지 않은 경우에는
                                    채무자는 실제통지가 도달한 날부터 7영업일이 경과한 날에 기한의 이익을 상실합니다.
                                </div>
                            </div>
                            <div class="border_text_box area_block_clear">
                                1. 이자를 지급하여야 할 때부터 &#40;<span class="emp_gray">2개월간 지체</span>&#41;한 때
                                2. 분할상환금 또는 분할상환원리금의 지급을 &#40;<span class="emp_gray">2회 이상 연속하여 지체</span>&#41;하고 그 금액이
                                대출금의 10분의1을 초과하는 경우
                            </div>
                        </div>

                        <div class="area_block">
                            <span class="emp_bold">② (채권의 양도)</span> 대부업자가 계약서상 채권을 제3자에게 양도할 수 있으나, 채권양도에 관하여는 반드시 사전에
                            채무자 및 보증인에게 동의를 얻어야 합니다.
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ③ (연체정보 등 등록)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>대부원금, 이자 등을 3개월 이상 연체한 경우에는 3개월이 되는 날을 등록사유 발생일로 하여 그 때로부터 7영업일 이내에 연체 정보 등이
                                        등록되며 연체정보 등이 등록되면 금융거래 제약 등이 발생할 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>연체정보 등이 정보거래처로 등록된 후 연체금액을 상환하여 등록사유가 해제되는 경우에도 등록기간 및 금액에 따라 해제기록이 1년 동안 남아있을
                                        수 있어 동 기록으로 인하여 금융상의 불편이 초래될 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div>또한, "연체기간 5영업일 이상 연체금액 10만원 이상"의「단기연체⌋가 발생한 경우에도 신용조회회사를 통해 금융회사간에 해당정보가 공유되어
                                        신용등급 또는 개인신용평점 하락의 원인이 될 뿐아니라 신용카드 사용정지 등 금융거래가 제한되어 예상하지 못한 불편이 발생할 수 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <span class="emp_bold">④ (대부중개수수료)</span> 대부이용자 등은 대부계약의 중개와 관련한 대부중개수수료를 지급할 의무가 없습니다.
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑤ (대출사기에 주의)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>"저금리로 대환 대출 해주겠다"는 제안 등은 대출빙자형 사기일 가능성이 매우 높음</div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>"신용등급 또는 개인신용평점 상향조정", "대출진행비용" 등을 요구하는 대출사기에 주의</div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <span class="emp_bold">⑥ (대부원리금 상환)</span> 대부계약서에 명시된 대부원리금 상환계좌로만 대부원금 및 이자를 상환하여야 합니다.
                        </div>

                        <div class="area_block">
                            <span class="emp_bold">⑦ (서민정책 금융상품 활용)</span> 정부에서 대학생·청년증을 위한 공적지원제도 및 서민금융정책상품을 운영 중이므로
                            신청대상인지 먼저 확인하시기 바랍니다.
                        </div>

                        <div class="area_block">
                            <span class="emp_bold">⑧ (연체이자율 상한)</span> 대부이용자에 대한 대부업자의 대출의 연체이자율은 "약정금리+3%p" 이내로 제한되어
                            있으므로 대부계약 시 연체 이자율을 반드시 확인하시기 바랍니다.
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑨ (청약의 철회)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>일반금융소비자(개인 및 개인사업자, 5인 미만의 법인 또는 일반금융소비자로 분류되길 요청한 법인)는 <span
                                        class="emp_bold emp_size">계약체결일 또는 계약서류를 제공받은 날(다만 금전·재화·용역의 지급이 늦게 이루어진 경우에는 그 지급일로 대체함)로부터 14일 이내</span>
                                        계약에 대한 청약을 철회할 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>청약의 철회 의사를 표시하기 위해서는 <span class="emp_bold emp_size">서면 등으로 의사표시</span>를 해야하며,
                                        <span class="underline">이미 공급받은 금전·재화와 그에 대한 이자(금전을 지급받은 날부터 금전을 돌려준 날까지의 기간에 대해 약정된 이자율과 공급받은 금전을 곱한 금액)를 반환</span>하여야
                                        합니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div>대출계약 철회권을 남용하여 해당 대부회사를 대상으로 최근 <span
                                        class="underline">1개월 내에 2회 이상 대출계약을 철회</span>하는 경우, <span
                                        class="emp_bold emp_size">신규대출·만기연장 거절, 대출한도 축소, 금리우대 제한 등 불이익이 발생</span>할 수
                                        있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">라.</div>
                                    <div>기타 세부사항은 대출거래약정서를 참고하여 주시기 바랍니다.(약정서에 해당 내용이 있는 경우에만 차용)</div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑩ (위법계약 해지권)
                            </div>

                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>금융회사가 ⌈금융소비자보호에 관한 법률⌋상 다음의 의무를 위반하여 대출계약을 체결한 경우, 금융소비자는 해당 계약을 위약금 등 <span
                                        class="emp_bold emp_size">수수료 부과 없이 해지할 수 있습니다.</span></div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>적합하지 아니하다고 인정되는 대출계약의 체결을 권유한 경우(법 제17조제3항 위반)</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>대출상품이 적정하지 않은 경우에도 사실을 알리지 않거나 확인받지 않은 경우(법 제18조제2항 위반)</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>설명의무를 이행하지 않은 경우(법 제19조제1항·제3항 위반)</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>불공정영업행위를 한 경우(법 제20조제1항 위반)</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>부당권유행위를 한 경우(법 제21조 위반)</div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>금융소비자는 위법계약해지권 행사를 위해 <span class="emp_bold emp_size">계약 체결일로부터 5년 이내</span>
                                        범위에서 <span class="emp_bold emp_size">위반사실을 안 날로부터 1년 이내</span>에 계약해지요구서에 위반사실을
                                        입증하는 서류를 첨부하여 계약의 해지를 요구하여야 합니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑪ (자료열람요구권)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>금융소비자는 분쟁조정 또는 소송의 수행 등 권리구제를 위한 목적으로 대부회사가 기록 및 유지·관리하는 다음의 자료에 대한 열람(사본 및 청취
                                        포함)을 요구할 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>계약체결에 관한 자료, 계약의 이행에 관한 자료, 금융상품 등에 관한 광고 자료, 금융소비자의 권리행사에 관한 자료, 업무 위탁에 관한
                                        자료
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>대부회사는 금융소비자의 분쟁조정 신청내역 또는 소송제기 등의 목적 및 열람의 범위가 기재된 열람요구서로 열람을 요구받은 날로부터 <span
                                        class="emp_bold">8일 이내</span>에 금융소비자가 해당 자료를 열람할 수 있게 합니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div>대부회사는 법령, 제3자의 이익 침해, 영업비밀의 침해 등의 사유가 있는 경우 이를 금융소비자에게 알리고 <span
                                        class="emp_bold emp_size">자료 열람을 제한하거나 거절할 수 있습니다.</span></div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading">
                                <span class="emp_bold">⑫ (담보권 설정에 관한 사항)</span> ☞ 담보를 설정하는 경우에만 내용 작성
                            </div>

                            <div class="border_text_box">
                                ※ 담보의 제공은 고객님의 <span class="emp_bold">재산상 손실</span>을 가져올 수도 있는 중요한 법률행위이므로 반드시 별도로 작성하시는
                                근저당권(근질권)설정계약서의 내용을 상세하게 확인하시기 바랍니다.
                            </div>

                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>담보권의 설정</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div>대출과 관련하여 담보물을 제공한 경우, 대부회사는 채권보전을 위해 제공받은 담보물에 담보권(저당권·질권 등)을 설정할 수 있습니다.</div>
                                </div>

                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>담보권의 실행 및 실행에 따른 권리변동</div>
                                </div>
                                <div class="flex">
                                    <div class="hyphen_align">-</div>
                                    <div><span class="emp_bold emp_size">대출의 변제기가 도래</span>하였거나 <span
                                        class="emp_bold emp_size">기한의 이익이 상실</span>>되었음에도 채무가 상환되지 않는 경우, 대부회사는 법정절차*에
                                        따라 담보물을 처분하여 채무의 변제에 충당할 수 있으며, 이 경우 담보제공자는 담보물에 대한 <span
                                            class="emp_bold emp_size">소유권을 상실</span>하게됩니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="asterisk_align">*</div>
                                    <div>민사집행법에 따라 담보물을 경매(매각 및 금전으로 환가)한 후 그 경매대금에 대하여 다른 채권자에 우선하여 변제</div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑬ (계약기간, 연장에 관한 사항)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div><span class="emp_bold underline">최초 심사에 비해 신용점수가 하락하거나 연체가 발생</span>하는 경우,
                                        <span class="emp_bold emp_size">대출계약의 연장이 거절</span>될 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>일정한 자격을 갖춘 채무자를 대상으로 한 대출(공무원대출, 전문직대출 등)의 경우 같은 <span
                                        class="emp_bold emp_size">자격이 유지되는 경우</span>에만 기한이 연장될 수 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ⑭ (계약의 해지에 관한 사항)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>대출계약의 해지 시 중도상환수수료가 부과될 수 있습니다.</div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>대출신청 이후 고객에 의한 대출취소 또는 대출 심사거절 시 발생한 비용(법무사수수료, 감정수수료 등)을 고객이 부담하여야 합니다.</div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div class="title_wrap">
                            <div class="article_title underline">3. 기타 유의사항</div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ① 이자율의 산출기준(대출금리 결정 요인)
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>대출금리는 <span class="emp_bold">원가요소와 마진(목표이익률)을 반영하여 결정</span>됩니다.</div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>원가요소는 신용원가, 업무원가, 조달원가 등으로 구분하여 금융회사가 합리적인 기준에 따라 체계적으로 산정합니다.</div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div><span class="emp_bold">고객에게 실제 적용되는 약정금리</span>는 <span class="underline">고객의 개인신용평점, 거래실적, 대출조건 등에 따라 가감조정하여 반영</span>됩니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">라.</div>
                                    <div>기한연장, 채무자변경 등 대출조건이 변경되는 경우에는 변경 당시의 원가요소와 마진을 기준으로 대출금리가 변경될 수 있습니다.</div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ② 개인신용평점 관련
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div><span class="emp_bold">신용공여(대출 등) 사실</span>만으로 <span class="emp_bold">개인신용평점이 하락</span>할
                                        수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div><span class="emp_bold">평균적으로 연체율이 높은 금융권역의 신용공여</span>는 은행 등 <span
                                        class="emp_size emp_bold underline">다른 금융권역의 신용공여보다 신용점수가 더 큰 폭으로 하락</span>할 수
                                        있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div><span class="emp_bold">평균적으로 연체율이 높은 형태의 신용공여</span>는 <span
                                        class="emp_bold emp_size underline">일반적인 신용공여보다 개인신용평점이 더 큰 폭으로 하락</span>할 수
                                        있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">라.</div>
                                    <div>해당 금융거래가 변제나 이에 준하는 방식으로 거래가 종료된 경우에도 <span class="emp_bold">일정기간 개인신용평점의 산정에 영향</span>을
                                        줄 수 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="area_block">
                            <div class="article_subheading emp_bold">
                                ③ 대출 이용관련 에로사항 상담·문의처
                            </div>
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">가.</div>
                                    <div>금융상품과 관련한 의문사항 또는 불만(민원)이 있을 경우, 당사 고객센터(<span class="emp_blue emp_bold">02-6010-0511</span>)로
                                        문의하실 수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">나.</div>
                                    <div>분쟁이 발생한 경우에는 금융감독원 e-금융민원센터(http://www.fcsc.kr) 또는 대표번호(국번없이 ☏1332) 등에 도움을 요청하실
                                        수 있습니다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">다.</div>
                                    <div>기타 애로사항 등은 하단 문의처에 문의하시기 바랍니다.</div>
                                </div>
                            </div>
                        </div>

                    </article>

                    <article>
                        <table class="terms_table counsel_table">

                            <tbody>
                            <tr>
                                <th>구분</th>
                                <th>상담·문의처</th>
                            </tr>
                            <tr>
                                <td>대부업등록여부조회</td>
                                <td>금융감독원(1332)</td>
                            </tr>
                            <tr>
                                <td>이자계산기</td>
                                <td>금융감독원(1332)</td>
                            </tr>
                            <tr>
                                <td>서민정책금융상품</td>
                                <td>서민금융진흥원(1397)</td>
                            </tr>
                            <tr>
                                <td>민원·분쟁 신청</td>
                                <td>금융감독원(1332)</td>
                            </tr>
                            <tr>
                                <td>불법대부·추심신고</td>
                                <td>금융감독원(1332)</td>
                            </tr>
                            <tr>
                                <td>무료법률상담</td>
                                <td>대한법률구조공단(132)</td>
                            </tr>
                            </tbody>

                        </table>
                    </article>

                    <article>
                        <div class="border_text_box none_area">
                            이 설명서는 대부이용자의 상품에 대한 이해를 돕고 약관의 중요내용을 알려드리기 위한 참고자료이며, 실제 대부계약은 대부거래계약서, 대부거래표준약관의 적용을 받습니다.
                            계약을 신청하는 경우 계약서류가 교부됩니다.
                        </div>
                    </article>
                </section>

                <section class="bottomBtn_wrap">
                    <bottom-btn>
                        <!-- active 활성화 -->
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeProductInstruction()"
                        >
                            확인
                        </button>
                    </bottom-btn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.prepareDataForBinding(this.contractDetail)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    data() {
        return {
            contractDetail: null,
            checkedLoanTermOptions: {
                'belowYear': {value: false, month: ''},
                '1year': {value: false, month: '12'},
                '3year': {value: false, month: '36'},
                'etc': {value: false, month: ''}
            },
        }
    },
    computed: {
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
        }),
    },
    methods: {
        closeProductInstruction() {
            this.$emit('close')
        },
        prepareDataForBinding(contractDetail) {
            const termMap = {'12': '1year', '36': '3year'}
            const termKey = termMap[contractDetail.loanTrm]
            if (termKey) {
                this.checkedLoanTermOptions[termKey].value = true
            } else if (this.contractDetail.loanTrm < 12) {
                this.checkedLoanTermOptions['belowYear'].value = true
                this.checkedLoanTermOptions['belowYear'].month = contractDetail.loanTrm
            } else {
                this.checkedLoanTermOptions['etc'].value = true
                this.checkedLoanTermOptions['etc'].month = contractDetail.loanTrm
            }
        },
    },
}
</script>

<style>

</style>