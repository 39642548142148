import axios from "axios";

async function fetchElectronicSignature(mgNo) {
    try {
        return axios.get(`/v1/api/inicis/${mgNo}`);
    } catch (error) {
        console.log(error)
    }
}

export {
    fetchElectronicSignature,
}