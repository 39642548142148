export default {
    name: 'scrollMixin',
    data() {
        return {
            activeBtn: true,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            const scrollTop = Math.ceil((window.scrollY || document.documentElement.scrollTop) + window.visualViewport.height);
            const clientHeight = Math.ceil(document.documentElement.clientHeight)
            const scrollHeight = Math.ceil(document.documentElement.scrollHeight)
            const browserDefaultHeight = Math.ceil(window.visualViewport.height - clientHeight)

            // console.log(scrollTop)

            if (scrollTop + browserDefaultHeight >= scrollHeight) {
                this.activeBtn = true
            } else {
                this.activeBtn = false
            }
        },
    },
}


