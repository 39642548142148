<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보의 선택적 수집·이용 동의">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">개인(신용)정보의 선택적 수집·이용 동의</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div class="terms_title">수집·이용 목적</div>
                        <div>
                            당사 및 관계사의 상품 서비스 소개 및 판매권유, 사은 행사 및 판촉행사, 대출이용권유, 문자메시지 서비스 제공 등의 마케팅 활동, 내부 시장조사 및 상품개발 및 연구 등의 수행
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">수집·이용할 정보의 내용</div>
                        <div>
                            ▪ 개인식별정보 : 성명, 주소, 고유식별정보, 성별, 국적, 직업, 연락처, 이메일 등<br/>
                            ▪ 신용능력정보 : 재산·채무·소득의 총액·납세실적 등<br/>
                            ▪ 신용거래정보 : 본 계약 이전 및 이후의 실적을 포함한 거래내용<br/>
                            ▪ 채무보증현황 : 본 계약 이전 및 이후의 보증현황 포함<br/>
                            ▪ 신용인증송부로 제출하는 신용정보<br/>
                            ▪ 주거정보, 맞벌이 유무, 직장 근속기간, 우편물 수령처<br/>
                            ▪ 기타 금융거래의 설정·유지·이행·관리를 위한 상담, 채권관리 등을 통해 생성되는 정보 등
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">보유 및 이용기간</div>
                        <div>
                            위 개인(신용)정보는 수집·이용 동의일로부터 본인이 신청한 대부거래가 귀사에 의해 거절된 시점까지, 대부거래가 설정된 경우에는 본인의 수집·이용 동의 철회 시까지 또는 거래종료일(채권·채무관계가 해소된 시점)까지 (마케팅정보는 거래종료일 이후 3개월까지) 보유·이용됩니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>