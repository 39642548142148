import {store} from "@/store";

import regType21_01 from "@/views/aone/EC05AO_COLLATERAL.vue";

export default [
    {
        path: '/contract/real_estate/collateral',
        component: regType21_01,
        name: 'regType21_01',
        props: {nextComponent: 'CompleteContract'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q)
                localStorage.removeItem('vuex');
                store.commit('AoneIndividualContractStore/RESET');
                if (response.data.result === true) {
                    next();
                } else {
                    localStorage.removeItem('vuex');
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
]