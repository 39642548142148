<template>
  <div class="bottom_container">
    <slot name="bottom_btn">
      <button slot="bottom_btn" class="primary_btn">
        default
      </button>
    </slot>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>
  
</style>