<template xmlns:slot="http://www.w3.org/1999/xhtml">
    <div class="container" data-contract-company="aone" data-html-name="대출금 제3자 지급 동의서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <div slot="title">대출금 제3자 지급 동의서</div>
            </header-blue>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="confirmation_article">
                        <div class="subtext">주식회사 에이원대부캐피탈 귀중</div>
                    </article>

                    <article>
                        <div>상기인을 대리인으로 정하고 다음 사항을 위임합니다.</div>
                    </article>

                    <article class="user_info_article confirmation" v-for="(item, index) in financialCompanies"
                             :key="index">
                        <div class="title_wrap">
                            <div class="article_title">{{ index + 1 }}.송금내역</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">금융회사<br/>또는 기관</div>
                            <div>{{ item.financial_company_name }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">입금은행명</div>
                            <div>{{ item.deposit_bank_name }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">입금계좌<br/>번호</div>
                            <div>{{ item.deposit_account_number }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">예금주명</div>
                            <div>{{ item.depositor_name }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">상환금액</div>
                            <div>{{ item.repayment_amount | comma }}</div>
                        </div>
                    </article>

                    <article>
                        <div class="label_case">총 금액 합</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.loanPayTotalLoanAmount | comma }}원</div>
                        </div>
                    </article>

                    <article>
                        <div>귀사에서 승인된 대출금 중 일부 또는 전부를 본인의 계좌 외 상기 계좌로 송금하는 것에 동의합니다.</div>
                    </article>

                    <article>
                        <div class="label_case">동의일자</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.loanPayDocumentDate | formatDate }}</div>
                        </div>
                    </article>

                    <article>
                        <div data-sign="sign_field">
                            <div class="label_case">본인</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        ref="kornNm"
                                        :placeholder="contractDetail.loanPayDebtorName"
                                        :value="returnName"
                                        :class="{ blue: signatureColor }"
                                        :disabled="!signatureColor"
                                        @input="isValidName"
                                        @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignReaded()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap" v-if="nextComponent === 'complete'">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureReaded v-if="showSignatureReaded" ref="signaturePad" @close="closeSignReaded()"
                         @save="saveSignatureProvideSecurity" :propsName="contractDetail.loanPayDebtorName"/>
        <InicisComponent ref="openPopup"/>
    </div>
</template>

<script>
import SignatureReaded from '@/components/common/SignaturePad.vue'

import {mapGetters, mapState,} from 'vuex'
import InicisComponent from "@/components/InicisComponent.vue";
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        SignatureReaded,
        InicisComponent,
    },
    data() {
        return {
            contractDetail: null,
            financialCompanies: null,
            showSignatureReaded: false,
            isKornNm: false,

            // check
            isKornNmModal: false,
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.financialCompanies = this.contractDetail.financialCompanies
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC11AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        returnName() {
            return this.EC11AO.name
        },
        signatureColor() {
            return this.EC11AO.nameColorValid
        },
        signedData() {
            const data = this.EC11AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        isDisabled() {
            return this.EC11AO.nameValid && this.EC11AO.signedDataValid
        },
        contractSignedData() {
            return {
                signedData01: this.EC11AO.signedData,
            }
        },
    },
    methods: {
        isValidName(e) {
            if (this.contractDetail.loanPayDebtorName === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC11AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC11AO_SIGNATURE_COLOR', false)
            }
        },
        saveSignatureProvideSecurity() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC11AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureReaded = !this.showSignatureReaded
        },
        closeSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        openSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        isValidKornNm(e) {
            if (this.contractDetail.loanPayDebtorName !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureReaded = !this.showSignatureReaded
        },
        async completeContract() {
            if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC11AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
    }
}
</script>

<style>

</style>