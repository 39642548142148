import CompleteContract from "@/views/common/tflain/CompleteContract.vue";
import InicisSuccessComponent from "@/views/common/tflain/InicisSuccessComponent.vue";

export default [
    {
        path: '/t-flain/completeContract',
        component: CompleteContract,
        name: 'TflainCompleteContract',
    },
    {
        path: '/t-flain/inicisSuccessComponent',
        component: InicisSuccessComponent,
        name: 'TflainInicisSuccessComponent',
    },
]