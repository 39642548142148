import axios from 'axios'

async function incrementContractSignatureCount(inicisResult) {
    try {
        return axios.post(`/v1/api/count/increment/signature`, inicisResult)
    } catch (error) {
        console.log(error)
    }
}

export {
    incrementContractSignatureCount,
}