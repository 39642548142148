import Vue from 'vue'
import VueRouter from "vue-router";

// common
import commonRouter from '@/views/common/aone'
import commonComponent from '@/components/common/index'
import tFlainRouter from '@/views/common/tflain'
import errorRouter from '@/views/error/index'
import TestComponent from "@/views/aone/TestComponent.vue";
// import see from "@/views/common/aone/CompleteContract.vue";
import see from "@/views/aone/EC04AO.vue";

// aone
import EC01AO from '@/views/aone/EC01AO.vue';
import EC02AO from '@/views/aone/EC02AO.vue';
import EC03AO from '@/views/aone/EC03AO.vue';
import EC04AO from '@/views/aone/EC04AO.vue';
import EC05AO_DEBTOR from '@/views/aone/EC05AO_DEBTOR.vue';
import EC05AO_COOWNER from '@/views/aone/EC05AO_COOWNER.vue';
import EC05AO_COLLATERAL from '@/views/aone/EC05AO_COLLATERAL.vue';
import EC06AO_DEBTOR from '@/views/aone/EC06AO_DEBTOR.vue';
import EC06AO_COOWNER from '@/views/aone/EC06AO_COOWNER.vue';
import EC06AO_COLLATERAL from '@/views/aone/EC06AO_COLLATERAL.vue';
import EC07AO from '@/views/aone/EC07AO.vue';
import EC08AO from '@/views/aone/EC08AO.vue';
import EC09AO from '@/views/aone/EC09AO.vue';
import EC10AO from '@/views/aone/EC10AO.vue';
import EC11AO from '@/views/aone/EC11AO.vue';

import AoneRegType01Router from '@/views/aone/AoneRegType01Router.js';
import AoneRegType02Router from '@/views/aone/AoneRegType02Router.js';
import AoneRegType04Router from '@/views/aone/AoneRegType04Router.js';
import AoneRegType06Router from '@/views/aone/AoneRegType06Router.js';
import AoneRegType08Router from '@/views/aone/AoneRegType08Router.js';
import AoneRegType15Router from '@/views/aone/AoneRegType15Router.js';
import AoneRegType16Router from '@/views/aone/AoneRegType16Router.js';
import AoneRegType17Router from '@/views/aone/AoneRegType17Router.js';
import AoneRegType18Router from '@/views/aone/AoneRegType18Router.js';
import AoneRegType19Router from '@/views/aone/AoneRegType19Router.js';
import AoneRegType20Router from '@/views/aone/AoneRegType20Router.js';
import AoneRegType21Router from '@/views/aone/AoneRegType21Router.js';
import AoneRegType22Router from '@/views/aone/AoneRegType22Router.js';

// tflain
import EC01TF from "@/views/tflain/EC01TF.vue";
import EC02TF from "@/views/tflain/EC02TF.vue";
import EC03TF from "@/views/tflain/EC03TF.vue";
import EC04TF from "@/views/tflain/EC04TF.vue";
import EC05TF from "@/views/tflain/EC05TF.vue";
import EC06TF from "@/views/tflain/EC06TF.vue";
import EC07TF from "@/views/tflain/EC07TF.vue";
import EC08TF from "@/views/tflain/EC08TF.vue";

import TflainRegType01Router from "@/views/tflain/TflainRegType01Router";
import TflainRegType02Router from "@/views/tflain/TflainRegType02Router";
import TflainRegType03Router from "@/views/tflain/TflainRegType03Router";
import InicisResultComponent from "@/views/common/index";

import IndexComponent from "@/views/common/IndexComponent.vue";

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: IndexComponent,
            component: IndexComponent
        },
        // common Router
        ...commonRouter,
        ...commonComponent,
        ...tFlainRouter,
        ...InicisResultComponent,
        ...errorRouter,
        // aone RegType
        ...AoneRegType01Router,
        ...AoneRegType02Router,
        ...AoneRegType04Router,
        ...AoneRegType06Router,
        ...AoneRegType08Router,
        ...AoneRegType15Router,
        ...AoneRegType16Router,
        ...AoneRegType17Router,
        ...AoneRegType18Router,
        ...AoneRegType19Router,
        ...AoneRegType20Router,
        ...AoneRegType21Router,
        ...AoneRegType22Router,
        // aone Router
        {
            name: 'EC01AO',
            path: '/EC01AO',
            component: EC01AO,
        },
        {
            name: 'EC02AO',
            path: '/EC02AO',
            component: EC02AO,
        },
        {
            name: 'EC03AO',
            path: '/EC03AO',
            component: EC03AO,
        },
        {
            name: 'EC04AO',
            path: '/EC04AO',
            component: EC04AO,
        },
        {
            name: 'EC05AO_DEBTOR',
            path: '/EC05AO_DEBTOR',
            component: EC05AO_DEBTOR,
        },
        {
            name: 'EC05AO_COOWNER',
            path: '/EC05AO_COOWNER',
            component: EC05AO_COOWNER,
        },
        {
            name: 'EC05AO_COLLATERAL',
            path: '/EC05AO_COLLATERAL',
            component: EC05AO_COLLATERAL,
        },
        {
            name: 'EC06AO_DEBTOR',
            path: '/EC06AO_DEBTOR',
            component: EC06AO_DEBTOR,
        },
        {
            name: 'EC06AO_COOWNER',
            path: '/EC06AO_COOWNER',
            component: EC06AO_COOWNER,
        },
        {
            name: 'EC06AO_COLLATERAL',
            path: '/EC06AO_COLLATERAL',
            component: EC06AO_COLLATERAL,
        },
        {
            name: 'EC07AO',
            path: '/EC07AO',
            component: EC07AO,
        },
        {
            name: 'EC08AO',
            path: '/EC08AO',
            component: EC08AO,
        },
        {
            name: 'EC09AO',
            path: '/EC09AO',
            component: EC09AO,
        },
        {
            name: 'EC10AO',
            path: '/EC10AO',
            component: EC10AO,
        },
        {
            name: 'EC11AO',
            path: '/EC11AO',
            component: EC11AO,
        },
        {
            name: 'TestComponent',
            path: '/TestComponent',
            component: TestComponent,
        },
        // tflain RegType
        ...TflainRegType01Router,
        ...TflainRegType02Router,
        ...TflainRegType03Router,
        // tflain Router
        {
            name: 'EC01TF',
            path: '/EC01TF',
            component: EC01TF,
        },
        {
            name: 'EC02TF',
            path: '/EC02TF',
            component: EC02TF,
        },
        {
            name: 'EC03TF',
            path: '/EC03TF',
            component: EC03TF,
        },
        {
            name: 'EC04TF',
            path: '/EC04TF',
            component: EC04TF,
        },
        {
            name: 'EC05TF',
            path: '/EC05TF',
            component: EC05TF,
        },
        {
            name: 'EC06TF',
            path: '/EC06TF',
            component: EC06TF,
        },
        {
            name: 'EC07TF',
            path: '/EC07TF',
            component: EC07TF,
        },
        {
            name: 'EC08TF',
            path: '/EC08TF',
            component: EC08TF,
        },
        {
            name: 'see',
            path: '/see',
            component: see,
        },
    ]
})