<template>
    <div class="container" data-contract-company="aone" data-html-name="담보제공승낙서_부동산">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <div slot="title">부동산 담보제공승낙서</div>
            </header-blue>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="confirmation_article">
                        <div class="subtext">주식회사 에이원대부캐피탈 귀중</div>
                    </article>

                    <article>
                        <div>금번 <span class="emp_bold underline">{{ contractDetail.rcollateralDebtorName }}</span>이/가 현재 또는 장래에 부담하는 일체의 채무에 대하여 본인 소유의
                            하기 표시의 부동산을 담보 제공하고 채권 최고액 금 <span class="emp_bold underline">{{ contractDetail.rcollateralPledgeAmount | comma }}</span>정의
                            근저당권 설정을 승낙하오며 근저당권 설정계약과 관련한 담보제공자로서의 법적의무를 성실히 이행할 것을 확약함.
                        </div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="title_wrap">
                            <div class="article_title">1. 부동산의 표시</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">소재지</div>
                            <div>{{ contractDetail.rcollateralPropertyAddress }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">소유자</div>
                            <div>{{ contractDetail.rcollateralOwnerName }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">대지</div>
                            <div>{{ contractDetail.rcollateralLand }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">건물</div>
                            <div>{{ contractDetail.rcollateralBuilding }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">기타</div>
                            <div>{{ contractDetail.rcollateralOther }}</div>
                        </div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="title_wrap">
                            <div class="article_title">2. 제공자 확인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.rcollateralProviderAddress }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.rcollateralProviderBirth | formatContractDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">성명</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        ref="kornNm"
                                        :placeholder="contractDetail.rcollateralProviderName"
                                        :value="returnName"
                                        :class="{ blue: signatureColor }"
                                        :disabled="!signatureColor"
                                        @input="isValidName"
                                        @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignReaded()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureReaded" ref="signaturePad" @close="closeSignReaded()"
                         @save="saveSignatureProvideSecurity" :propsName="contractDetail.rcollateralProviderName"/>
        <InicisComponent ref="openPopup"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SignatureDebtor from "@/components/common/SignaturePad.vue";
import InicisComponent from "@/components/InicisComponent.vue";
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        SignatureDebtor,
        InicisComponent,
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC08AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
        }),
        returnName() {
            return this.EC08AO.name
        },
        signatureColor() {
            return this.EC08AO.nameColorValid
        },
        signedData() {
            const data = this.EC08AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        contractSignedData() {
            return {
                signedData01: this.EC08AO.signedData,
            }
        },
        isDisabled() {
            return this.EC08AO.nameValid && this.EC08AO.signedDataValid
        },
    },
    data() {
        return {
            contractDetail: null,
            isLoading: false,
            showSignatureReaded: false,
            isKornNm: false,

            // check
            isKornNmModal: false,
            isKornNmSignatureModal: false,
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        openSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        saveSignatureProvideSecurity() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC08AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureReaded = !this.showSignatureReaded
        },
        isValidName(e) {
            if (this.contractDetail.rcollateralProviderName === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC08AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC08AO_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.rcollateralProviderName !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureReaded = !this.showSignatureReaded
        },
        async completeContract() {
            if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC08AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
    },
}
</script>

<style>

</style>