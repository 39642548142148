<template>
    <div class="modal_wrap" data-contract-company="tflain">
        <div class="backdrop"></div>
        <div class="modal_content_wrap contract_personally_certify">

            <div class="modal_content">
                <div class="logo_img"></div>
                <div class="certify_content">
                    <div class="certify_title">주민등록번호 생년월일 6자리</div>
                    <div class="input_group certify">
                        <input
                            id="personallyCertifyInput"
                            class="personally_certify_input"
                            type="text"
                            inputmode="numeric"
                            maxlength="6"
                            @input="bindNumber"
                            v-model="inputtedNumber"
                            ref="inputRef"
                            @keydown.enter="isValidBirthday()"
                        >
                        <label for="personallyCertifyInput">
                            <ul>
                                <li
                                    v-for="(dot, index) in 6"
                                    :key="index"
                                    :class="['number_dot', index < inputtedNumber.length ? 'enter' : '']">
                                </li>
                            </ul>
                        </label>
                    </div>

                    <div>
                        <div class="certify_wrong" :class="{ rejection: rejectCertify }" v-if="rejectCertify">
                            본인인증이 <span class="emp_bold">{{ certifyWrongCount }}</span>회 틀렸습니다.<br>
                            5회 오류 입력 시 다시 문의하시기 바랍니다.
                        </div>
                        <div class="certify_wrong" :class="{ rejection: rejectCertifyAll }" v-else>
                            본인인증이 5회 틀렸습니다.<br>
                            문의해주시기 바랍니다.
                        </div>
                    </div>
                </div>
            </div>

            <button
                class="primary_btn"
                :class="{ active: isDisabled }"
                :disabled="!isDisabled"
                @click="isValidBirthday()"
            >
                확인
            </button>
        </div>
    </div>
</template>

<script>
import {mapState,} from 'vuex'
import {completeCertification, failedCertification, updateCertificationCount} from "@/api/contract";

export default {
    created() {
        this.contractDetail = this.contractStore.contractDetail
        this.certifyWrongCount = this.contractDetail.pwdCheck
        this.validateCertificationAttempts();
    },
    data() {
        return {
            contractDetail: null,
            rejectCertify: false,
            rejectCertifyAll: false,
            certifyWrongCount: 0,
            inputtedNumber: '',
        }
    },
    computed: {
        ...mapState({
            contractStore: state => state.TflainCommonContractStore,
        }),
        fullInput() {
            return this.inputtedNumber.length === 6 ? true : ''
        },
        isDisabled() {
            return this.fullInput && !this.rejectCertifyAll
        },
    },
    watch: {
        inputtedNumber(numberValue) {
            const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
            if (reg.exec(numberValue) !== null) this.inputtedNumber = numberValue.replace(/[^0-9]/g, '');
            if (isNaN(parseFloat(numberValue))) this.inputtedNumber = '';
        },
    },
    methods: {
        validateCertificationAttempts() {
            if (this.certifyWrongCount > 4) {
                this.$router.replace({name: 'AuthenticationFailure'})
            }
        },
        bindNumber(e) {
            this.inputtedNumber = e.target.value;
        },
        isValidBirthday() {
            if (this.contractDetail.birthday.slice(2, 8) === this.inputtedNumber) {
                updateCertificationCount(this.contractDetail.mgNo, this.certifyWrongCount)
                    .then(response => {
                        if (response.data.result === true) {
                            completeCertification(this.contractDetail.mgNo);
                            this.$emit('confirm-birthday');
                        } else if (response.data.result === false) {
                            this.$router.replace({name: 'AuthenticationFailure'})
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                this.inputtedNumber = ''
                this.certifyWrongCount++
                if (this.certifyWrongCount === 5) {
                    this.rejectCertify = false
                    failedCertification(this.contractDetail.mgNo)
                    this.$router.replace({name: 'AuthenticationFailure'})
                    // this.rejectCertifyAll = true
                } else if (this.certifyWrongCount < 5) {
                    updateCertificationCount(this.contractDetail.mgNo, this.certifyWrongCount)
                    this.rejectCertify = true
                }
            }
        }
    }
}
</script>
