<template>
    <!-- 계약서 페이지 : contract / 설명페이지 : manual -->
    <div class="container" data-contract-company="tflain" data-html-name="계약 완료">
        <div class="contract">

            <HeaderBlue>
                <template v-slot:title>계약 완료</template>
            </HeaderBlue>

            <main class="complete_contract">
                <section class="default">
                    <article class="contract_done_case">
                        <div class="contract_done_contents">
                            <div class="done_msg">
                                <div class="confirm_icon"></div>
                                <div class="text">계약서 작성이 완료되었습니다.</div>
                            </div>
                        </div>
                    </article>
                </section>
            </main>
        </div>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
export default {
    name: 'CompleteContract',
    data() {
        return {
            url: '',
            filepath: '',
            isLoading: false,
            mgNo: '',
        }
    },
    created() {
        localStorage.removeItem('vuex');
        localStorage.removeItem('_secure__ls__metadata')
        this.isLoading = true
    },
    mounted() {
        this.url = this.$route.query.url
        this.filepath = this.$route.query.filepath
        this.mgNo = this.$route.query.mgNo
        this.pdfDownload();
    },
    beforeRouteLeave(to, from, next) {
        // 뒤로가기 방지
        alert('이미 완료된 계약입니다')
        next(false)
    },
    methods: {
        pdfDownload() {
            const fileUrl = this.url + '/v1/api/download/pdf/completion?filePath=' + this.filepath + '&mgNo=' + this.mgNo
            const link = document.createElement('a')
            link.href = fileUrl
            link.setAttribute('download', '')
            link.click()
            link.remove()
            this.isLoading = false
        },
    }
};
</script>