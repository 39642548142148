<template>
    <div class="container" data-contract-company="aone" data-html-name="채무조정합의서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <div slot="title">채무조정합의서</div>
            </header-blue>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="terms_article layout_setting_others">
                        <div>(주)에이원대부캐피탈(이하‘갑 이라 한다)과 채무자</div>
                        <div>
                            <div class="common_box">
                                <div class="text_enter_wrap">
                                    <div class="label_case">성명</div>
                                    <SignatureInput>
                                        <template #signature>
                                            <input
                                                type="text"
                                                id="sign"
                                                class="sign"
                                                ref="debtRestrucDebtorName"
                                                :placeholder="contractDetail.debtRestrucDebtorName"
                                                :value="returnName"
                                                :class="{ blue: signatureColor }"
                                                :disabled="!signatureColor"
                                                @input="isValidName"
                                                @blur="isValidKornNm"
                                            >
                                        </template>
                                    </SignatureInput>
                                </div>
                            </div>
                        </div>
                        <div>(이하 '을'이라 한다.) 다음과 같이 '채무조정합의서'를 체결한다.</div>
                    </article>

                    <article>
                        <div class="text_align">- 다 음 -</div>
                    </article>

                    <article class="terms_article layout_setting_others">
                        <div>
                            1. ‘을’은 ‘갑’의 채무자로서 상환하여야 할 대출원리금에 대하여 현재의 재산과 수입으로는 변제기에 상환하기 어려워 ‘갑’이 제시한 신용회복지원을 위한 채무조정을
                            신청한다.<br/>
                            2. 채무조정의 대상: ‘을’의 이자 및 연체이자, 가지급금<br/>
                            3. 채무조정의 확약
                        </div>

                        <div>(i) 채무조정 내용 &lt; {{ contractDetail.debtRestrucDocumentDate | formatHyphenContractDate }} &gt;</div>

                        <div>
                            <table class="terms_table two_dimensional">
                                <tbody>
                                <tr>
                                    <th class="category_table_header" colspan="2">구분</th>
                                    <th class="category_table_header" colspan="2">조정전 채무</th>
                                    <th class="category_table_header" colspan="2">조정후 채무</th>
                                </tr>

                                <tr>
                                    <th rowspan="5">채무감면</th>
                                </tr>
                                <tr>
                                    <th>가지급</th>
                                    <td colspan="2">{{ contractDetail.preNegotiationDebtAmount | comma }}</td>
                                    <td colspan="2">{{ contractDetail.postNegotiationDebtAmount | comma }}</td>
                                </tr>
                                <tr>
                                    <th>대출원금</th>
                                    <td colspan="2">{{ contractDetail.preNegotiationLoanPrincipalAmount | comma }}</td>
                                    <td colspan="2">{{ contractDetail.postNegotiationLoanPrincipalAmount | comma }}</td>
                                </tr>
                                <tr>
                                    <th>미수이자</th>
                                    <td colspan="2">{{ contractDetail.preNegotiationUnpaidInterestAmount | comma }}</td>
                                    <td colspan="2">{{ contractDetail.postNegotiationUnpaidInterestAmount | comma }}</td>
                                </tr>
                                <tr>
                                    <th>계</th>
                                    <td colspan="2">{{ contractDetail.preNegotiationTotalAmount | comma }}</td>
                                    <td colspan="2">{{ contractDetail.postNegotiationTotalAmount | comma }}</td>
                                </tr>

                                <tr>
                                    <th colspan="2">이자율 조정</th>
                                    <td colspan="2">연 {{ contractDetail.preNegotiationInterestRateAdjustment }}%</td>
                                    <td colspan="2">연 {{ contractDetail.postNegotiationInterestRateAdjustment }}%</td>
                                </tr>

                                <tr>
                                    <th rowspan="3">상환기간</th>
                                </tr>
                                <tr>
                                    <th>상환방식</th>
                                    <td colspan="2">{{ preNegotiationRepaymentMethod }}</td>
                                    <td colspan="2">{{ '분할상환' }}</td>
                                   
                                </tr>
                                <tr>
                                    <th>만기일</th>
                                    <td colspan="2">{{ contractDetail.preNegotiationMaturityDate | formatContractDate }}</td>
                                    <th>상환회차</th>
                                    <td>{{ contractDetail.postNegotiationRepaymentTerm }}회</td>                                
                                </tr>
                                <tr>
                                    <th colspan="2">약정일(입금일)</th>
                                    <td colspan="2">매월 {{ contractDetail.preNegotiationDepositDate }}일</td>
                                    <td colspan="2">매월 {{ contractDetail.postNegotiationDepositDate }}일</td>
                                </tr>

                                <tr>
                                    <th colspan="2">가상계좌번호</th>
                                    <td colspan="4">{{ '['+ contractDetail.virtualAccountBankNm +'] ' + contractDetail.virtualAccountNumber + ' ' + contractDetail.debtRestrucDebtorName }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>(ii) 이자율 조정이 무이자(0%)인 원금분할상환의 경우 분할상환기간 동안의 이자, 연체이자는 부과하지 아니한다. 대부업상 최고이자율은 연 20%</div>
                        <div>
                            4. 채무조정의 효력발생일 : (1회)상환금 입금일, 최종회차에는 전액 상환하기로 한다.<br/>
                            5. '을'은 본 합의에도 불구하고 기한의 이익을 상실할 경우에는 본 채무조정은 처음부터 그 효력을 상실하며, '을'의 채무는 다시 채무조정 이전의 원 채무로
                            환원된다.<br/>
                            6. 기한이익의 상실규정 : 채무조정 후 31일 이상 연체시 채무자의 기한이익은 상실된다.<br/>
                            7. '을'은 본 합의서 작성후 주소 및 거소, 전화번호 동의 변경이 있는 경우에는 즉시 변경된 사항을 '갑'에 통지한다.
                        </div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.debtRestrucDebtorName }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.debtRestrucDebtorBirth | formatContractDate }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.debtRestrucDebtorAddress }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">연락처</div>
                            <div>{{ contractDetail.debtRestrucDebtorPhone | formatPhoneNr }}</div>
                        </div>

                        <div>이 조건은 1회 가상계좌 납입 (입금) 이후 효력이 발생합니다.</div>
                    </article>

                    <article>
                        <div>이 합의를 명확히 하기 위해 본 합의서 2부를 작성하여 '갑'과 '을'이 각각 1부씩 보관한다. <span class="emp_bold">(읽어보시고 동의시 작성바랍니다)</span>
                        </div>
                    </article>

                    <article>
                        <div class="label_case">작성일</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.debtRestrucDocumentDate | formatDate }}</div>
                        </div>
                    </article>

                    <article>
                        <div class="mb8">(갑) 채권자(주)에이원대부캐피탈 대표이사 주환곤</div>

                        <div data-sign="sign_field">
                            <div class="label_case">(을) 채무자(보증인)</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        ref="kornNm"
                                        :placeholder="contractDetail.debtRestrucDebtorName"
                                        :value="guarantorReturnName"
                                        :class="{ blue: guarantorSignatureColor }"
                                        :disabled="!guarantorSignatureColor"
                                        @input="isGuarantorValidName"
                                        @blur="isValidGuarantorKornNm"
                                    >
                                    <label for="sign" @click="openSignReaded()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                    <article>
                        <div class="emp_bold">*채무재조정이후 한번 정한 약정일은 변경불가합니다.</div>
                        <div class="emp_bold">*이 조건은 최초 1회 납입 이후 효력이 발생합니다.</div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap" v-if="nextComponent === 'complete'">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isGuarantorKornNm" @confirmBtn="confirmGuarantorKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isDebtRestrucDebtorNameModal" @confirmBtn="checkDebtRestrucDebtorNameModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">채무자(보증인)</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureReaded v-if="showSignatureReaded" ref="signaturePad" @close="closeSignReaded()"
                         @save="saveSignatureProvideSecurity" :propsName="contractDetail.debtRestrucDebtorName"/>
        <InicisComponent ref="openPopup"/>
    </div>
</template>

<script>
import SignatureReaded from '@/components/common/SignaturePad.vue'
import InicisComponent from "@/components/InicisComponent.vue";
import {mapGetters, mapState} from "vuex";
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        SignatureReaded,
        InicisComponent,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureReaded: false,
            showSignDebtor: false,
            isKornNm: false,
            isGuarantorKornNm: false,

            // check
            isDebtRestrucDebtorNameModal: false,
            isKornNmModal: false,
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC10AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
        }),
        preNegotiationRepaymentMethod() {
            const preNegotiationRepaymentMethod = this.contractDetail.preNegotiationRepaymentMethod
            if (preNegotiationRepaymentMethod === '01') {
                return '자유상환'
            } else if (preNegotiationRepaymentMethod === '04' || preNegotiationRepaymentMethod === '05' || preNegotiationRepaymentMethod === '06') {
                return '분할상환'
            } else if (preNegotiationRepaymentMethod === '03') {
                return '만기일일시상환'
            } else {
                return '자유상환'
            }
        },
        returnName() {
            return this.EC10AO.name
        },
        guarantorReturnName() {
            return this.EC10AO.guarantorName
        },
        signatureColor() {
            return this.EC10AO.nameColorValid
        },
        guarantorSignatureColor() {
            return this.EC10AO.guarantorNameColorValid
        },
        signedData() {
            const data = this.EC10AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        contractSignedData() {
            return {
                signedData01: this.EC10AO.signedData,
            }
        },
        isDisabled() {
            return this.EC10AO.nameValid && this.EC10AO.signedDataValid && this.EC10AO.guarantorNameValid
        },
    },
    methods: {
        isValidName(e) {
            if (this.contractDetail.debtRestrucDebtorName === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC10AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC10AO_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.debtRestrucDebtorName !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        isGuarantorValidName(e) {
            if (this.contractDetail.debtRestrucDebtorName === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC10AO_GUARANTOR_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC10AO_GUARANTOR_SIGNATURE_COLOR', false)
            }
        },
        isValidGuarantorKornNm(e) {
            if (this.contractDetail.debtRestrucDebtorName !== e.target.value) {
                this.isGuarantorKornNm = true
            }
        },
        confirmGuarantorKornNmModal() {
            this.isGuarantorKornNm = false
        },
        openSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        closeSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        saveSignatureProvideSecurity() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC10AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureReaded = !this.showSignatureReaded
        },
        checkDebtRestrucDebtorNameModal() {
            this.isDebtRestrucDebtorNameModal = false
            this.$refs.debtRestrucDebtorName.focus()
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureReaded = !this.showSignatureReaded
        },
        async completeContract() {
            if (this.signatureColor) {
                this.isDebtRestrucDebtorNameModal = true
            } else if (this.guarantorSignatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC10AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
    },
}
</script>

<style>

</style>