import {store} from "@/store";

import regType01_01 from "@/views/aone/EC01AO.vue";
import regType01_02 from "@/views/aone/EC02AO.vue";
import regType01_03 from "@/views/aone/EC03AO.vue";
import regType01_04 from "@/views/aone/EC04AO.vue";

export default [
    {
        path: '/contract/01',
        component: regType01_01,
        name: 'regType01_01',
        props: {nextComponent: 'regType01_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q);
                localStorage.removeItem('vuex');
                if (response.data.result === true) {
                    store.commit('AoneIndividualContractStore/RESET');
                    next();
                } else {
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/01/02',
        component: regType01_02,
        name: 'regType01_02',
        props: {nextComponent: 'regType01_03'},
    },
    {
        path: '/contract/01/03',
        component: regType01_03,
        name: 'regType01_03',
        props: {nextComponent: 'regType01_04'},
    },
    {
        path: '/contract/01/04',
        component: regType01_04,
        name: 'regType01_04',
        props: {nextComponent: 'complete'},
    },
];