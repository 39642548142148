<template>
    <div class="container" data-contract-company="aone" data-html-name="대부거래약정서_고객정보 및 서명">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">대부거래약정서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">
                    <article class="corporation_info_article">
                        <div>
                            <div class="logo_img"></div>
                            <div class="corporation_url">(주)에이원대부캐피탈 www.lonio.co.kr</div>
                        </div>
                        <div class="corporation_info">
                            서울특별시 강남구 테헤란로 409, 5층, 6층(삼성동, 동신빌딩)<br/>
                            TEL : 02-5533-111 , FAX : 02-555-8780<br/>
                            대부업등록번호 : 2016-금감원-0013(대부업)<br/>
                            사업자등록번호 : 120-86-38783
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">고객정보</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.kornNm }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.birthday | formatContractDate }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성별</div>
                            <div>{{ contractDetail.gender | formatGender }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">휴대폰</div>
                            <div>{{ contractDetail.hpNo | formatPhoneNr }}</div>
                        </div>
                        <div class="common_box last">
                            <div class="user_info_title">현재 집주소(주민등본상)</div>
                            <div>{{ contractDetail.addr }}</div>
                        </div>
                        <div class="comment gray none">※ 주민등록 등본 확인을 위해 현재 주소가 맞는지 확인하세요.</div>
                    </article>

                    <article>
                        <div>1. 본인(이하“갑”이라 한다)은 주식회사 에이원대부캐피탈(이하“을”이라 한다)과 아래의 거래조건에 따라 대출거래를 함에 있어 “을”의 대부거래표준약관이 적용됨을
                            승인하고 다음 각 조항을 확약합니다.
                        </div>
                        <div>2.“ 갑”은 대부금액, 이자율(연체이자율), 변제기간은 대부업법상 중요사항으로서 반드시 자필기재 하셔야 합니다.</div>
                        <div>3. 계약내용을 명확히 하기 위하여 약정서 2부와“을”이 필요로 하는 제3자 채권양도담보제공 용도의 담보권자용 부본을 작성하여“갑”과“을”이 각각 보관하는 것으로
                            합니다.
                        </div>
                        <div class="emp_bold">※ 대부중개업자가 중개수수료를 고객으로 부터 받는 것은 불법입니다. (대부업법 제11조의 2)</div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">본인</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        ref="inputField"
                                        :placeholder="contractDetail.kornNm"
                                        :value="returnName"
                                        :class="{ blue: signatureColor }"
                                        :disabled="!signatureColor"
                                        @input="isValidName"
                                        @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isNameModal" @confirmBtn="checkNameModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isNameSignatureModal" @confirmBtn="checkNameSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'

export default {
    components: {
        SignatureDebtor,
    },
    data() {
        return {
            showSignatureDebtor: false,
            contractDetail: null,
            isKornNm: false,

            // check
            isNameModal: false,
            isNameSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC03AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        signedData() {
            const data = this.EC03AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC03AO.name
        },
        signatureColor() {
            return this.EC03AO.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            return this.EC03AO.signedDataValid && this.EC03AO.nameValid
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC03AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC03AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC03AO_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkNameModal() {
            this.isNameModal = false
            this.$refs.inputField.focus()
        },
        checkNameSignatureModal() {
            this.isNameSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (this.signatureColor) {
                this.isNameModal = true
            } else if (!this.EC03AO.signedDataValid) {
                this.isNameSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>