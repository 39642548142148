<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보 선택적 제공에 관한 사항">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">개인(신용)정보 선택적 제공에 관한 사항</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div class="terms_title">1) 신용정보집중기관 및 신용조회회사에 개인(신용)정보 제공</div>
                        <div>
                            ▪ 제공 대상 기관 :
                            <div class="terms_list">
                                - 신용정보 집중기관 : 한국신용정보원, 차입처(한국신용정보원 등록금융기관)<br/>
                                - 신용조회회사 : NICE평가정보(주), 코리아크레딧뷰로(주)
                            </div>
                            ▪ 제공 목적 : 신용정보의 집중관리 및 활용 등 신용정보집중기관의 업무, 신용도 평가, 실명확인 등 신용조회회사의 업무<br/>
                            ▪ 제공할 신용정보의 내용 : 자사대출 신청정보(신청일자, 신청채널, 신청상품, 신청결과, 직장 및 자택정보)<br/>
                            ▪ 보유 및 이용기간 : 신용정보집중관리 활용 등 신용정보집중기관의 업무 및 신용도 평가, 실명확인 등 신용조회업무 목적 달성 시까지
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">2) 귀사 및 제휴회사 등에 개인(신용)정보 제공</div>
                        <div>
                            ▪ 제공 대상 기관 : 관련 법령이 허용하는 범위 내에서 금융기관, 은행법에 의한 은행, 상호저축은행법에 의한 저축은행, 대부업법에 의한 대부업체, NICE R&C(주) 및 당사 제휴회사, 기타 당사 홈페이지에 공지된 회사<br/>
                            ▪ 제공 목적 :
                            <div class="terms_list">
                                - 대출 상품의 공동 마케팅 및 제휴서비스, 고객심사 및 사후관리, 대출상품 서비스소개 및 판매, 대출이용권유, 대출계약의 유지 및 사후관리, 매각채권 관리 및 차입 시 담보 채권제공, 문자메시지서비스제공 등의 마케팅 활용 또는 시장조사, 개인 신용분석 및 상품 개발에 관한 업무 및 연구 등의 수행<br/>
                                - 명세서 발송, 우편물, 이메일, 카드 등의 배송업무 또는 본인이 신청한 대출상품의 부가서비스를 제공함에 있어서 제공업체가 업무수행을 하기 위하여 개인정보가 필요한 경우
                            </div>
                            ▪ 혜택 : 사은품 제공, 제휴업체 서비스 할인 등<br/>
                            ▪ 제공할 신용정보의 내용
                            <div class="terms_list">
                                - 개인식별정보 : 성명, 주소, 고유식별정보, 성별, 국적, 직업, 연락처, 이메일 등<br/>
                                - 신용능력정보 : 재산·채무·소득의 총액·납세실적 등<br/>
                                - 신용거래정보 : 본 계약 이전 및 이후의 실적을 포함한 거래내용<br/>
                                - 채무보증현황 : 본 계약 이전 및 이후의 보증현황 포함<br/>
                                - 신용인증송부로 제출하는 신용정보<br/>
                                - 주거정보, 맞벌이 유무, 직장 근속기간, 우편물 수령처, 기타 금융거래의 설정, 유지, 이행, 관리를 위한 상담, 채권관리 등을 통해 생성되는 정보 등
                            </div>
                            <div class="terms_comment">※ 본 동의 이전에 발생한 상기 개인(신용)정보 포함</div>
                            ▪ 보유 및 이용기간 : 제공 동의일로부터 본인이 신청한 대부거래가 귀사에 의해 거절된 시점까지, 대부거래가 설정된 경우에는 본인의 제공 동의 철회 사까 지 또는 거래종료일(채권·채무관계가 해소된 시점) 이내에서 제공계약 종료 시까지 보유·이용됩니다. 다만, 마케팅 목적의 정보는 거래종료일 이후 3개 월까지 이용이 제한됩니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>