<template>
 <div class="signature_wrap">
  <slot name="signature">
    <input type="text" placeholder="default" id="sign" class="sign">
    <label for="sign">
      <button>default</button>
    </label>
  </slot>
 </div>
</template>
<script>
export default {
  
}
</script>
<style lang="">
  
</style>