<template>
    <div>

    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    data() {
        return {
            bizCode: '',
        }
    },
    computed: {
        ...mapState({
            inicisStore: state => state.inicisStore,
        }),
    },
    async mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const compressData = urlParams.get('data')
        this.bizCode = this.inicisStore.inicisDetail.bizCode
        if (this.bizCode === 'aone01') {
            await this.$router.replace({name: 'InicisSuccessComponent', query: { compressData: compressData, }})
        } else if (this.bizCode === 'tflain001') {
            await this.$router.replace({name: 'TflainInicisSuccessComponent', query: { compressData: compressData, }})
        } else {
            alert('잘못된 경로입니다')
        }

    }

}
</script>