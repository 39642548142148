import Vue from "vue";
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

import AoneCommonContractStore from "./modules/aone/commonContractStore";
import AoneIndividualContractStore from "./modules/aone/individualContractStore";
import TflainCommonContractStore from "./modules/tflain/commonContractStore";
import TflainIndividualContractStore from "./modules/tflain/individualContractStore";
import InicisStore from "@/store/modules/inicisStore";

Vue.use(Vuex);

const ls = new SecureLS({encodingType: "aes"});

export const store = new Vuex.Store({
    modules: {
        AoneCommonContractStore: AoneCommonContractStore,
        AoneIndividualContractStore: AoneIndividualContractStore,
        TflainCommonContractStore: TflainCommonContractStore,
        TflainIndividualContractStore: TflainIndividualContractStore,
        inicisStore: InicisStore,
    },
    plugins: [createPersistedState({
        paths: (() => {
            return ['TflainCommonContractStore', 'TflainIndividualContractStore', 'AoneCommonContractStore', 'AoneIndividualContractStore', 'inicisStore']
        }),
        getState: (key) => {
            const value = ls.get(key);
            return value ? JSON.parse(value) : null;
        },
        setState: (key, state) => {
            const value = JSON.stringify(state);
            ls.set(key, value);
        },
    })],
});