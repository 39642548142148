import {store} from "@/store";

import regType16_01 from "@/views/aone/EC10AO.vue";

export default [
  {
    path: '/contract/16',
    component: regType16_01,
    name: 'regType16_01',
    props: { nextComponent: 'complete' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q)
        localStorage.removeItem('vuex');
        store.commit('AoneIndividualContractStore/RESET');
        if (response.data.result === true) {
          next();
        } else {
          localStorage.removeItem('vuex');
          localStorage.removeItem('_secure__ls__metadata');
          location.replace('/duplicateContractError');
        }
      } catch(error) {
        console.log(error);
      }
    },
  },
]