import {store} from "@/store";

import regType02_01 from '@/views/tflain/EC01TF.vue'
import regType02_02 from '@/views/tflain/EC02TF.vue'
import regType02_03 from '@/views/tflain/EC03TF.vue'
import regType02_04 from '@/views/tflain/EC04TF.vue'
import regType02_05 from '@/views/tflain/EC05TF.vue'
import regType02_06 from '@/views/tflain/EC06TF.vue'
import regType02_07 from '@/views/tflain/EC07TF.vue'

export default [
    {
        path: '/contract/t-flain/02',
        component: regType02_01,
        name: 't-flain/regType02_01',
        props: {nextComponent: 't-flain/regType02_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('TflainCommonContractStore/FETCH_CONTRACT', to.query.q);
                localStorage.removeItem('vuex');
                if (response.data.result === true) {
                    store.commit('TflainIndividualContractStore/RESET');
                    next();
                } else {
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/t-flain/02/02',
        component: regType02_02,
        name: 't-flain/regType02_02',
        props: {nextComponent: 't-flain/regType02_03'},
    },
    {
        path: '/contract/t-flain/02/03',
        component: regType02_03,
        name: 't-flain/regType02_03',
        props: {nextComponent: 't-flain/regType02_04'},
    },
    {
        path: '/contract/t-flain/02/04',
        component: regType02_04,
        name: 't-flain/regType02_04',
        props: {nextComponent: 't-flain/regType02_05'},
    },
    {
        path: '/contract/t-flain/02/05',
        component: regType02_05,
        name: 't-flain/regType02_05',
        props: {nextComponent: 't-flain/regType02_06'},
    },
    {
        path: '/contract/t-flain/02/06',
        component: regType02_06,
        name: 't-flain/regType02_06',
        props: {nextComponent: 't-flain/regType02_07'},
    },
    {
        path: '/contract/t-flain/02/07',
        component: regType02_07,
        name: 't-flain/regType02_07',
        props: {nextComponent: 'complete'},
    },
]