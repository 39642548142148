import InicisFailedComponent from "@/components/common/InicisFailedComponent.vue";
import AuthenticationFailure from "@/components/common/AuthenticationFailure.vue";

export default [
    {
        path: '/inicisFailedComponent',
        component: InicisFailedComponent,
        name: 'InicisFailedComponent',
    },
    {
        path: '/authenticationFailure',
        component: AuthenticationFailure,
        name: 'AuthenticationFailure',
    },
]