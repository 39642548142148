<template>
  <header>
    <div class="header_wrap black">
      <slot name="backBtn">
        <div class="backBtn_icon"></div>
        
      </slot>
      <div class="title">
        <slot name="title">
          default
        </slot>
      </div>
      <div class="blank"></div>
    </div>
  </header>
</template>
<script>
export default {
 
}
</script>
<style>
  
</style>