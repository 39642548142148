import {store} from "@/store";

import regType01_01 from '@/views/tflain/EC01TF.vue'
import regType01_02 from '@/views/tflain/EC02TF.vue'
import regType01_03 from '@/views/tflain/EC03TF.vue'
import regType01_04 from '@/views/tflain/EC04TF.vue'
import regType01_05 from '@/views/tflain/EC05TF.vue'

export default [
    {
        path: '/contract/t-flain/01',
        component: regType01_01,
        name: 't-flain/regType01_01',
        props: {nextComponent: 't-flain/regType01_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('TflainCommonContractStore/FETCH_CONTRACT', to.query.q);
                localStorage.removeItem('vuex');
                if (response.data.result === true) {
                    store.commit('TflainIndividualContractStore/RESET');
                    next();
                } else {
                    localStorage.removeItem('_secure__ls__metadata');
                    const message = response.data.clientErrorResponseDto.message;
                    location.replace(`/commonErrorComponent?message=${encodeURIComponent(message)}`);
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/t-flain/01/02',
        component: regType01_02,
        name: 't-flain/regType01_02',
        props: {nextComponent: 't-flain/regType01_03'},
    },
    {
        path: '/contract/t-flain/01/03',
        component: regType01_03,
        name: 't-flain/regType01_03',
        props: {nextComponent: 't-flain/regType01_04'},
    },
    {
        path: '/contract/t-flain/01/04',
        component: regType01_04,
        name: 't-flain/regType01_04',
        props: {nextComponent: 't-flain/regType01_05'},
    },
    {
        path: '/contract/t-flain/01/05',
        component: regType01_05,
        name: 't-flain/regType01_05',
        props: {nextComponent: 'complete'},
    },
]