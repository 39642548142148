<template>
    <div class="container" data-contract-company="tflain" data-html-name="채권 양수 양도 계약서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <!-- class : show로 처리 -->
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">채권 양수 양도 계약서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article>
                        <table class="terms_table">
                            <tbody>
                                <tr>
                                    <th>목적물</th>
                                    <td>
                                        {{ contractDetail.addCollateralPropertyAddress }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>양도채권의 표시</th>
                                    <td>
                                        위목적물에 관하여 <span class="emp_bold">{{ contractDetail.cntrctDt | formatDate }}</span> 소유자[임대인] <span class="emp_bold">{{ contractDetail.lessor }}</span>와(과) 임차인[양도인] <span class="emp_bold">{{ contractDetail.assignor }}</span>와(과)간에 임대차계약을 체결하여 동 계약서의 보증금으로 예치시킨 금 <span class="emp_bold">{{ contractDetail.securityDeposit | amountInKorean }}</span>원정의 임대보증금 반환청구채권 중 금 <span class="emp_bold">{{ contractDetail.leaseSecurityDepositClaim | amountInKorean }}</span>원의 반환청구채권 (대출원금의 150%)
                                    </td>
                                </tr>
                                <tr>
                                    <th>양수인</th>
                                    <td>
                                        (주)티플레인대부<br/>
                                        서울특별시 마포구 삼개로 16, 2신관 1층 103호 (도화동,근신빌딩)<br/>
                                        - 대표이사 윤웅원 (인)<br/>
                                        - 사업자등록번호 : 169-81-01699<br/>
                                        - 대부업등록번호 : 2020-금감원-1923(대부업)<br/>
                                        - ☎02-6010-0511, FAX : 02-6010-0711
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <article>
                        <article class="user_info_article">
                            <div class="title_wrap">
                                <div class="section_title">양도인</div>
                            </div>
                            <div class="common_box">
                                <div class="user_info_title">성명</div>
                                <div>{{ contractDetail.kornNm }}</div>
                            </div>
                            <div class="common_box">
                                <div class="user_info_title">생년월일</div>
                                <div>{{ contractDetail.birthday | formatContractDate }}</div>
                            </div>
                            <div class="common_box">
                                <div class="user_info_title">연락처</div>
                                <div>{{ contractDetail.hpNo | formatPhoneNr }}</div>
                            </div>
                        </article>
                    </article>

                    <article>
                        <div class="area_block">
                            양도인은 양수인과의 금전소비대차계약과 관련하여 위에 표시된 목적물의 채권에 대하여 양수인에게 양도함을 확인한다. 이에 각 당사자는 다음과 같이 합의한다.
                        </div>
                        <div class="area_block next">- 다음 -</div>
                        <div class="area_block">
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">1.</div>
                                    <div>
                                        양도인은 위 양도채권이 계약일 현재 타인에게 양도 또는 담보로 제공되거나 압류된 사실이 없으며, 적법한 권리를 가지고 있다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">2.</div>
                                    <div>
                                        양도인은 위 목적물의 보증금으로 예치시킨 금액이 사실과 다르지 않음을 확인한다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">3.</div>
                                    <div>
                                        양도인은 이 계약 체결 이후 양도채권을 위 양수인 이외의 제3자에게 담보로 제공하거나 양도할 수 없다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">4.</div>
                                    <div>
                                        양도인은 이 계약체결로 양도채권의 권리가 양수인에게 있음을 확인하고, 양수인과의 합의 없이 임대인에게 보증금 반환청구권을 행사하거나 그 외의 방법으로 권리의 행사를 하여서는 아니 된다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">5.</div>
                                    <div>
                                        만약, 위 1,2,3,4항의 내용이 허위이거나, 임의로 권리행사를 하는 등의 방법으로 양수인에게 금전적 손해를 입히는 경우 민·형사적 책임이 있음을 확인한다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">6.</div>
                                    <div>
                                        금전소비대차계약 기간 내에 임의로 이사하는 경우 원리금 전액을 상환하여야 한다.<br/>
                                        단, 동일 목적물의 거주기간을 연장하는 경우 임대와 새로운 임대차계약서를 작성하고, 양수인이 요구하는 서류를 제출하는 등의 방법으로 금전소비대차계약의 연장도 가능하다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">7.</div>
                                    <div>
                                        <div class="mb4">
                                            아래의 사유가 발생하는 경우 기한의 이익은 상실되며, 양수인은 *채권보존을 위한 행위 및 임대인에게 임대차보증금 반환청구권을 행사하기로 한다.
                                        </div>
                                        <div>
                                            ① 양수인에게 제출하는 임대차계약서가 허위이거나, 원본이 아닌 경우<br/>
                                            ② 계약기간내에 양수인에게 아무런 통지없이 임의대로 이사하거나, 양도목적물의 계약조건을 변경하는 경우(보증금 감액 등)<br/>
                                            ③ 월차임료를 2회 이상 지체한 경우<br/>
                                            ④ 임대차보증금에 대한 제3자의 강제집행 등이 발생하는 경우<br/>
                                            ⑤ 금전소비대차계약서 관련 계약서류, 채권양도계약서 및 그 밖의 양수인에게 제출해야하는 서류를 7일 이내에 반송하지 않을 경우<br/>
                                            ⑥ 금전소비대차계약기간 내 임대차계약이 만료되거나, 새로운 목적물로 이사를 하는 경우 임대차계약만료일 또는 새로운 목적물의 계약일로부터 7일 이내 양수인이 요구하는 서류 일체를 제출하지 않았을 경우(신규임대차 계약서 등)<br/>
                                            ⑦ 기타 담보물의 소멸이 우려되거나, 이사를 가더라도 새로운 임대차계약에 기한 기존과 동일한 가치 이상의 담보물로 교체하지 않는 경우
                                        </div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">8.</div>
                                    <div>
                                        금전소비대차계약서 약관 제12조의 [기한의 이익상실]과 본 계약서 상의 [기한의 이익상실]조항이 상충되는 경우 이 계약의 조항이 우선한다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">9.</div>
                                    <div>
                                        양도인은 이 계약 체결이후 양도목적물의 가치를 감소시킬수 있는 일체의 행위, 양도목적물의 조건을 수정하거나 이를 해지, 취소하는 것을 포함하여 본건 양도에 따른 양수인의 권리내용을 감소 또는 불확실하게 하거나 그 효력을 상실시키는 행위를 할 수 없다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">10.</div>
                                    <div>
                                        양수인의 금전소비대차계약 원리금 및 비용의 합이 양도채권액보다 적을 경우 그 차액을 양도인에게 반환한다.
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">11.</div>
                                    <div>
                                        <div class="mb4">
                                            (양도의 통지) 양도사실의 통지를 보류하고 있던 중 아래와 같은 사유가 발생한 경우, 양수인은 채권 양도의 대항요건을 갖추기 위한 조치로써 양도인을 대리하여 위 채권의 양도 사실을 제3채무자에게 확정일자 있는 증서로 통지한다. 위 채권양도통지서가 도착하지 않고 반송되었을 경우, 해당 통지서는 양수인이 보관한다.
                                        </div>
                                        <div>
                                            ① 피(被) 담보채무의 연체(일수 2일 이상)<br/>
                                            ② 본 양도양수계약서 제7조 각 항목의 사유 발생 및 그 외의 사유로 기한의 이익을 상실한 때<br/>
                                            ③ 양도인이 통지 한 경우 관련 증비자료를 양수인에게 제공해주지 않거나, 확정일자 있는 증서로 양도의 통지를 하지 않은 떄<br/>
                                            ④ 양도인이 공적 채무조정 (신용회복, 회생, 파산 등)을 신청한 떄<br/>
                                            ⑤ 이사예정 사실을 고지하더라도 양수인이 채권 보존을 위해 필요하다고 판단한 경우<br/>
                                            * 통상적 임대차 재계약기간을 임대만료 1~6개월전으로 보고 임대만료 1개월 전까지 양도통지절차는 완료될 수 있도록 한다.
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mb16">
                                    <div class="alphabet_align">12.</div>
                                    <div>
                                        양도사실의 통지를 보류하여 양도통지가 임대인에게 도달되기 전인 경우, 양도인은 양수인의 양도사실 통지에 관한 대리의 수권을 철회하지 못 한다.
                                    </div>
                                </div>

                            </div>
                        </div>

                    </article>

                    <article>
                        <div class="label_case">양도일</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.transferDate | formatDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">채무자(본인)</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <section class="bottomBtn_wrap">
                    <div class="text">
                        위의 내용에 관하여 충분히 설명을 받았음을 확인합니다.
                    </div>
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'

export default {
    components: {
        SignatureDebtor,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureDebtor: false,
            name: '',
            isKornNm: false,
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC06TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC06TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='80' height='38' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC06TF.name
        },
        signatureColor() {
            return this.EC06TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC06TF
            return setPath.signedDataValid
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            });
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC06TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC06TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC06TF_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (!this.EC06TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>