<template>
    <div class="container" data-contract-company="tflain" data-html-name="불법중개수수료 및 권리 안내">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">불법중개수수료 및 권리 안내</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div>
                            당사가 취득한 귀하의 개인신용정보를 제3자에게 제공함에 대한 동의를 철회하거나 상품 소개나 구매·권유목적의 본인 연락 중지를 요청한 경우, 즉시 이에 따를 의무가 당사에 있음을 알려드립니다. 단, 철회권은 제공 및 활용 동의 후 3개월 이내에만 행사가 가능하며. 제휴회사 등에 대한 정보의 제공·활용에 동의 하지 않으시는 경우 제휴서비스를 제공 받지 못할 수도 있음을 알려드립니다.<br/>
                            또한 고객은 ‘신용정보제공사실 통보요구권’(법 제35조) 및 신용정보 열람 및 정정요구권’(법 제38조)에 따른 권리가 있음을 알려드립니다.
                        </div>
                    </article>

                    <article class="terms_article">
                        <div>
                            「대부업 등의 등록 및 금융이용자 보호에 관한 법률」제11조의2 제2항에 따라 대부중개업자는 대부받은 자(채무자)로부터의 중개수수료 수취가 금지되어 있습 니다(위반 시 5년이하의 징역, 5천만원 이하의 벌금). 당사가 대부중개업자와 체결한 계약서상 대부중개업자가 고객에게 수수료, 알선료 등 어떠한 명목의 금원 을 요구하거나 금원을 수령하는 행위는 금지 되어있습니다. 기타 문의사항은 상담센터 <span class="emp_blue emp_bold">02-6010-0511</span>으로 연락바랍니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>