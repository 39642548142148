<template>
    <div class="container" data-contract-company="aone" data-html-name="원용확인서_부동산">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <div slot="title">원용확인서</div>
            </header-blue>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="confirmation_article">
                        <div class="confirmation_title">부동산</div>
                        <div class="subtext">주식회사 에이원대부캐피탈 귀중</div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="title_wrap">
                            <div class="article_title">채권자(근저당권자)</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">상호</div>
                            <div>{{ '주식회사 에이원대부캐피탈' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">대부업<br/>등록번호</div>
                            <div>{{ '2016-금감원-0013(대부업)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">사업자<br/>등록번호</div>
                            <div>{{ '120-86-38783' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ '서울 강남구 테헤란로 409 5,6층(삼성동, 동신빌딩)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">연락처</div>
                            <div>
                                {{ 'TEL: 02-5533-111' }}<br/>
                                {{ 'FAX: 02-555-8780' }}
                            </div>
                        </div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="title_wrap">
                            <div class="article_title">근저당권설정자(담보제공자)</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ realEstatePledgees.pledgee_name }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ realEstatePledgees.pledgee_birth | formatDate }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ realEstatePledgees.pledgee_address }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">연락처</div>
                            <div>{{ realEstatePledgees.pledgee_phone | formatPhoneNr }}</div>
                        </div>
                    </article>

                    <article class="user_info_article confirmation">
                        <div class="title_wrap">
                            <div class="article_title">부동산 표시</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">물건지주소</div>
                            <div>{{ contractDetail.restatePropertyAddress }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">최초 근저당설정일</div>
                            <div>{{ contractDetail.restateInitialPledgeDate | formatContractDate }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">최초 근저당설정액</div>
                            <div>{{ contractDetail.restateInitialPledgeAmount | comma }}원</div>
                        </div>
                        <div v-for="(item, index) in realEstatePledges" :key="index">
                            <div class="common_box">
                                <div class="user_info_title">근저당<br />설정일</div>
                                <div>{{ item.pledge_date | formatContractDate }}</div>
                            </div>
                            <div class="common_box">
                                <div class="user_info_title">근저당<br />설정액</div>
                                <div>{{ item.pledge_amount | comma }}원</div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div>
                            상기인(들)은 채권자(주식회사 에이원대부캐피탈)로부터 <span class="emp_bold underline">{{ contractDetail.restateExpectedLoanRecipient | formatContractDate }}자</span> 대출금 <span
                            class="emp_bold underline">일금 {{ contractDetail.restateExpectedLoanAmount | comma }}원</span>을 차용함에 있어 상기 부동산에 대하여 채권최고액 <span
                            class="emp_bold underline">일금  {{ contractDetail.restateExpectedPledgeAmount | comma }}원</span>을 근저당 설정하였다. 금번 채권자로부터 재(추가)대출을 신청함에 있어 상기 부동산을 담보로 한
                            <span class="emp_bold underline" v-if="realEstatePledges.length === 1">{{ realEstatePledges[0].pledge_date | formatContractDate }}자 각각</span>
                            <span class="emp_bold underline" v-if="realEstatePledges.length === 2">{{ realEstatePledges[0].pledge_date | formatContractDate}}자, {{ realEstatePledges[1].pledge_date | formatContractDate }}자 각각</span>
                            <span class="emp_bold underline" v-if="realEstatePledges.length === 3">{{ realEstatePledges[0].pledge_date | formatContractDate}}자, {{ realEstatePledges[1].pledge_date | formatContractDate }}자, {{ realEstatePledges[2].pledge_date | formatContractDate }}자 각각</span>
                            의 채권최고액 및 근저당설정을 원용하는 것으로
                            하며 추후 민, 형사상의 어떠한 이의신청을 하지 않기로 한다.
                        </div>
                    </article>

                    <article>
                        <div class="label_case">동의일자</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.restateAgreementDate | formatDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article mb28">
                        <div data-sign="sign_field">
                            <div class="label_case">담보제공자</div>
                            <SignatureInput>
                                <template #signature>
                                    <input type="text" id="sign" class="sign"
                                           ref="kornNm"
                                           :placeholder="realEstatePledgees.pledgee_name"
                                           :value="returnName"
                                           :class="{ blue: signatureColor }"
                                           :disabled="!signatureColor"
                                           @input="isValidName"
                                           @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: isDisabled }"
                            @click="completeContract()"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="realEstatePledgees.pledgee_name"/>
        <InicisComponent ref="openPopup"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SignatureDebtor from "@/components/common/SignaturePad.vue";
import InicisComponent from "@/components/InicisComponent.vue";
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        SignatureDebtor,
        InicisComponent,
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.realEstatePledgees = this.contractDetail.realEstatePledgees[0]
        this.realEstatePledges = this.contractDetail.realEstatePledges
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    data() {
        return {
            contractDetail: null,
            isLoading: false,
            showSignatureDebtor: false,
            realEstatePledgees: null,
            realEstatePledges: null,
            isKornNm: false,

            // check
            isKornNmModal: false,
            isKornNmSignatureModal: false,
        }
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC05AO_COLLATERAL']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC05AO_COLLATERAL.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC05AO_COLLATERAL.name
        },
        signatureColor() {
            return this.EC05AO_COLLATERAL.nameColorValid
        },
        isDisabled() {
            return this.EC05AO_COLLATERAL.signedDataValid && this.EC05AO_COLLATERAL.nameValid
        },
        contractSignedData() {
            return {
                signedData01: this.EC05AO_COLLATERAL.signedData
            }
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC05AO_COLLATERAL_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.realEstatePledgees.pledgee_name === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC05AO_COLLATERAL_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC05AO_COLLATERAL_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.realEstatePledgees.pledgee_name !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        async completeContract() {
            if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC05AO_COLLATERAL.signedDataValid) {
                this.isKornNmSignatureModal = true;
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
    },
}
</script>

<style>

</style>