<template>
    <div>

    </div>
</template>
<script>
export default {
    name: 'ServerError',
    data() {
        return {
            message: 'PDF 검증 중 오류가 발생하였습니다\n고객센터에 문의해주시기 바랍니다',
        };
    },
    created() {
        alert(this.message);
        window.close()
    },
};
</script>