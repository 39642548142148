<template>
    <div class="spinner-container">
        <div class="backdrop">
            <div class="spinner" />
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = null;
    },
};
</script>

<style scoped>
.spinner-container {
    display: flex ;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
.backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid white;
    border-bottom: 5px solid #0B6CE8;
    animation: spin 0.7s linear infinite;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>