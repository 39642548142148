<template>
    <div class="container" data-contract-company="tflain" data-html-name="대부거래약정서_고객정보 및 서명">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">대부거래약정서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">
                    <article class="corporation_info_article">
                        <div>
                            <div class="logo_img"></div>
                        </div>
                        <div class="corporation_info">
                            서울시 마포구 삼개로 16, 2신관 1층 103호(도화동 근신빌딩)<br/>
                            TEL : 02-6010-0511, FAX ： 02-6010-0711<br/>
                            사업자번호 : 169-81-01699, 대부업등록번호 : 2020-금감원—1923 (대부업)<br/>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">고객정보</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.kornNm }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.birthday | formatContractDate }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성별</div>
                            <div>{{ contractDetail.gender | formatGender }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">휴대폰</div>
                            <div>{{ contractDetail.hpNo | formatPhoneNr }}</div>
                        </div>
                        <div class="common_box last">
                            <div class="user_info_title">현재 집주소(주민등본상)</div>
                            <div>{{ contractDetail.addr }}</div>
                        </div>
                        <div class="comment gray none">※ 주민등록 등본 확인을 위해 현재 주소가 맞는지 확인하세요.</div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">주채무자</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isNameSignatureModal" @confirmBtn="checkNameSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'

export default {
    components: {
        SignatureDebtor,
    },
    data() {
        return {
            showSignatureDebtor: false,
            contractDetail: null,
            isKornNm: false,

            // check
            isNameSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC02TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainCommonContractStore,
        }),
        signedData() {
            const data = this.EC02TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC02TF.name
        },
        signatureColor() {
            return this.EC02TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC02TF
            return setPath.signedDataValid
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC02TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC02TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC02TF_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkNameSignatureModal() {
            this.isNameSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (!this.EC02TF.signedDataValid) {
                this.isNameSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent})
            }
        },
    }
}
</script>

<style>

</style>