<template>
    <div class="container" data-contract-company="tflain" data-html-name="채권 양수 양도 계약서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <!-- class : show로 처리 -->
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">채권양도통지 위임장</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">수신</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.lessor }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.lessorAddress }}</div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">양도채권의 표시</div>
                        </div>
                        <table class="terms_table">
                            <tbody>
                                <tr>
                                    <th>물건의 표시</th>
                                    <td>
                                        <span class="emp_bold">"{{ contractDetail.addr }}"</span>에 관한 임대차보증금 반환채권
                                    </td>
                                </tr>
                                <tr>
                                    <th>채권의 종류</th>
                                    <td>
                                        임대차보증금 <span class="emp_bold">{{ contractDetail.guaranteeDeposit | amountInKorean }}&nbsp;&nbsp;{{ contractDetail.guaranteeDeposit | comma }}</span>원정을 포함한 임대차보증금 반환청구 채권금 전부
                                    </td>
                                </tr>
                                <tr>
                                    <th>양도 채권액</th>
                                    <td>
                                        <span class="emp_bold">{{ contractDetail.amountDebtAssignment | amountInKorean }}</span>원정
                                    </td>
                                </tr>
                                <tr>
                                    <th>양도 일자</th>
                                    <td>
                                        <span class="emp_bold">{{ contractDetail.transferDate | formatContractDate }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <article>
                        <div class="area_block">
                            본인이 귀하에 대하여 가지는 위 채권을 아래 양수인에게 양도하였으므로 민법 제450조의 규정에 따라 통지하오니 동 채권을 양수인에게 지불하여 주시기 바랍니다.
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">양수인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ '㈜티플레인' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ '서울시 마포구 삼개로 16, 1층 103호 (근신빌딩 별관)' }}</div>
                        </div>
                        <div class="comment gray none">* 양도인 퇴거 요청시 또는 관련 문의사항은 Tel 02-6010-0511 / Fax 02-6010-0711 연락 부탁드립니다.</div>
                    </article>

                    <article>
                        <div class="label_case">날짜</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.cntrctDt | formatDate }}</div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">발신 - 양도인 겸 통지인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.kornNm }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.addr }}</div>
                        </div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">본인</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                    <article>
                        <div class="area_block">
                            위 채권 양도인이 <span class="emp_bold">{{ contractDetail.transferDate | formatContractDate }}</span> 자로 채권양수인인 <span class="emp_bold">(주)티플레인</span>에게 다음 채권을 전액 양도하였는바 ,양도한 채권의 제3채무자에 대한 채권양도통지를 채권양수인에게 위임함으로써, 채권양수인이 채권양도인을 대리하여 적법하게 제3채무자에 대한 채권양도통지를 할 수 있는 권한을 수여합니다.
                        </div>
                    </article>

                </section>

                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            대출거래 계약하기
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <InicisComponent ref="openPopup"/>
    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'
import InicisComponent from "@/components/InicisComponent.vue";

export default {
    components: {
        SignatureDebtor,
        InicisComponent,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureDebtor: false,
            name: '',
            isKornNm: false,

            // check
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC01TF', 'EC02TF', 'EC03TF', 'EC04TF', 'EC05TF', 'EC08TF', 'EC09TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC09TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='80' height='38' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC09TF.name
        },
        signatureColor() {
            return this.EC09TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC09TF
            return setPath.signedDataValid
        },
        contractSignedData() {
            return {
                signedData01: this.EC01TF.signedData,
                signedData02: this.EC02TF.signedData,
                signedData03: this.EC03TF.signedData,
                signedData04: this.EC04TF.signedData,
                signedData05: this.EC05TF.signedDataAgreementFirst,
                signedData06: this.EC05TF.signedDataAgreementSecond,
                signedData07: this.EC08TF.signedData,
                signedData08: this.EC09TF.signedData,
                PCICollectionCheckbox: this.convertToString(this.EC04TF.PCICollectionCheckbox),
                PCIProvideCheckbox: this.convertToString(this.EC04TF.PCIProvideCheckbox),
                marketingChannelPhoneCheckbox: this.convertToString(this.EC04TF.marketingChannelPhoneCheckbox),
                marketingChannelSNSCheckbox: this.convertToString(this.EC04TF.marketingChannelSNSCheckbox),
                marketingChannelWritingCheckbox: this.convertToString(this.EC04TF.marketingChannelWritingCheckbox),
                marketingChannelEmailCheckbox: this.convertToString(this.EC04TF.marketingChannelEmailCheckbox),
                kakaoAlertingServiceCheckbox: this.convertToString(this.EC04TF.kakaoAlertingServiceCheckbox)
            }
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            })
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC09TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC09TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC09TF_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        async completeContract() {
            if (!this.EC09TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('TflainIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            await this.$refs.openPopup.openPopup()
                        }
                    });
            }

        },
    }
}
</script>

<style>

</style>