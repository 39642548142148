import {store} from "@/store";

import regType04_01 from "@/views/aone/EC01AO.vue";
import regType04_02 from "@/views/aone/EC02AO.vue";
import regType04_03 from "@/views/aone/EC03AO.vue";
import regType04_04 from "@/views/aone/EC04AO.vue";
import regType04_05 from "@/views/aone/EC07AO.vue";

export default [
    {
        path: '/contract/04',
        component: regType04_01,
        name: 'regType04_01',
        props: {nextComponent: 'regType04_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q)
                localStorage.removeItem('vuex');
                store.commit('AoneIndividualContractStore/RESET');
                if (response.data.result === true) {
                    next();
                } else {
                    localStorage.removeItem('vuex');
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/04/02',
        component: regType04_02,
        name: 'regType04_02',
        props: {nextComponent: 'regType04_03'},
    },
    {
        path: '/contract/04/03',
        component: regType04_03,
        name: 'regType04_03',
        props: {nextComponent: 'regType04_04'},
    },
    {
        path: '/contract/04/04',
        component: regType04_04,
        name: 'regType04_04',
        props: {nextComponent: 'regType04_05'},
    },
    {
        path: '/contract/04/05',
        component: regType04_05,
        name: 'regType04_05',
        props: {nextComponent: 'regType04_06'},
    },
]