import { fetchContractDetail, } from "@/api/contract";

const commonContractStore = {
    namespaced: true,
    state: {
        contractDetail: {
            no: null,
            mgNo: "",
            bizCode: "",
            loanNo: "",
            docuNo: "",
            kornNm: "",
            birthday: "",
            gender: "",
            hpNo: "",
            addr: "",
            aplyRate: 0,
            delayRate: 0,
            vaAcntNo: "",
            pymtDd: "",
            repayMthd: "",
            loanAmt: "",
            cntrctDt: "",
            matDt: "",
            rateTop: 0,
            workerId: "",
            feeAmt: "",
            status: "",
            loanBal: "",
            loanTrm: "",
            regType: "",
            otherCostAmt: "",
            mortgAppraisalFee: "",
            regLicenseTaxAmt: "",
            nationalBondPurchaseAmt: "",
            collateralSettingFee: "",
            cusRepayAccBankNm: "",
            initRepayCond: "",
            loanIntRateType: "",
            earlyRepayFee: "",
            cusRepayAccHolderNm: "",
            bondPreservation: "",
            autoCreditorName: "",
            autoCreditorLoanNumber: "",
            autoCreditorBusinessNumber: "",
            autoCreditorAddress: "",
            autoCreditorPhone: "",
            autoCreditorFax: "",
            autoDebtorName: "",
            autoDebtorBirth: "",
            autoDebtorAddress: "",
            autoDebtorPhone: "",
            autoVehicleNumber: "",
            autoVehicleModel: "",
            autoVehicleVin: "",
            autoVehicleYear: "",
            autoInitialPledgeDate: "",
            autoInitialPledgeAmount: "",
            autoExpectedLoanDate: "",
            autoExpectedLoanAmount: "",
            autoExpectedPledgeAmount: "",
            autoAgreementDate: "",
            rEstateCreditorName: "",
            rEstateCreditorLoanNumber: "",
            rEstateCreditorBusinessNumber: "",
            rEstateCreditorAddress: "",
            rEstateCreditorPhone: "",
            rEstateCreditorFax: "",
            rEstateDebtorName: "",
            rEstateDebtorBirth: "",
            rEstateDebtorAddress: "",
            rEstateDebtorPhone: "",
            rEstatePropertyAddress: "",
            rEstateInitialPledgeDate: "",
            rEstateInitialPledgeAmount: "",
            rEstateExpectedLoanRecipient: "",
            rEstateExpectedLoanAmount: "",
            rEstateExpectedPledgeAmount: "",
            rEstateAdditionalLoanDate: "",
            rEstateAgreementDate: "",
            rCollateralDebtorName: "",
            rCollateralPledgeAmount: "",
            rCollateralPropertyAddress: "",
            rCollateralOwnerName: "",
            rCollateralLand: "",
            rCollateralBuilding: "",
            rCollateralOther: "",
            rCollateralDocumentDate: "",
            rCollateralProviderAddress: "",
            rCollateralProviderBirth: "",
            rCollateralProviderName: "",
            carCollateralDebtorName: '',
            carCollateralPledgeAmount: '',
            carCollateralVehicleNumber: '',
            carCollateralVehicleModel: '',
            carCollateralOwnerName: '',
            carCollateralProviderAddress: '',
            carCollateralProviderBirth: '',
            carCollateralProviderPhone: '',
            carCollateralProviderName: '',
            addLoanDate: "",
            addDebtorName: "",
            addDebtorAddress: "",
            addCollateralPropertyAddress: "",
            addMortgageAmount: "",
            addDocumentDate: "",
            loanPayTotalLoanAmount: "",
            loanPayDocumentDate: "",
            loanPayDebtorName: "",
            preNegotiationDebtAmount: "",
            preNegotiationLoanPrincipalAmount: "",
            preNegotiationUnpaidInterestAmount: "",
            preNegotiationTotalAmount: "",
            preNegotiationInterestRateAdjustment: "",
            preNegotiationRepaymentMethod: "",
            preNegotiationMaturityDate: "",
            preNegotiationDepositDate: "",
            postNegotiationDebtAmount: "",
            postNegotiationLoanPrincipalAmount: "",
            postNegotiationUnpaidInterestAmount: "",
            postNegotiationTotalAmount: "",
            postNegotiationInterestRateAdjustment: "",
            postNegotiationRepaymentMethod: "",
            postNegotiationRepaymentTerm: "",
            postNegotiationMaturityDate: "",
            postNegotiationDepositDate: "",
            virtualAccountNumber: "",
            virtualAccountBankNm: "",
            debtRestrucDebtorName: "",
            debtRestrucDebtorBirth: "",
            debtRestrucDebtorAddress: "",
            debtRestrucDebtorPhone: "",
            debtRestrucDocumentDate: "",
            financialCompanies: null, // 대출금 제3자 지급동의서
            autoPledges: null, // 차량원용확인서
            realEstatePledges: null, // 부동산원용확인서
            autoJointOwners: null, // 원용확인서(자동차)
            thirdCollateralProviders: null, // 원용확인서(자동차)
            realEstatePledgees: null, // 원용확인서(부동산)
            realEstateJointOwners: null, // 원용확인서(부동산)
        },
    },
    mutations: {
        CONTRACT_DETAIL(state, payload) {
            state.contractDetail = { ...payload }
        },
    },
    actions: {
        async FETCH_CONTRACT({ commit }, payload) {
            const response = await fetchContractDetail(payload);
            if (response.data.result === true) {
                commit('CONTRACT_DETAIL', response.data.contents);
            }
            return response
        },
    },
}

export default commonContractStore