<template>
    <div class="container" data-contract-company="tflain" data-html-name="채권 양도 담보 계약서">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <!-- class : show로 처리 -->
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">채권 양도 양수 계약서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article>
                        <span class="emp_bold">{{ contractDetail.addDocumentDate | formatDate }}</span> 자 금전소비대차 계약에 따라 티플레인대부(주) (이하 “갑”)는 <span class="emp_bold">{{ contractDetail.kornNm }}</span> (이하 “을”)에게 금 <span class="emp_bold">{{ contractDetail.addMortgageAmount | amountInKorean }}</span>원을 대여해 주면서(대출금리 연 20%, 연체금리 연20.0%), 변제의 담보로 ‘을’이 제 3채무자 <span class="emp_bold">{{ contractDetail.lessor }}</span>(이하 “병”이라 함)에 대하여 가지고 있는 임대차보증금반환채권을 “갑”에게 양도하기 위하여 “갑”과 “을”은 아래의 내용으로 임대차보증금반환채권의 양도(양수)계약(이하 “본 계약”이라 함)을 체결한다.
                    </article>

                    <article class="eight_grid">
                        <div class="emp_bold emp_size">제 1 조(양도채권)</div>
                        <div>
                            “을”은 “병”에 대해여 아래 라. 에 기재된 보증금 중 마. 에 기재된 금액에 대한 보증금반환채권을 “갑”에게 담보를 목적으로 양도하고, “갑”은 이를 양수한다.
                        </div>
                        <div class="common_box list_box subtext four_grid">
                            <div class="flex">
                                <div class="alphabet_align">가.</div>
                                <div>채권명 : 임대차계약에 의한 임대보증금 반환채권</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">나.</div>
                                <div>채권자 : {{ '티플레인대부(주)' }}</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">다.</div>
                                <div>채무자 : 임차인 {{ contractDetail.kornNm }}</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">다.</div>
                                <div>제3채무자 : 임대인 {{ contractDetail.lessor }}</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">라.</div>
                                <div>채권액 : 보증금 금 {{ contractDetail.guaranteeDeposit | amountInKorean }}원</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">바.</div>
                                <div>상환일자 : {{ contractDetail.repaymentDate | formatContractDate }}</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">사.</div>
                                <div>임대차 주소지 : {{ contractDetail.lessorAddress }}</div>
                            </div>
                        </div>
                    </article>

                    <article class="eight_grid">
                        <div class="emp_bold emp_size">제 2 조(양도통지)</div>

                        <div class="common_box list_box subtext four_grid">
                            <div class="flex emp_bold">
                                <div class="alphabet_align">1.</div>
                                <div class="mb8">“을”은 채권양도통지의 의무를 “갑”에게 위임하고 갑은 수락한다.</div>
                            </div>
                            <div class="flex emp_bold">
                                <div class="alphabet_align">2.</div>
                                <div>아래 사항에 해당될시 “갑”은 “병”에게 양도통지서를 발송하며, “을”은 이에 동의한다.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">가.</div>
                                <div>“을”이 본 계약의 원인이 되는 금전소비대차계약상에 상환일 지급을 1일 이상 연체할시.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">나.</div>
                                <div>“을”이 본 계약의 원인이 되는 원본계약서를 수령 후 3일이내에 “갑”에게 발송하지 않았을시.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">다.</div>
                                <div>미납된 월세, 통신료, 카드대금을 대출 당일에 납부하는 조건으로 실행하였으나, 이행하지 않았을시.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">라.</div>
                                <div>기한의 이익이 상실 되었을 때.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">마.</div>
                                <div>대출계약 만기일 한달전까지 연장유무에 대한 통지가 없을 때.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">바.</div>
                                <div>신용회복접수, 개인회생·파산 사건을 진행하기 위한 부채증명서 발급, 채무조정신청을 접수한 경우</div>
                            </div>
                        </div>
                    </article>

                    <article class="eight_grid">
                        <div class="emp_bold emp_size">제 3 조(특약사항)</div>

                        <div class="common_box list_box subtext four_grid">
                            <div class="flex emp_bold">
                                <div class="alphabet_align">1.</div>
                                <div>아래 사항에 해당될시 기한의 이익은 상실되며, “을”은 “갑”에게 원금, 이자를 포함한 전액을 일시 상환한다.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">가.</div>
                                <div>본 계약 이전에 “갑”에게 미리 통지하지 않은 선순위 양수권자(질권자)가 존재 할 경우.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">나.</div>
                                <div>본 양도채권에 압류가 진행되어 있을 경우.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">다.</div>
                                <div>“을”이 “병”에게 본 양도채권으로 상계 가능하고 “갑”이 알지 못하는 “병”에 대한 채무가 있는 경우.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">라.</div>
                                <div>“갑”에게 제공한 임대차계약서상 내용과 실제계약 내용이 상이할 경우.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">마.</div>
                                <div>금전소비대차계약 체결일 이후 7영업일 이내에 “갑”에게 제출하기로 약속한 대부계약에 필요한 중요서류 (금전소비대차계약서, 인감증명서 등)를 제공하지 않았을 경우.</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">바.</div>
                                <div>임차부동산의 소유자가 변경되어 임대인이 변경되었음에도 “을”이 “갑”에게 이사실을 사전에 통지하지 않았을 때. (임대인이 변경된 경우 변경된 임대인을 “병”으로 하는 새로운 계약서류를 작성 하여야 함.)</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">사.</div>
                                <div>동일 보증금으로 이중양도한 사실이 확인되었을 경우</div>
                            </div>
                            <div class="flex">
                                <div class="alphabet_align">아.</div>
                                <div class="mb8">양도한 보증금을 양수인의 동의 없이 임의로 일부, 전액을 반환받은 경우.</div>
                            </div>
                            <div class="flex emp_bold">
                                <div class="alphabet_align">2.</div>
                                <div>본 특약사항과 대부거래표준약관의 기한의 이익상실 조항이 상출할 경우 본 특약사항이 우선한다.</div>
                            </div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">양수인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">상호</div>
                            <div>{{ '티플레인대부(주)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ '서울시 마포구 삼개로 16, 1층 103호 (근신빌딩 별관)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">대표자</div>
                            <div>{{ '윤웅원' }}</div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">양도인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.kornNm }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.addr }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.birthday | formatContractDate }}</div>
                        </div>
                    </article>

                    <article>
                        <div class="label_case">날짜</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.cntrctDt | formatDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">채무자(본인)</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'

export default {
    components: {
        SignatureDebtor,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureDebtor: false,
            name: '',
            isKornNm: false,

            //check
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC08TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC08TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='80' height='38' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC08TF.name
        },
        signatureColor() {
            return this.EC08TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC08TF
            return setPath.signedDataValid
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            });
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC08TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC08TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC08TF_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (!this.EC08TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>