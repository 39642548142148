<template>
    <div class="container" data-contract-company="tflain" data-html-name="카카오 알림톡 서비스 이용에 관한 사항">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">카카오 알림톡 서비스 이용에 관한 사항</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div>
                            고객님께 결제대금 및 필수고지사항 등을 안내할 경우 문자메시지를 대체하여 카카오 알림톡을 이용합니다. 와이파이가 아닌 이동통신망으로 접속하여 메시지를 수신할 경우 데이터 요금이 발생할 수 있습니다. 문자메시지 등 당사가 정한 다른 방식으로 안내받길 원하시면 동의를 거부하실 수 있으며, 동의 후에도 수신한 알림톡 메시지에서 차단 버튼을 눌러주시거나 당사로 연락하시어 언제든지 철회 가능합니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>