<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보 수집·이용·조회·제공 동의서_선택">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract" v-if="!showAgreementFirst && !showAgreementSecond && !showAgreementThird">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">개인신용정보에 관한 동의서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default" @change="toggleAllHasFalse">
                    <article class="agreement_intro_article">
                        <div class="article_subheading emp_bold">㈜티플레인대부 귀중</div>
                        <div class="comment gray none">귀사와의 (금융)거래와 관련하여 귀사가 본인의 개인(신용)정보를 수집 - 이용·조회·제공하고자 하는 경우에는『개인정보보호법』제15조, 제17조, 제22조 및 제 24조 '정보통신망 이용촉진 및 정보보호 등에 관한 법률」제22조, 제24조의2, 제25조 및 제26조의2「신용정보의 이용 및 보호에 관한 법률」제32조, 제33조 및 제34조에 따라 동의를 얻어야 합니다. 이에 본인은 귀사가 아래의 내용과 같이 본인의 개인(신용)정보를 처리하는데 동의합니다.</div>
                        <div class="article_subheading emp_bold">선택 동의사항</div>
                        <div class="comment gray none">귀하는 아래 개인(신용)정보의 수집 - 이용 및 제공에 대한 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다. 동의거부에 따른 금융서비스 이용 에 불이익은 없으며, 다만 관련된 상품 및 서비스 안내, 경품지급, 사은행사 등의 편의는 제공받을 수 없습니다.</div>
                    </article>
                    <article @change="PCICollectionCheckbox">
                        <div class="checkbox_wrap agreement_all">
                            <input
                                type="checkbox"
                                id="agreement_depth1_index_ALL"
                                class="checkbox_24 agreement all"
                                v-model="selectAll"
                                @change="toggleAll"
                            >
                            <label for="agreement_depth1_index_ALL">
                                <div class="checkbox_agreement_unactive_icon"></div>
                                <div class="agreement_content">전체동의</div>
                            </label>
                        </div>

                        <div class="agreement_type">
                            <div>1-1. 개인(신용)정보의 선택적 수집·이용 동의</div>
                            <div class="watch_agreement_detail" @click="openAgreementFirst()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_1" class="checkbox_24 agreement" v-model="agreements[0].checked"  @change="syncCheckboxBundleFirst(0)">
                                <label for="agreement_depth1_index_1">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_2" class="checkbox_24 agreement" v-model="agreements[1].checked"  @change="syncCheckboxBundleFirst(1)">
                                <label for="agreement_depth1_index_2">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">
                                        본인은 귀사가 상기 목적으로 본인의 고유식별정보를 처리하는 것에 동의합니다.
                                        <div class="comment gray none">※고유식별정보 : 주민등록번호, 운전면허번호, 여권번호, 외국인등록번호</div>
                                    </div>
                                </label>
                            </template>
                        </AgreementCase>
                    </article>

                    <article @change="PCIProvideCheckbox">
                        <div class="agreement_type">
                            <div>1-2. 개인(신용)정보 선택적 제공에 관한 사항</div>
                            <div class="watch_agreement_detail" @click="openAgreementSecond()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_3" class="checkbox_24 agreement" v-model="agreements[2].checked" @change="syncCheckboxBundleSecond(0)">
                                <label for="agreement_depth1_index_3">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_4" class="checkbox_24 agreement" v-model="agreements[3].checked" @change="syncCheckboxBundleSecond(1)">
                                <label for="agreement_depth1_index_4">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">
                                        본인은 귀사가 상기 목적으로 본인의 고유식별정보를 처리하는 것에 동의합니다.
                                        <div class="comment gray none">※고유식별정보 : 주민등록번호, 운전면허번호, 여권번호, 외국인등록번호</div>
                                    </div>
                                </label>
                            </template>
                        </AgreementCase>
                    </article>

                    <article>
                        <div class="agreement_type with_subtext">
                            <div>상품이용권유 수단 동의</div>
                        </div>

                        <div class="subtext_agreement mb8">기존 계약의 유지·관리를 위한 필수 고지사항은 동의 대상에서 제외됩니다.</div>

                        <div class="depth2_checkbox_bundle" @change="belowToggleAllHasFalse">
                            <AgreementCase>
                                <template #agreementCaseCheckbox>
                                    <input
                                        type="checkbox"
                                        id="agreement_depth2_index_ALL_1"
                                        class="checkbox_24 agreement"
                                        v-model="agreements[4].checked"
                                        @change="belowToggleAll"
                                    >
                                    <label for="agreement_depth2_index_ALL_1">
                                        <div class="checkbox_agreement_unactive_icon"></div>
                                        <div class="agreement_content">
                                            전체
                                        </div>
                                    </label>
                                </template>
                            </AgreementCase>
                            <div class="checkbox_wrap">
                                <input type="checkbox" id="agreement_depth2_index_1" class="checkbox_20 agreement" v-model="agreements[4].belowChecked[0]" @change="marketingChannelPhoneCheckbox">
                                <label for="agreement_depth2_index_1">
                                    <div class="infocheck_unactive_icon"></div>
                                    <div class="agreement_content">
                                        전화
                                    </div>
                                </label>
                            </div>
                            <div class="checkbox_wrap">
                                <input type="checkbox" id="agreement_depth2_index_2" class="checkbox_20 agreement" v-model="agreements[4].belowChecked[1]" @change="marketingChannelSNSCheckbox">
                                <label for="agreement_depth2_index_2">
                                    <div class="infocheck_unactive_icon"></div>
                                    <div class="agreement_content">
                                        문자
                                    </div>
                                </label>
                            </div>
                            <div class="checkbox_wrap">
                                <input type="checkbox" id="agreement_depth2_index_3" class="checkbox_20 agreement" v-model="agreements[4].belowChecked[2]" @change="marketingChannelWritingCheckbox">
                                <label for="agreement_depth2_index_3">
                                    <div class="infocheck_unactive_icon"></div>
                                    <div class="agreement_content">
                                        서면
                                    </div>
                                </label>
                            </div>
                            <div class="checkbox_wrap">
                                <input type="checkbox" id="agreement_depth2_index_4" class="checkbox_20 agreement" v-model="agreements[4].belowChecked[3]" @change="marketingChannelEmailCheckbox">
                                <label for="agreement_depth2_index_4">
                                    <div class="infocheck_unactive_icon"></div>
                                    <div class="agreement_content">
                                        이메일
                                    </div>
                                </label>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div class="agreement_type">
                            <div>1-3. 카카오 알림톡 서비스 이용에 관한 사항</div>
                            <div class="watch_agreement_detail" @click="openAgreementThird()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_5" class="checkbox_24 agreement" v-model="agreements[5].checked" @change="kakaoAlertingServiceCheckbox">
                                <label for="agreement_depth1_index_5">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 결제대금 등을 안내할 경우 카카오 알림톡을 이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <div data-sign="sign_field" class="mt16">
                            <div class="label_case">성명</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>
            </main>
        </div>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <AgreementFirst v-if="showAgreementFirst" @close="closeAgreementFirst" />
        <AgreementSecond v-if="showAgreementSecond" @close="closeAgreementSecond" />
        <AgreementThird v-if="showAgreementThird" @close="closeAgreementThird" />
        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex'

import SignatureDebtor from '@/components/common/SignaturePad.vue'
import AgreementFirst from '@/views/common/tflain/Agreement-1-1.vue'
import AgreementSecond from '@/views/common/tflain/Agreement-1-2.vue'
import AgreementThird from '@/views/common/tflain/Agreement-1-3.vue'

export default {
    components: {
        AgreementFirst,
        AgreementSecond,
        AgreementThird,
        SignatureDebtor,
    },
    props: {
        nextComponent: String,
    },
    data() {
        return {
            contractDetail: null,
            showAgreementFirst: false,
            showAgreementSecond: false,
            showAgreementThird: false,
            selectAll: false,
            belowSelectAll: false,
            agreements: [
                { checked: false },
                { checked: false },
                { checked: false },
                { checked: false },
                { checked: false, belowChecked: [false, false, false, false] },
                { checked: false },
            ],
            showSignatureDebtor: false,
            isKornNm: false,
            needMoreAgreement: false,
            isLoading: false,

            // check
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC04TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC04TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC04TF.name
        },
        signatureColor() {
            return this.EC04TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC04TF
            return setPath.signedDataValid
        },
    },
    methods: {
        // 체크박스 번들화 메소드
        syncCheckboxBundleFirst(index) {
            if (index === 0) {
                this.agreements[1].checked = this.agreements[0].checked;
            }
            else if (index === 1) {
                this.agreements[0].checked = this.agreements[1].checked;
            }
        },
        syncCheckboxBundleSecond(index) {
            if (index === 0) {
                this.agreements[3].checked = this.agreements[2].checked;
            }
            else if (index === 1) {
                this.agreements[2].checked = this.agreements[3].checked;
            }
        },
        // 선택적 체크박스 값
        PCICollectionCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_PCI_COLLECTION_CHECKBOX', this.agreements[0].checked && this.agreements[1].checked)
        },
        PCIProvideCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_PCI_PROVIDE_CHECKBOX', this.agreements[2].checked && this.agreements[3].checked)
        },
        marketingChannelPhoneCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_PHONE_CHECKBOX', this.agreements[4].belowChecked[0])
        },
        marketingChannelSNSCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_SNS_CHECKBOX', this.agreements[4].belowChecked[1])
        },
        marketingChannelWritingCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_WRITING_CHECKBOX', this.agreements[4].belowChecked[2])
        },
        marketingChannelEmailCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_EMAIL_CHECKBOX', this.agreements[4].belowChecked[3])
        },
        kakaoAlertingServiceCheckbox() {
            this.$store.commit('TflainIndividualContractStore/EC04TF_KAKAO_ALERT_SERVICE_CHECKBOX', this.agreements[5].checked)
        },
        // 전체동의 체크박스 관련 메소드
        toggleAll() {
            for (const agreement of this.agreements) {
                agreement.checked = this.selectAll;
                if (Array.isArray(agreement.belowChecked)) {
                    agreement.belowChecked = agreement.belowChecked.map(() => this.selectAll);
                }
            }
            this.PCICollectionCheckbox()
            this.PCIProvideCheckbox()
            this.marketingChannelPhoneCheckbox()
            this.marketingChannelSNSCheckbox()
            this.marketingChannelWritingCheckbox()
            this.marketingChannelEmailCheckbox()
            this.kakaoAlertingServiceCheckbox()
        },
        toggleAllHasFalse() {
            for(let i = 0; i < this.agreements.filter(item => item.checked).length; i++) {
                const hasUnchecked = this.agreements.some(item => item.checked === false);
                if (hasUnchecked) {
                    this.selectAll = false
                } else {
                    this.selectAll = true
                }
            }
        },
        belowToggleAll() {
            if (this.agreements[4].checked) {
                this.agreements[4].belowChecked = this.agreements[4].belowChecked.map(() => true)
                this.agreements[4].belowChecked.forEach(() => {
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_PHONE_CHECKBOX', true)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_SNS_CHECKBOX', true)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_WRITING_CHECKBOX', true)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_EMAIL_CHECKBOX', true)
                })
            } else {
                this.agreements[4].belowChecked = this.agreements[4].belowChecked.map(() => false)
                this.agreements[4].belowChecked.forEach(() => {
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_PHONE_CHECKBOX', false)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_SNS_CHECKBOX', false)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_WRITING_CHECKBOX', false)
                    this.$store.commit('TflainIndividualContractStore/EC04TF_MARKETING_CHANNEL_EMAIL_CHECKBOX', false)
                })
            }
        },
        belowToggleAllHasFalse() {
            for(let i = 0; i < this.agreements[4].belowChecked.length; i++) {
                const hasFalse = this.agreements[4].belowChecked.some(item => item === false)
                if (hasFalse) {
                    this.agreements[4].checked = false
                } else {
                    this.agreements[4].checked = true
                }
            }
        },
        // 동의서 보기
        openAgreementFirst() {
            this.showAgreementFirst = !this.showAgreementFirst
        },
        closeAgreementFirst() {
            this.showAgreementFirst = !this.showAgreementFirst
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        openAgreementSecond() {
            this.showAgreementSecond = !this.showAgreementSecond
        },
        closeAgreementSecond() {
            this.showAgreementSecond = !this.showAgreementSecond
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        openAgreementThird() {
            this.showAgreementThird = !this.showAgreementThird
        },
        closeAgreementThird() {
            this.showAgreementThird = !this.showAgreementThird
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        // methods of signature
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 1104)
            });
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC04TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC04TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC04TF_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (!this.EC04TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent})
            }
        },
    }
}
</script>

<style>

</style>