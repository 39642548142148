<template>
    <div class="container" data-contract-company="tflain" data-html-name="채권양도통지 위임장">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <!-- class : show로 처리 -->
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">채권양도통지 위임장</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">수임인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">상호</div>
                            <div>{{ '티플레인대부(주)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ '서울시 마포구 삼개로 16, 1층 103호 (근신빌딩 별관)' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">대표자</div>
                            <div>{{ '윤웅원' }}</div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="title_wrap">
                            <div class="section_title">위임인</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.kornNm }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.addr }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">생년월일</div>
                            <div>{{ contractDetail.birthday | formatContractDate }}</div>
                        </div>
                    </article>

                    <article>
                        <div class="area_block">
                            위 위임인은 <span class="emp_bold">{{ contractDetail.cntrctDt | formatContractDate }}</span> 수임인과 체결한 채권양수도 계약에 따라 위임인이 임대인에 대하여 가지고 있는 보증금 반환청구권과 임대차 보증금 전액을 수임인에게 양도하였는 바, 이에 위임인은 수임인에게 다음과 같은 사항을 위임합니다.
                        </div>
                        <div class="area_block next">- 다음 -</div>
                        <div class="area_block">
                            <div class="text_group">
                                <div class="flex">
                                    <div class="alphabet_align">1.</div>
                                    <div>
                                        위임인은 본 계약이 체결된 날부터 원인 계약에 대한 채무 원리금 등 전액이 완제되는 날까지 수임 인에게 양도한 채권에 관하여 임대인에게 이를 통지할 채권양도통지서의 작성 권한
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">2.</div>
                                    <div>
                                        위 통지서에 날인하여 임대인에게 이를 발송할 권한
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">3.</div>
                                    <div>
                                        위 채권양도통지와 관련하여 임대인으로부터 의사표시를 수령하는 권한
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="alphabet_align">4.</div>
                                    <div>
                                        기타 위 제1항 내지 제3항에 부수하는 일체의 권한
                                    </div>
                                </div>

                            </div>
                        </div>

                    </article>

                    <article>
                        <div class="label_case">날짜</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.cntrctDt | formatDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">채무자(본인)</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColor }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                </section>

                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            대출거래 계약하기
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <InicisComponent ref="openPopup"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'
import InicisComponent from "@/components/InicisComponent.vue";

export default {
    components: {
        SignatureDebtor,
        InicisComponent,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureDebtor: false,
            name: '',
            isKornNm: false,
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC01TF', 'EC02TF', 'EC03TF', 'EC04TF', 'EC05TF', 'EC06TF', 'EC07TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 사인 데이터 관련
        signedData() {
            const data = this.EC07TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='80' height='38' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC07TF.name
        },
        signatureColor() {
            return this.EC07TF.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC07TF
            return setPath.signedDataValid
        },
        contractSignedData() {
            return {
                signedData01: this.EC01TF.signedData,
                signedData02: this.EC02TF.signedData,
                signedData03: this.EC03TF.signedData,
                signedData04: this.EC04TF.signedData,
                signedData05: this.EC05TF.signedDataAgreementFirst,
                signedData06: this.EC05TF.signedDataAgreementSecond,
                signedData07: this.EC06TF.signedData,
                signedData08: this.EC07TF.signedData,
                PCICollectionCheckbox: this.convertToString(this.EC04TF.PCICollectionCheckbox),
                PCIProvideCheckbox: this.convertToString(this.EC04TF.PCIProvideCheckbox),
                marketingChannelPhoneCheckbox: this.convertToString(this.EC04TF.marketingChannelPhoneCheckbox),
                marketingChannelSNSCheckbox: this.convertToString(this.EC04TF.marketingChannelSNSCheckbox),
                marketingChannelWritingCheckbox: this.convertToString(this.EC04TF.marketingChannelWritingCheckbox),
                marketingChannelEmailCheckbox: this.convertToString(this.EC04TF.marketingChannelEmailCheckbox),
                kakaoAlertingServiceCheckbox: this.convertToString(this.EC04TF.kakaoAlertingServiceCheckbox)
            }
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            })
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC07TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC07TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC07TF_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        async completeContract() {
            if (!this.EC07TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('TflainIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            await this.$refs.openPopup.openPopup()
                        }
                    })
            }
        },
    }
}
</script>

<style>

</style>