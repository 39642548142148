import Vue from "vue";

import AgreementCase from "@/components/common/AgreementCase.vue"
import BottomBtn from "@/components/common/BottomBtn.vue"
import CheckboxBottomContentCase from "@/components/common/CheckboxBottomContentCase.vue"
import CheckboxDefault from "@/components/common/CheckboxDefault.vue"
import CommonModal from "@/components/common/CommonModal.vue"
import DatePicker from "@/components/common/DatePicker.vue"
import HeaderBlack from "@/components/common/HeaderBlack.vue"
import HeaderBlue from "@/components/common/HeaderBlue.vue"
import LoadingSpinner from "@/components/common/LoadingSpinner.vue"
import SignatureInput from "@/components/common/SignatureInput.vue"
import SignaturePad from "@/components/common/SignaturePad.vue"
import SignatureTextCase from "@/components/common/SignatureTextCase.vue"

Vue.component("AgreementCase",AgreementCase);
Vue.component("BottomBtn",BottomBtn);
Vue.component("CheckboxBottomContentCase",CheckboxBottomContentCase);
Vue.component("CheckboxDefault",CheckboxDefault);
Vue.component("CommonModal",CommonModal);
Vue.component("DatePicker",DatePicker);
Vue.component("HeaderBlack",HeaderBlack);
Vue.component("HeaderBlue",HeaderBlue);
Vue.component("LoadingSpinner",LoadingSpinner);
Vue.component("SignatureInput",SignatureInput);
Vue.component("SignaturePad",SignaturePad);
Vue.component("SignatureTextCase",SignatureTextCase);

import CompleteContract from "@/views/common/aone/CompleteContract.vue"
import ProductInstruction from "@/views/common/aone/ProductInstruction.vue"
import ContractDetailContent from "@/views/common/aone/ContractDetailContent.vue"
import StandardizedAgreement from "@/views/common/aone/StandardizedAgreement.vue"
import ProvideSecurity from "@/views/common/aone/ProvideSecurity.vue"

Vue.component("CompleteContract",CompleteContract);
Vue.component("ProductInstruction",ProductInstruction);
Vue.component("ContractDetailContent",ContractDetailContent);
Vue.component("StandardizedAgreement",StandardizedAgreement);
Vue.component("ProvideSecurity",ProvideSecurity);

export default {
  name: 'GlobalComponents'
}