<template>
    <div id="app">
        <div>
            <!--        <transition name="slide-fade">-->
            <router-view/>
            <!--        </transition>-->
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';
</style>
