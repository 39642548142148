<template>
  <div class="common_box sign_case">
    <div class="sign_title">
      <slot name="title">
        <div class="title">default</div>
      </slot>
    </div>

    <div class="text_case_wrapper">
      <div class="signature_wrap">
       <slot name="signature">
         <input type="text" placeholder="홍길동" id="sign" class="sign">
         <label for="sign">서명하기</label>
       </slot>
      </div>
    </div>
  </div>
 </template>
 <script>
 export default {
  
 }
 </script>
 <style>
   
 </style>