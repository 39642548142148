import DuplicateContractError from "@/views/error/DuplicateContractError.vue";
import ContractExpiredError from "@/views/error/ContractExpiredError.vue";
import CommonErrorComponent from "@/views/error/CommonErrorComponent.vue";
import ServerErrorComponent from "@/views/error/ServerErrorComponent.vue";
import ResentContractComponent from "@/views/error/ResentContractComponent.vue";

export default [
    {
        path: '/duplicateContractError',
        component: DuplicateContractError,
        name: 'DuplicateContractError',
    },
    {
        path: '/contractExpiredError',
        component: ContractExpiredError,
        name: 'ContractExpiredError',
    },
    {
        path: '/resentContractComponent',
        component: ResentContractComponent,
        name: 'ResentContractComponent',
    },
    {
        path: '/commonErrorComponent',
        component: CommonErrorComponent,
        name: 'CommonErrorComponent',
    },
    {
        path: '/serverErrorComponent',
        component: ServerErrorComponent,
        name: 'ServerErrorComponent',
    },
]