import { fetchElectronicSignature } from "@/api/inicis";

const inicisStore = {
    namespaced: true,
    state: {
        inicisDetail: {
            authHash: '',
            bizCode: '',
            directAgency: '',
            loanNo: '',
            docuNo: '',
            mgNo: '',
            failedUrl: '',
            figFixedUser: '',
            identifier: '',
            inicisMid: '',
            mtxId: '',
            reqSvcCd: '',
            successUrl: '',
            userBirth: '',
            userHash: '',
            userName: '',
            userPhone: '',
        },
        inicisResult: {
            loanNo: '',
            docuNo: '',
            mgNo: '',
            bizCode: '',
            resultCode: '',
            resultMsg: '',
            providerDvcCd: '',
            signedData: '',
            svcCd: '',
            txId: '',
            userCi: '',
            esDate: null,
        },
    },
    mutations: {
        INICIS_DETAIL(state, payload) {
            state.inicisDetail.authHash = payload.authHash
            state.inicisDetail.bizCode = payload.bizCode
            state.inicisDetail.loanNo = payload.loanNo
            state.inicisDetail.docuNo = payload.docuNo
            state.inicisDetail.directAgency = payload.directAgency
            state.inicisDetail.failedUrl = payload.failedUrl
            state.inicisDetail.figFixedUser = payload.figFixedUser
            state.inicisDetail.identifier = payload.identifier
            state.inicisDetail.inicisMid = payload.inicisMid
            state.inicisDetail.mgNo = payload.mgNo
            state.inicisDetail.mtxId = payload.mtxId
            state.inicisDetail.reqSvcCd = payload.reqSvcCd
            state.inicisDetail.successUrl = payload.successUrl
            state.inicisDetail.userBirth = payload.userBirth
            state.inicisDetail.userHash = payload.userHash
            state.inicisDetail.userName = payload.userName
            state.inicisDetail.userPhone = payload.userPhone
        },
    },
    actions: {
        async FETCH_INICIS({ commit }, payload) {
            const response = await fetchElectronicSignature(payload)
            if (response.data.result === true) {
                commit('INICIS_DETAIL', response.data.contents)
            }
            return response
        },
    }
}

export default inicisStore