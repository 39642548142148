const individualContractStore = {
    namespaced: true,
    state: {
        EC01AO: {
            productInstructionCheckbox: false, // 대출 상품 설명서 체크박스

            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC03AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC04AO: {
            contractDetailContentCheckbox: false, // 대부계약체결상세내용 체크박스
            standardizedAgreementCheckbox: false, // 대부거래 표준약관 체크박스
            provideSecurityCheckbox: false, // 채권양도 ∙ 양도담보제공승낙서 체크박스

            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크

            price: '', // 대출금액
            priceValid: false, // 대출금액체크
            priceColor: true, // 대출금액 클래스 체크

            contractDate: '', // 계약일
            contractDateValid: false, // 계약일 체크
            contractDateColor: true, // 계약일 클래스 체크

            contractEndDate: '', // 만기일
            contractEndDateValid: false, // 만기일 체크
            contractEndDateColor: true, // 만기일 클래스 체크

            yearAplyRate: '', // 연정상이율
            yearAplyRateValid: false, // 연정상이율 체크
            yearAplyRateColor: true,  // 연정상이율 클래스 체크

            monthAplyRate: '', // 월정상이율
            monthAplyRateValid: false, // 월정상이율 체크
            monthAplyRateColor: true,  // 월정상이율 클래스 체크

            yearDelayRate: '', // 연연체이율
            yearDelayRateValid: false, // 연연체이율 체크
            yearDelayRateColor: true,  // 연연체이율 클래스 체크

            monthDelayRate: '', // 월연체이율
            monthDelayRateValid: false, // 월연체이율 체크
            monthDelayRateColor: true,  // 월연체이율 클래스 체크

            agreementPCI: false, // 이메일 수집 이용 동의
        },
        EC05AO_DEBTOR: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC05AO_COLLATERAL: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC05AO_COOWNER: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC06AO_DEBTOR: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC06AO_COLLATERAL: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC06AO_COOWNER: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC07AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC08AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC09AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC10AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크

            guarantorName: '',
            guarantorNameValid: false,
            guarantorNameColorValid: true,
        },
        EC11AO: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        CONTRACT_EMAIL_CHECKED: {
            email: '',
            receiveEmail: '',
            signedData: null,
        },
        completedContract: false,
    },
    getters: {
        EC01AO(state) {
            return state.EC01AO
        },
        EC03AO(state) {
            return state.EC03AO
        },
        EC04AO(state) {
            return state.EC04AO
        },
        EC05AO_DEBTOR(state) {
            return state.EC05AO_DEBTOR
        },
        EC05AO_COLLATERAL(state) {
            return state.EC05AO_COLLATERAL
        },
        EC05AO_COOWNER(state) {
            return state.EC05AO_COOWNER
        },
        EC06AO_DEBTOR(state) {
            return state.EC06AO_DEBTOR
        },
        EC06AO_COLLATERAL(state) {
            return state.EC06AO_COLLATERAL
        },
        EC06AO_COOWNER(state) {
            return state.EC06AO_COOWNER
        },
        EC07AO(state) {
            return state.EC07AO
        },
        EC08AO(state) {
            return state.EC08AO
        },
        EC09AO(state) {
            return state.EC09AO
        },
        EC10AO(state) {
            return state.EC10AO
        },
        EC11AO(state) {
            return state.EC11AO
        },
        CONTRACT_EMAIL_CHECKED(state) {
            return state.CONTRACT_EMAIL_CHECKED
        },
    },
    mutations: {
        // EC01AO
        EC01AO_SIGNATURE_DEBTOR(state, value) {
            state.EC01AO.signedData = value.data
            state.EC01AO.signedDataValid = value.isValid
        },
        EC01AO_SIGNATURE_NAME(state, value) {
            state.EC01AO.name = value.name
            state.EC01AO.nameValid = value.isValid
        },
        EC01AO_SIGNATURE_COLOR(state, value) {
            state.EC01AO.nameColorValid = value
        },
        PRODUCT_INSTRUCTION_MODAL(state, value) {
            state.EC01AO.productInstructionCheckbox = value
        },
        // EC03AO
        EC03AO_SIGNATURE_DEBTOR(state, value) {
            state.EC03AO.signedData = value.data
            state.EC03AO.signedDataValid = value.isValid
        },
        EC03AO_SIGNATURE_NAME(state, value) {
            state.EC03AO.name = value.name
            state.EC03AO.nameValid = value.isValid
        },
        EC03AO_SIGNATURE_COLOR(state, value) {
            state.EC03AO.nameColorValid = value
        },
        // EC04AO
        EC04AO_SIGNATURE_DEBTOR(state, value) {
            state.EC04AO.signedData = value.data
            state.EC04AO.signedDataValid = value.isValid
        },
        EC04AO_SIGNATURE_NAME(state, value) {
            state.EC04AO.name = value.name
            state.EC04AO.nameValid = value.isValid
        },
        EC04AO_SIGNATURE_COLOR(state, value) {
            state.EC04AO.nameColorValid = value
        },
        CONTRACT_DETAIL_CONTENT_MODAL(state, value) {
            state.EC04AO.contractDetailContentCheckbox = value
        },
        STANDARDIZED_AGREEMENT_MODAL(state, value) {
            state.EC04AO.standardizedAgreementCheckbox = value
        },
        PROVIDE_SECURITY_MODAL(state, value) {
            state.EC04AO.provideSecurityCheckbox = value
        },
        EC04AO_PRICE_COLOR(state, value) {
            state.EC04AO.priceColor = value
        },
        EC04AO_PRICE(state, value) {
            state.EC04AO.price = value.name
            state.EC04AO.priceValid = value.isValid
        },
        EC04AO_CONTRACT_DATE_COLOR(state, value) {
            state.EC04AO.contractDateColor = value
        },
        EC04AO_SUCCESS_CONTRACT_DATE(state, value) {
            state.EC04AO.contractDate = value.contractDate
            state.EC04AO.contractDateValid = value.isValid
        },
        EC04AO_FAILED_CONTRACT_DATE(state, value) {
            state.EC04AO.contractDate = value
        },
        EC04AO_CONTRACT_END_DATE_COLOR(state, value) {
            state.EC04AO.contractEndDateColor = value
        },
        EC04AO_SUCCESS_END_CONTRACT_DATE(state, value) {
            state.EC04AO.contractEndDate = value.contractEndDate
            state.EC04AO.contractEndDateValid = value.isValid
        },
        EC04AO_FAILED_END_CONTRACT_DATE(state, value) {
            state.EC04AO.contractEndDate = value
        },
        EC04AO_YEAR_APLY(state, value) {
            state.EC04AO.yearAplyRate = value.name
            state.EC04AO.yearAplyRateValid = value.isValid
        },
        EC04AO_YEAR_APLY_COLOR(state, value) {
            state.EC04AO.yearAplyRateColor = value
        },
        EC04AO_MONTH_APLY(state, value) {
            state.EC04AO.monthAplyRate = value.name
            state.EC04AO.monthAplyRateValid = value.isValid
        },
        EC04AO_MONTH_APLY_COLOR(state, value) {
            state.EC04AO.monthAplyRateColor = value
        },
        EC04AO_YEAR_DELAY(state, value) {
            state.EC04AO.yearDelayRate = value.name
            state.EC04AO.yearDelayRateValid = value.isValid
        },
        EC04AO_YEAR_DELAY_COLOR(state, value) {
            state.EC04AO.yearDelayRateColor = value
        },
        EC04AO_MONTH_DELAY(state, value) {
            state.EC04AO.monthDelayRate = value.name
            state.EC04AO.monthDelayRateValid = value.isValid
        },
        EC04AO_MONTH_DELAY_COLOR(state, value) {
            state.EC04AO.monthDelayRateColor = value
        },
        EC04AO_EMAIL_ADDRESS(state, value) {
            state.EC04AO.emailAddress = value.name
            state.EC04AO.emailValid = value.isValid
        },
        // EC05AO_DEBTOR
        EC05AO_DEBTOR_SIGNATURE_DEBTOR(state, value) {
            state.EC05AO_DEBTOR.signedData = value.data
            state.EC05AO_DEBTOR.signedDataValid = value.isValid
        },
        EC05AO_DEBTOR_SIGNATURE_NAME(state, value) {
            state.EC05AO_DEBTOR.name = value.name
            state.EC05AO_DEBTOR.nameValid = value.isValid
        },
        EC05AO_DEBTOR_SIGNATURE_COLOR(state, value) {
            state.EC05AO_DEBTOR.nameColorValid = value
        },
        // EC05AO_COLLATERAL
        EC05AO_COLLATERAL_SIGNATURE_DEBTOR(state, value) {
            state.EC05AO_COLLATERAL.signedData = value.data
            state.EC05AO_COLLATERAL.signedDataValid = value.isValid
        },
        EC05AO_COLLATERAL_SIGNATURE_NAME(state, value) {
            state.EC05AO_COLLATERAL.name = value.name
            state.EC05AO_COLLATERAL.nameValid = value.isValid
        },
        EC05AO_COLLATERAL_SIGNATURE_COLOR(state, value) {
            state.EC05AO_COLLATERAL.nameColorValid = value
        },
        // EC05AO_COOWNER
        EC05AO_COOWNER_SIGNATURE_DEBTOR(state, value) {
            state.EC05AO_COOWNER.signedData = value.data
            state.EC05AO_COOWNER.signedDataValid = value.isValid
        },
        EC05AO_COOWNER_SIGNATURE_NAME(state, value) {
            state.EC05AO_COOWNER.name = value.name
            state.EC05AO_COOWNER.nameValid = value.isValid
        },
        EC05AO_COOWNER_SIGNATURE_COLOR(state, value) {
            state.EC05AO_COOWNER.nameColorValid = value
        },
        // EC06AO_DEBTOR
        EC06AO_DEBTOR_SIGNATURE_DEBTOR(state, value) {
            state.EC06AO_DEBTOR.signedData = value.data
            state.EC06AO_DEBTOR.signedDataValid = value.isValid
        },
        EC06AO_DEBTOR_SIGNATURE_NAME(state, value) {
            state.EC06AO_DEBTOR.name = value.name
            state.EC06AO_DEBTOR.nameValid = value.isValid
        },
        EC06AO_DEBTOR_SIGNATURE_COLOR(state, value) {
            state.EC06AO_DEBTOR.nameColorValid = value
        },
        // EC06AO_COLLATERAL
        EC06AO_COLLATERAL_SIGNATURE_DEBTOR(state, value) {
            state.EC06AO_COLLATERAL.signedData = value.data
            state.EC06AO_COLLATERAL.signedDataValid = value.isValid
        },
        EC06AO_COLLATERAL_SIGNATURE_NAME(state, value) {
            state.EC06AO_COLLATERAL.name = value.name
            state.EC06AO_COLLATERAL.nameValid = value.isValid
        },
        EC06AO_COLLATERAL_SIGNATURE_COLOR(state, value) {
            state.EC06AO_COLLATERAL.nameColorValid = value
        },
        // EC06AO_COOWNER
        EC06AO_COOWNER_SIGNATURE_DEBTOR(state, value) {
            state.EC06AO_COOWNER.signedData = value.data
            state.EC06AO_COOWNER.signedDataValid = value.isValid
        },
        EC06AO_COOWNER_SIGNATURE_NAME(state, value) {
            state.EC06AO_COOWNER.name = value.name
            state.EC06AO_COOWNER.nameValid = value.isValid
        },
        EC06AO_COOWNER_SIGNATURE_COLOR(state, value) {
            state.EC06AO_COOWNER.nameColorValid = value
        },
        // EC07AO
        EC07AO_SIGNATURE_DEBTOR(state, value) {
            state.EC07AO.signedData = value.data
            state.EC07AO.signedDataValid = value.isValid
        },
        EC07AO_SIGNATURE_NAME(state, value) {
            state.EC07AO.name = value.name
            state.EC07AO.nameValid = value.isValid
        },
        EC07AO_SIGNATURE_COLOR(state, value) {
            state.EC07AO.nameColorValid = value
        },
        // EC08AO
        EC08AO_SIGNATURE_DEBTOR(state, value) {
            state.EC08AO.signedData = value.data
            state.EC08AO.signedDataValid = value.isValid
        },
        EC08AO_SIGNATURE_NAME(state, value) {
            state.EC08AO.name = value.name
            state.EC08AO.nameValid = value.isValid
        },
        EC08AO_SIGNATURE_COLOR(state, value) {
            state.EC08AO.nameColorValid = value
        },
        // EC09AO
        EC09AO_SIGNATURE_DEBTOR(state, value) {
            state.EC09AO.signedData = value.data
            state.EC09AO.signedDataValid = value.isValid
        },
        EC09AO_SIGNATURE_NAME(state, value) {
            state.EC09AO.name = value.name
            state.EC09AO.nameValid = value.isValid
        },
        EC09AO_SIGNATURE_COLOR(state, value) {
            state.EC09AO.nameColorValid = value
        },
        // EC10AO
        EC10AO_SIGNATURE_DEBTOR(state, value) {
            state.EC10AO.signedData = value.data
            state.EC10AO.signedDataValid = value.isValid
        },
        EC10AO_SIGNATURE_NAME(state, value) {
            state.EC10AO.name = value.name
            state.EC10AO.nameValid = value.isValid
        },
        EC10AO_SIGNATURE_COLOR(state, value) {
            state.EC10AO.nameColorValid = value
        },
        EC10AO_GUARANTOR_SIGNATURE_NAME(state, value) {
            state.EC10AO.guarantorName = value.name
            state.EC10AO.guarantorNameValid = value.isValid
        },
        EC10AO_GUARANTOR_SIGNATURE_COLOR(state, value) {
            state.EC10AO.guarantorNameColorValid = value
        },
        // EC11AO
        EC11AO_SIGNATURE_DEBTOR(state, value) {
            state.EC11AO.signedData = value.data
            state.EC11AO.signedDataValid = value.isValid
        },
        EC11AO_SIGNATURE_NAME(state, value) {
            state.EC11AO.name = value.name
            state.EC11AO.nameValid = value.isValid
        },
        EC11AO_SIGNATURE_COLOR(state, value) {
            state.EC11AO.nameColorValid = value
        },
        SIGNED_DATA(state, value) {
            state.CONTRACT_EMAIL_CHECKED.signedData = value.signedData

        },
        // 계약 완료
        COMPLETED_CONTRACT(state, value) {
            state.completedContract = value
        },
        RESET(state) {
            // EC01AO
            state.EC01AO.productInstructionCheckbox = false
            state.EC01AO.name = ''
            state.EC01AO.nameValid = false
            state.EC01AO.nameColorValid = true
            state.EC01AO.signedData = '서명하기'
            state.EC01AO.signedDataValid = false
            // EC03AO
            state.EC03AO.signedData = '서명하기'
            state.EC03AO.signedDataValid = false
            state.EC03AO.nameColorValid = true
            state.EC03AO.name = ''
            state.EC03AO.nameValid = false
            // EC04AO
            state.EC04AO.contractDetailContentCheckbox = false
            state.EC04AO.standardizedAgreementCheckbox = false
            state.EC04AO.provideSecurityCheckbox = false
            state.EC04AO.signedData = '서명하기'
            state.EC04AO.signedDataValid = false
            state.EC04AO.nameColorValid = true
            state.EC04AO.name = ''
            state.EC04AO.nameValid = false
            state.EC04AO.price = ''
            state.EC04AO.priceValid = false
            state.EC04AO.priceColor = true
            state.EC04AO.contractDate = ''
            state.EC04AO.contractDateValid = false
            state.EC04AO.contractDateColor = true
            state.EC04AO.contractEndDate = ''
            state.EC04AO.contractEndDateValid = false
            state.EC04AO.contractEndDateColor = true
            state.EC04AO.yearAplyRate = ''
            state.EC04AO.yearAplyRateValid = false
            state.EC04AO.yearAplyRateColor = true
            state.EC04AO.monthAplyRate = ''
            state.EC04AO.monthAplyRateValid = false
            state.EC04AO.monthAplyRateColor = true
            state.EC04AO.yearDelayRate = ''
            state.EC04AO.yearDelayRateValid = false
            state.EC04AO.yearDelayRateColor = true
            state.EC04AO.monthDelayRate = ''
            state.EC04AO.monthDelayRateValid = false
            state.EC04AO.monthDelayRateColor = true
            state.EC04AO.emailAddress = ''
            state.EC04AO.emailValid = false
            // EC05AO_DEBTOR
            state.EC05AO_DEBTOR.signedData = '서명하기'
            state.EC05AO_DEBTOR.signedDataValid = false
            state.EC05AO_DEBTOR.nameColorValid = true
            state.EC05AO_DEBTOR.name = ''
            state.EC05AO_DEBTOR.nameValid = false
            // EC05AO_COLLATERAL
            state.EC05AO_COLLATERAL.signedData = '서명하기'
            state.EC05AO_COLLATERAL.signedDataValid = false
            state.EC05AO_COLLATERAL.nameColorValid = true
            state.EC05AO_COLLATERAL.name = ''
            state.EC05AO_COLLATERAL.nameValid = false
            // EC05AO_COOWNER
            state.EC05AO_COOWNER.signedData = '서명하기'
            state.EC05AO_COOWNER.signedDataValid = false
            state.EC05AO_COOWNER.nameColorValid = true
            state.EC05AO_COOWNER.name = ''
            state.EC05AO_COOWNER.nameValid = false
            // EC06AO_DEBTOR
            state.EC06AO_DEBTOR.signedData = '서명하기'
            state.EC06AO_DEBTOR.signedDataValid = false
            state.EC06AO_DEBTOR.nameColorValid = true
            state.EC06AO_DEBTOR.name = ''
            state.EC06AO_DEBTOR.nameValid = false
            // EC06AO_COLLATERAL
            state.EC06AO_COLLATERAL.signedData = '서명하기'
            state.EC06AO_COLLATERAL.signedDataValid = false
            state.EC06AO_COLLATERAL.nameColorValid = true
            state.EC06AO_COLLATERAL.name = ''
            state.EC06AO_COLLATERAL.nameValid = false
            // EC06AO_COOWNER
            state.EC06AO_COOWNER.signedData = '서명하기'
            state.EC06AO_COOWNER.signedDataValid = false
            state.EC06AO_COOWNER.nameColorValid = true
            state.EC06AO_COOWNER.name = ''
            state.EC06AO_COOWNER.nameValid = false
            // EC07AO
            state.EC07AO.signedData = '서명하기'
            state.EC07AO.signedDataValid = false
            state.EC07AO.nameColorValid = true
            state.EC07AO.name = ''
            state.EC07AO.nameValid = false
            // EC08AO
            state.EC08AO.signedData = '서명하기'
            state.EC08AO.signedDataValid = false
            state.EC08AO.nameColorValid = true
            state.EC08AO.name = ''
            state.EC08AO.nameValid = false
            // EC09AO
            state.EC09AO.signedData = '서명하기'
            state.EC09AO.signedDataValid = false
            state.EC09AO.nameColorValid = true
            state.EC09AO.name = ''
            state.EC09AO.nameValid = false
            // EC10AO
            state.EC10AO.signedData = '서명하기'
            state.EC10AO.signedDataValid = false
            state.EC10AO.name = ''
            state.EC10AO.nameValid = false
            state.EC10AO.nameColorValid = true
            state.EC10AO.guarantorName = ''
            state.EC10AO.guarantorNameValid = false
            state.EC10AO.guarantorNameColorValid = true
            // EC11AO
            state.EC11AO.signedData = '서명하기'
            state.EC11AO.signedDataValid = false
            state.EC11AO.nameColorValid = true
            state.EC11AO.name = ''
            state.EC11AO.nameValid = false
            // email
            state.CONTRACT_EMAIL_CHECKED.email = ''
            state.CONTRACT_EMAIL_CHECKED.receiveEmail = ''
            state.CONTRACT_EMAIL_CHECKED.signedData = null
        },
    }
}

export default individualContractStore