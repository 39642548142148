<template>
  <div class="common_box check">
    <div class="checkbox_wrap" @click="eventEmit">
      <slot name="checkboxMainTitle">
        <div class="left">
          <!-- class active 추가시 활성화 -->
          <div class="checkbox24_icon"></div>
          <div>default</div>
        </div>
        <button class="seedetail_icon"></button>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    eventEmit() {
      this.$emit('show')
    }
  }
}
</script>
<style>

</style>