<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보 필수적 제공 동의">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">개인(신용)정보 필수적 제공 동의</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div>
                            본인은「개인정보 보호법」제17조, 제22조, 제24조,「신용정보의 이용 및 보호에 관한 법률」제32조, 제33조, 제34조,「정보통신망 이용촉진 및 정보보호 등에 관한 법률」제 24조의2, 제25조, 제26조의2에 따라 귀사가 본인이 신청한 대부거래에 관하여 본인의 개인（신용）정보를 아래와 같이 제3자에게 제공하는 것에 대하여 동의합니다.
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_main_title">신용정보집중기관 및 신용조회회사에 개인(신용)정보 제공</div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공 대상 기관</div>
                        <div>
                            ▪ 신용정보 집중기관 : 한국신용정보원, 차입처(한국신용정보원 등록금융기관, <span class="emp_blue emp_bold">상세 차입처 내용은  상담센터 02-6010-0511로 문의</span>)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공목적</div>
                        <div>
                            본인확인 및 신용도 평가 등 신용조회업무, 신용정보의 집중 관리 및 활용 등 신용정보집중기관의 업무, 계약 등 대부거래의 체결유·지·이행·관리(연체 관리 및 채권관리 포함), 서류접수 대행업무 등 신용조회회사의 업무
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공할 개인(신용)정보</div>
                        <div>
                            ▪ 개인식별정보 : 성명, 자택(직장)소, 고유식별정보, 직업, 연락처, 직장명 및 사업자번호, 입금계좌 등<br/>
                            ▪ 신용거래정보 : 본 계약 이전 및 이후의 대부 - 보증·담보제공 등 거래내용을 판단할 수 있는 정보<br/>
                            ▪ 신용능력정보 : 재산·채무·소득의 총액·납세실적 등<br/>
                            ▪ 신용도판단정보 : 신용등급 또는 개인 신용평점, 연체, 부도, 대위변제, 기타 신용질서 문란행위 관련 금액 등<br/>
                            ▪ 공기관정보 : 개인회생, 파산, 면책, 채무불이행 등 법원의 재판·결정 정보, 체납정보, 경매 관련 정보, 사회보험·공공요금 관련 정보, 행정처분에 관한 정보 등<br/>
                            ▪ 무보증현황: 본 계약 이전 및 이후의 보증현황 포함·신용인증송부로 제출하는 신용정보<br/>
                            ▪ 기타 금융거래의 설정·유지·이행 - 관리를 위한 상담, 채권관리 등을 통해 생성되는 정보 등 (※ 동의 이전에 발생한 상기 개인(신용)정보 포함)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">보유 및 이용기간</div>
                        <div>
                            신용조회회사 및 신용정보집중기관에 제공한 개인(신용)정보는 신용정보의 이용 및 보호에 관한 법률 및 관련 규약에 근거한 기간까지, 수탁업체 에 제공한 개인(신용)정보는 거래종료일(채권·채무관계가 해소된 시점) 이내에서 수탁계약 종료 시까지 보유·이용됩니다. (·〉& 채무불이행정보(연체, 대위변제, 대지 급, 부도 등)는 신용정보법 제32조 1항에 의하여 동의 없이 신용정보집중기관 및 신용조회회사에게 제공될 수 있습니다.)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_main_title">거래목적 달성을 위한 개인(신용)정보 제공</div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공 대상 기관</div>
                        <div>
                            ▪ 전산시스템 관리 : (주)웨인테크놀로지<br/>
                            ▪ 서류접수 수탁업체 : NICE신용정보(주), 코리아 크레딧뷰로(주)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공목적</div>
                        <div>
                            (금융)거래의 설정·유지·이행·관리 등에 필요한 경우로서 위탁 받은 업무의 수행, 금융사고 조사, 법령상 의무이행 채권 매각 및 담보 제공과 관련된 업무 등
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">제공할 개인(신용)정보</div>
                        <div>
                            ▪ 개인식별정보 : 성명, 자택(직장)소, 고유식별정보, 직업, 연락처, 직장명 및 사업자번호, 입금계좌 등<br/>
                            ▪ 신용거래정보 : 본 계약 이전 및 이후의 대부 - 보증·담보제공 등 거래내용을 판단할 수 있는 정보<br/>
                            ▪ 신용능력정보 : 재산·채무·소득의 총액·납세실적 등<br/>
                            ▪ 신용도판단정보 : 신용등급 또는 개인 신용평점, 연체, 부도, 대위변제, 기타 신용질서 문란행위 관련 금액 등<br/>
                            ▪ 공기관정보 : 개인회생, 파산, 면책, 채무불이행 등 법원의 재판·결정 정보, 체납정보, 경매 관련 정보, 사회보험·공공요금 관련 정보, 행정처분에 관한 정보 등<br/>
                            ▪ 무보증현황: 본 계약 이전 및 이후의 보증현황 포함·신용인증송부로 제출하는 신용정보<br/>
                            ▪ 기타 금융거래의 설정·유지·이행 - 관리를 위한 상담, 채권관리 등을 통해 생성되는 정보 등 (※ 동의 이전에 발생한 상기 개인(신용)정보 포함)
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">보유 및 이용기간</div>
                        <div>
                            신용조회회사 및 신용정보집중기관에 제공한 개인(신용)정보는 신용정보의 이용 및 보호에 관한 법률 및 관련 규약에 근거한 기간까지, 수탁업체 에 제공한 개인(신용)정보는 거래종료일(채권 - 채무관계가 해소된 시점) 이내에서 수탁계약 종료 시까지 보유 - 이용됩니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>