import {store} from "@/store";

import regType02_01 from "@/views/aone/EC01AO.vue";
import regType02_02 from "@/views/aone/EC02AO.vue";
import regType02_03 from "@/views/aone/EC03AO.vue";
import regType02_04 from "@/views/aone/EC04AO.vue";

export default [
    {
        path: '/contract/02',
        component: regType02_01,
        name: 'regType02_01',
        props: {nextComponent: 'regType02_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q)
                localStorage.removeItem('vuex');
                store.commit('AoneIndividualContractStore/RESET');
                if (response.data.result === true) {
                    next();
                } else {
                    localStorage.removeItem('vuex');
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/02/02',
        component: regType02_02,
        name: 'regType02_02',
        props: {nextComponent: 'regType02_03'},
    },
    {
        path: '/contract/02/03',
        component: regType02_03,
        name: 'regType02_03',
        props: {nextComponent: 'regType02_04'},
    },
    {
        path: '/contract/02/04',
        component: regType02_04,
        name: 'regType02_04',
        props: {nextComponent: 'regType02_05'},
    },
]