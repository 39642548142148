<template>
    <div>{{ message }}</div>
</template>
<script>
export default {
    data() {
        return {
            message: 'index'
        }
    },
}
</script>