import {store} from "@/store";

import regType03_01 from '@/views/tflain/EC01TF.vue'
import regType03_02 from '@/views/tflain/EC02TF.vue'
import regType03_03 from '@/views/tflain/EC03TF.vue'
import regType03_04 from '@/views/tflain/EC04TF.vue'
import regType03_05 from '@/views/tflain/EC05TF.vue'
import regType03_06 from '@/views/tflain/EC08TF.vue'
import regType03_07 from '@/views/tflain/EC09TF.vue'

export default [
    {
        path: '/contract/t-flain/03',
        component: regType03_01,
        name: 't-flain/regType03_01',
        props: {nextComponent: 't-flain/regType03_02'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('TflainCommonContractStore/FETCH_CONTRACT', to.query.q);
                localStorage.removeItem('vuex');
                if (response.data.result === true) {
                    store.commit('TflainIndividualContractStore/RESET');
                    next();
                } else {
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    {
        path: '/contract/t-flain/03/02',
        component: regType03_02,
        name: 't-flain/regType03_02',
        props: {nextComponent: 't-flain/regType03_03'},
    },
    {
        path: '/contract/t-flain/03/03',
        component: regType03_03,
        name: 't-flain/regType03_03',
        props: {nextComponent: 't-flain/regType03_04'},
    },
    {
        path: '/contract/t-flain/03/04',
        component: regType03_04,
        name: 't-flain/regType03_04',
        props: {nextComponent: 't-flain/regType03_05'},
    },
    {
        path: '/contract/t-flain/03/05',
        component: regType03_05,
        name: 't-flain/regType03_05',
        props: {nextComponent: 't-flain/regType03_06'},
    },
    {
        path: '/contract/t-flain/03/06',
        component: regType03_06,
        name: 't-flain/regType03_06',
        props: {nextComponent: 't-flain/regType03_07'},
    },
    {
        path: '/contract/t-flain/03/07',
        component: regType03_07,
        name: 't-flain/regType03_07',
        props: {nextComponent: 'complete'},
    },
]