<template>
    <div class="date_picker_wrap">
        <div class="backdrop" @click="deletedDate"></div>
        <div class="datepicker_background">
            <div class="picker-group">
                <scroll-picker v-model="selectedYear" :options="years" />
                <scroll-picker v-model="selectedMonth" :options="months" />
                <scroll-picker v-model="selectedDay" :options="days" />
            </div>
            <div class="btn_wrap">
                <button class="cancel_btn" @click="deletedDate">취소</button>
                <button class="confirm_btn" @click="resultDate">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ScrollPicker } from "vue-scroll-picker"

export default {
    components: {
        ScrollPicker,
    },
    data() {
        return {
            contract: null,
            selectedYear: null,
            selectedMonth: null,
            selectedDay: null,
            year: null,
            month: null,
            day: null,
            years: [],
            months: [],
            days: [],
            top: null,
        }
    },
    props: {
        propsDate: {
            type: String,
            default: null
        }
    },
    created() {
        const date = this.propsDate
        this.contract = date
        this.selectedYear = Number(date.substring(0, 4))
        this.selectedMonth = Number(date.substring(4, 6))
        this.selectedDay = Number(date.substring(6, 8))
        this.year = this.selectedYear
        this.month = this.selectedMonth
        this.day = this.selectedDay
        this.initDates()
        // this.setToToday()
    },
    mounted() {
    // body 요소의 스크롤 막기
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
    // body 요소의 스크롤 허용
        document.body.style.overflow = null;
    },
    computed: {
        maxDay() {
            const date = new Date(this.selectedYear, this.selectedMonth, 0)
            return date.getDate()
        },
    },
    watch: {
        selectedYear() {
            this.updateDays()
        },
        selectedMonth() {
            this.updateDays()
        },
    },
    methods: {
        initDates() {
            // 년도 목록 생성
            const currentYear = this.year
            for (let i = 0; i < 50; i++) {
                this.years.push(currentYear - i)
            }

            // 월 목록 생성
            for (let i = 1; i <= 12; i++) {
                this.months.push(i)
            }

            // 일 목록 생성
            for (let i = 1; i <= 31; i++) {
                this.days.push(i)
            }
        },
        clear() {
            this.selectedYear = null
            this.selectedMonth = null
            this.selectedDay = null
        },
        updateDays() {
            if (this.selectedYear && this.selectedMonth) {
                const maxDay = this.maxDay
                this.days = []
                for (let i = 1; i <= maxDay; i++) {
                    this.days.push(i)
                }
                if (this.selectedDay > maxDay) {
                    this.selectedDay = maxDay
                }
            }
        },
        checkDate() {
            const year = this.selectedYear
            const month = this.selectedMonth
            const day = this.selectedDay
            return {
                validResult: this.year === this.selectedYear && this.month === this.selectedMonth && this.day === this.selectedDay,
                date: this.integerDateToString({year, month, day})
            }
        },
        resultDate() {
            this.$emit('resultDatePicker')
        },
        deletedDate() {
            this.$emit('closeDatePicker')
        },
    },
}
</script>
