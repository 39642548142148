<template>
    <div>

    </div>
</template>
<script>
import {mapState} from "vuex";
import {contractInicisFailed} from "@/api/contract";

export default {
    mounted() {
        this.inicisDetail = this.inicisStore.inicisDetail;
        if (this.isChecked === true) {
            contractInicisFailed({bizCode: this.inicisDetail.bizCode, loanNo: this.inicisDetail.loanNo, docuNo: this.inicisDetail.docuNo})
                .then(response => {
                    if (response.data.result === true) {
                        alert(this.message)
                        this.isChecked = false
                    }
                }).catch(error => console.log(error));
        }
        window.close()
    },
    beforeRouteLeave(to, from, next) {
        next(false)
    },
    data() {
        return {
            message: '전자서명 중 오류가 발생하였습니다\n고객센터에 문의해주시기 바랍니다',
            inicisDetail: null,
            isChecked: true,
        }
    },
    computed: {
        ...mapState({
            inicisStore: state => state.inicisStore,
        }),
    },
}
</script>