<template>
    <div class="container" data-contract-company="aone" data-html-name="대부거래약정서_계약서작성">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract" v-if="!showContractDetailContent && !showStandardizedAgreement && !showProvideSecurity">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">대부거래약정서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article>
                        <div class="title_wrap">
                            <div class="article_title">제1조 [거래조건]</div>
                        </div>

                        <div class="comment gray center">※ 계약 내용을 확인하시고 같은 내용으로 입력해 주세요.</div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대출금액</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="priceColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.loanAmt | comma }}원</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    ref="loanAmt"
                                    :placeholder="contractDetail.loanAmt | comma"
                                    :class="{ blue: priceColor }"
                                    :disabled="!priceColor"
                                    :value="price"
                                    @input="isValidPrice"
                                    @blur="isCheckedPrice"
                                >
                                <div class="after">원</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>계약일</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="contractDateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.cntrctDt | formatContractDate }}</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    class="non_cursor"
                                    ref="cntrctDt"
                                    @click="openContractDatePicker()"
                                    :placeholder="contractDetail.cntrctDt | formatContractDate"
                                    :class="{ blue: contractDateColor }"
                                    :disabled="!contractDateColor"
                                    :value="contractDate"
                                >
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>만기일</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="contractEndDateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.matDt | formatContractDate }}</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    class="non_cursor"
                                    ref="matDt"
                                    @click="openContractEndDatePicker()"
                                    :placeholder="contractDetail.matDt | formatContractDate"
                                    :class="{ blue: contractEndDateColor }"
                                    :disabled="!contractEndDateColor"
                                    :value="contractEndDate"
                                >
                            </div>
                        </div>

                        <div class="flex subtext mt8">
                            <div class="asterisk_align">*</div>
                            <div>
                                신규계약 : 채무자가 실제 수령한 금액<br/>
                                연장계약 : 잔존 채무잔액<br/>
                                추가대출계약 : 기 대출금액 + 채무자가 추가로 실제 수령한 금액
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>정상이율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="yearAplyRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>연 {{ contractDetail.aplyRate }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">연</div>
                                <input
                                    type="text"
                                    ref="yearAplyRate"
                                    :placeholder="contractDetail.aplyRate"
                                    :class="{ blue: yearAplyRateColor }"
                                    :disabled="!yearAplyRateColor"
                                    :value="yearAplyRate"
                                    @input="isValidYearAply"
                                    @blur="isCheckedYearAply"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>정상이율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="monthAplyRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>월 {{ contractDetail.aplyRate | divideByTwelve }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">월</div>
                                <input
                                    type="text"
                                    ref="monthAplyRate"
                                    :placeholder="contractDetail.aplyRate | divideByTwelve"
                                    :class="{ blue: monthAplyRateColor }"
                                    :disabled="!monthAplyRateColor"
                                    :value="monthAplyRate"
                                    @input="isValidMonthAply"
                                    @blur="isCheckedMonthAply"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>연체이율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="yearDelayRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>연 {{ contractDetail.delayRate }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">연</div>
                                <input
                                    type="text"
                                    ref="yearDelayRate"
                                    :placeholder="contractDetail.delayRate"
                                    :class="{ blue: yearDelayRateColor }"
                                    :disabled="!yearDelayRateColor"
                                    :value="yearDelayRate"
                                    @input="isValidYearDelay"
                                    @blur="isCheckedYearDelay"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>연체이율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="monthDelayRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>월 {{ contractDetail.delayRate | divideByTwelve }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">월</div>
                                <input
                                    type="text"
                                    ref="monthDelayRate"
                                    :placeholder="contractDetail.delayRate | divideByTwelve"
                                    :class="{ blue: monthDelayRateColor }"
                                    :disabled="!monthDelayRateColor"
                                    :value="monthDelayRate"
                                    @input="isValidMonthDelay"
                                    @blur="isCheckedMonthDelay"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                    </article>

                    <article>
                        <div class="comment dark_blue">대출 계약 체결 상세 내용을 꼭 확인하세요.</div>
                        <CheckboxDefault @show="openContractDetailContent()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <!-- class active 추가시 활성화 -->
                                    <div class="checkbox24_icon"
                                         :class="{ active: contractDetailContentCheckbox }"></div>
                                    <div>대부 계약 체결 상세 내용</div>
                                </div>
                                <button class="seedetail_icon" ref="contractDetailContent"></button>
                            </template>
                        </CheckboxDefault>
                    </article>

                    <article v-if="contractDetail.regType !== '13' && contractDetail.regType !== '14'">
                        <div class="comment dark_blue">대부거래 표준약관을 꼭 확인하세요.</div>
                        <CheckboxBottomContentCase @show="openStandardizedAgreement()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <input type="checkbox" id="" class="checkbox_24">
                                    <label for="">
                                        <div class="checkbox24_icon"
                                             :class="{ active: standardizedAgreementCheckbox }"></div>
                                        <div>대부거래 표준약관</div>
                                    </label>
                                </div>
                            </template>
                            <button slot="arrow_part" class="seedetail_icon" ref="standardizedAgreement"></button>
                            <template #checkboxContent>
                                <div class="text_case">
                                    본인은 위 대부거래계약의 내용 및 대부거래약관이 적용됨을 승인하고 성실히 이행할 것을 확약합니다.
                                </div>
                            </template>
                        </CheckboxBottomContentCase>
                    </article>

                    <article>
                        <div class="title_wrap">
                            <div class="article_title">제2조 [채권양도 및 양도담보제공 승낙서]</div>
                        </div>

                        <div class="comment dark_blue">채권양도 및 양도담보제공 승낙서를 꼭 확인하세요.</div>

                        <CheckboxBottomContentCase @show="openProvideSecurity()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <input type="checkbox" id="" class="checkbox_24">
                                    <label for="">
                                        <div class="checkbox24_icon" :class="{ active: provideSecurityCheckbox }"></div>
                                        <div>채권양도 ∙ 양도담보제공승낙서</div>
                                    </label>
                                </div>
                            </template>

                            <button slot="arrow_part" class="seedetail_icon" ref="provideSecurity"></button>

                            <template #checkboxContent>
                                <div class="sign_case">
                                    <SignatureInput>
                                        <template #signature>
                                            <!-- blue : 미일치 / black : 일치 -->
                                            <input
                                                type="text"
                                                id="sign"
                                                class="sign"
                                                ref="kornNm"
                                                :placeholder="contractDetail.kornNm"
                                                :value="returnName"
                                                :class="{ blue: signatureColor }"
                                                :disabled="!signatureColor"
                                                @input="isValidName"
                                                @blur="isValidKornNm"
                                            >
                                            <label for="sign" @click="openSignReaded()">
                                                <button v-html="signedData" ref="kornNmSignature"></button>
                                            </label>
                                        </template>
                                    </SignatureInput>
                                </div>
                            </template>
                        </CheckboxBottomContentCase>
                    </article>

                    <article>
                        <div class="title_wrap">
                            <div class="article_title">대출계약 주요사항 설명 및 교부</div>
                        </div>

                        <div class="common_box check_case">
                            <div class="title">채무자는 다음 사항을 읽고 본인의 의사를 사실에 근거하여 기재하여 주십시오. (기재예시 : 1. 수령함, 2. 들었음, 3.
                                들었음)
                            </div>

                            <div>
                                <div class="content">1. 이 약정서 및 대부거래표준약관을 확실히 수령하였습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <!-- checked 시에 활성화 -->
                                        <input type="radio" id="radio_1" name="radioSection_1"
                                               @click="validAgreementReceived(true)"
                                               :checked="agreementReceived === true"
                                               ref="agreementReceived"
                                        >
                                        <label for="radio_1">
                                            <span>수령함</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_2" name="radioSection_1"
                                               @click="validAgreementReceived(false)"
                                               :checked="agreementReceived === false"
                                        >
                                        <label for="radio_2">
                                            <span>수령하지 않음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="content align">2. 이 약정서 및 대부거래표준약관의 중요한 내용에 대하여 설명을 들었습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_3" name="radioSection_2"
                                               @click="validAgreementExplain(true)"
                                               :checked="agreementExplanationReceived === true"
                                               ref="agreementExplanationReceived"
                                        >
                                        <label for="radio_3">
                                            <span>들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_4" name="radioSection_2"
                                               @click="validAgreementExplain(false)"
                                               :checked="agreementExplanationReceived === false"
                                        >
                                        <label for="radio_4">
                                            <span>못 들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="content align">3. 대부중개업자가 중개수수료를 고객으로 부터 받는 것이 불법임을 설명 들었습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_5" name="radioSection_3"
                                               @click="validIllegalCommission(true)"
                                               :checked="illegalCommissionReceivedExplained === true"
                                               ref="illegalCommissionReceivedExplained"
                                        >
                                        <label for="radio_5">
                                            <span>들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_6" name="radioSection_3"
                                               @click="validIllegalCommission(false)"
                                               :checked="illegalCommissionReceivedExplained === false"
                                        >
                                        <label for="radio_6">
                                            <span>못 들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div class="title_wrap">
                            <div class="article_title">이메일 수집 이용 동의(필수)</div>
                        </div>

                        <div class="common_box email_box">
                            <AgreementCase>
                                <template #agreementCaseCheckbox>
                                    <input type="checkbox" id="agreement_email_receive" class="checkbox_24 agreement"
                                           v-model="isChecked">
                                    <label for="agreement_email_receive">
                                        <div class="checkbox_agreement_unactive_icon"></div>
                                        <div class="agreement_content">
                                            개인신용정보의 수집·이용 동의에 따라 이메일 수집을 동의합니다.
                                        </div>
                                    </label>
                                </template>
                            </AgreementCase>

                            <div class="email_input_group_wrap">
                                <div class="email_input_group">
                                    <input type="email"
                                           id="email_input"
                                           class="email_input"
                                           placeholder="Email 입력"
                                           v-model="emailID"
                                           @input="checkFillEmailAddress"
                                    >
                                    <label for="email_input">
                                        <div class="email_unactive_icon"></div>
                                    </label>
                                </div>

                                <div class="email_select_group">
                                    <select class="email_select" name="email_select" v-model="emailDomain"
                                            @change="checkFillEmailAddress">
                                        <option value="@naver.com">naver.com</option>
                                        <option value="@gmail.com">gmail.com</option>
                                        <option value="@daum.net">daum.net</option>
                                        <option value="@nate.com">nate.com</option>
                                        <option value="@hotmail.com">hotmail.com</option>
                                    </select>
                                </div>
                            </div>

                            <div class="email_checking_msg" :class="{fillText: fillEmailAddress}">
                                <div class="failed_icon"></div>
                                <p class="check_text" v-if="fillEmailAddress === false">이메일 아이디와 도메인을 입력해주세요.</p>
                                <p class="check_text" v-else>올바르게 작성했는지 다시 한번 확인해 주세요.</p>
                            </div>
                        </div>

                        <div class="comment dark_blue">
                            <div>이메일 오기재로 인한 대부약정서 오발송시 채무자 본인에게 책임이 있음을 알려드립니다. 정확히 입력바랍니다.</div>
                        </div>

                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap" v-if="nextComponent === 'complete'">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>
                <section class="bottomBtn_wrap" v-else>
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>
            </main>
        </div>

        <CommonModal v-if="isLoanAmt" @confirmBtn="confirmLoanAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">대출금액</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="failedContractDateModal" @confirmBtn="confirmModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부일자</span>가<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="failedContractEndDateModal" @confirmBtn="confirmModal()">
            <div slot="ModalContent">
                <span class="emp_red">만기일자</span>가<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearAplyRate" @confirmBtn="confirmYearAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthAplyRate" @confirmBtn="confirmMonthAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearDelayRate" @confirmBtn="confirmYearDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthDelayRate" @confirmBtn="confirmMonthDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isLoanAmtModal" @confirmBtn="checkLoanAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">대출금액</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isCntrctDtModal" @confirmBtn="checkCntrctDtModal()">
            <div slot="ModalContent">
                <span class="emp_red">계약일</span>을<br>
                선택해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMatDtModal" @confirmBtn="checkMatDtModal()">
            <div slot="ModalContent">
                <span class="emp_red">만기일</span>을<br>
                선택해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearAplyRateModal" @confirmBtn="checkYearAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상 연이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthAplyRateModal" @confirmBtn="checkMonthAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상 월이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearDelayRateModal" @confirmBtn="checkYearDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체 연이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthDelayRateModal" @confirmBtn="checkMonthDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체 월이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isContractDetailContentModal" @confirmBtn="checkContractDetailContentModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부 계약 체결 상세 내용</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isStandardizedAgreementModal" @confirmBtn="checkStandardizedAgreementModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부거래 표준약관</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isProvideSecurityModal" @confirmBtn="checkProvideSecurityModal()">
            <div slot="ModalContent">
                <span class="emp_red">채권양도∙양도담보제공승낙서</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">서명</span>을<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isAgreementReceivedModal" @confirmBtn="checkAgreementReceivedModal()">
            <div slot="ModalContent">
                <span class="emp_red">수령함</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isAgreementExplanationReceivedModal" @confirmBtn="checkAgreementExplanationReceivedModal()">
            <div slot="ModalContent">
                <span class="emp_red">들었음</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isIllegalCommissionReceivedExplainedModal"
                     @confirmBtn="checkIllegalCommissionReceivedExplainedModal()">
            <div slot="ModalContent">
                <span class="emp_red">들었음</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isEmailCheckboxModal" @confirmBtn="checkEmailCheckboxModal()">
            <div slot="ModalContent">
                <span class="emp_red">이메일 수집 이용동의</span>를<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isFillEmailAddressModal" @confirmBtn="checkFillEmailAddressModal()">
            <div slot="ModalContent">
                <span class="emp_red">이메일 아이디와 도메인</span>을<br>
                확인해주시기 바랍니다.
            </div>
        </CommonModal>

        <ContractDetailContent v-if="showContractDetailContent" @close="closeContractDetailContent"/>
        <StandardizedAgreement v-if="showStandardizedAgreement" @close="closeStandardizedAgreement"/>
        <ProvideSecurity v-if="showProvideSecurity" @close="closeProvideSecurity"/>

        <ContractDatePicker v-if="showContractDatePicker" :propsDate="contractDetail.cntrctDt" ref="contractDatePicker"
                            @closeDatePicker="closeContractDatePicker" @resultDatePicker="resultContractDatePicker"/>
        <ContractEndDatePicker v-if="showContractEndDatePicker" :propsDate="contractDetail.matDt"
                               ref="contractEndDatePicker" @closeDatePicker="closeContractEndDatePicker"
                               @resultDatePicker="resultContractEndDatePicker"/>
        <SignatureReaded v-if="showSignatureReaded" ref="signaturePad" @close="closeSignReaded()"
                         @save="saveSignatureProvideSecurity" :propsName="contractDetail.kornNm"/>
        <InicisComponent ref="openPopup"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex'

import ContractDetailContent from '@/views/common/aone/ContractDetailContent.vue'
import StandardizedAgreement from '@/views/common/aone/StandardizedAgreement.vue'
import ProvideSecurity from '@/views/common/aone/ProvideSecurity.vue'
import InicisComponent from "@/components/InicisComponent.vue";

import ContractDatePicker from '@/components/common/DatePicker.vue'
import ContractEndDatePicker from '@/components/common/DatePicker.vue'
import SignatureReaded from '@/components/common/SignaturePad.vue'
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        ContractDatePicker,
        ContractEndDatePicker,
        ContractDetailContent,
        StandardizedAgreement,
        ProvideSecurity,
        SignatureReaded,
        InicisComponent,
    },
    props: {
        nextComponent: String,
    },
    data() {
        return {
            showContractDetailContent: false,
            showStandardizedAgreement: false,
            showProvideSecurity: false,
            failedContractDateModal: false,
            failedContractEndDateModal: false,
            isLoanAmt: false,
            isYearAplyRate: false,
            isMonthAplyRate: false,
            isYearDelayRate: false,
            isMonthDelayRate: false,
            isKornNm: false,
            showBirthdayDatePicker: false,
            showContractDatePicker: false,
            showContractEndDatePicker: false,
            showSignatureReaded: false,
            isLoading: false,
            contractDetail: null,
            agreementReceived: true,
            agreementExplanationReceived: true,
            illegalCommissionReceivedExplained: true,
            isChecked: false,
            isEmail: false,
            emailID: '',
            emailDomain: '',
            emailAddress: '',
            fillEmailAddress: false,

            // check
            isLoanAmtModal: false,
            isCntrctDtModal: false,
            isMatDtModal: false,
            isYearAplyRateModal: false,
            isMonthAplyRateModal: false,
            isYearDelayRateModal: false,
            isMonthDelayRateModal: false,
            isContractDetailContentModal: false,
            isStandardizedAgreementModal: false,
            isProvideSecurityModal: false,
            isKornNmModal: false,
            isKornNmSignatureModal: false,
            isEmailCheckboxModal: false,
            isAgreementReceivedModal: false,
            isAgreementExplanationReceivedModal: false,
            isIllegalCommissionReceivedExplainedModal: false,
            isFillEmailAddressModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC01AO', 'EC03AO', 'EC04AO', 'CONTRACT_EMAIL_CHECKED']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        // 대부계약체결상세내용 체크박스 활성화 Boolean
        contractDetailContentCheckbox() {
            return this.EC04AO.contractDetailContentCheckbox
        },
        // 대부거래 표준약관 체크박스 활성화 Boolean
        standardizedAgreementCheckbox() {
            return this.EC04AO.standardizedAgreementCheckbox
        },
        // 채권양도 ∙ 양도담보제공승낙서 체크박스 활성화 Boolean
        provideSecurityCheckbox() {
            return this.EC04AO.provideSecurityCheckbox
        },
        // 사인 데이터 관련
        signedData() {
            const data = this.EC04AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC04AO.name
        },
        signatureColor() {
            return this.EC04AO.nameColorValid
        },
        // 대출금액 관련
        priceColor() {
            return this.EC04AO.priceColor
        },
        price() {
            return this.EC04AO.price
        },
        // 계약일 관련
        contractDateColor() {
            return this.EC04AO.contractDateColor
        },
        contractEndDateColor() {
            return this.EC04AO.contractEndDateColor
        },
        contractDate() {
            return this.EC04AO.contractDate
        },
        contractEndDate() {
            return this.EC04AO.contractEndDate
        },
        yearAplyRate() {
            return this.EC04AO.yearAplyRate
        },
        yearAplyRateColor() {
            return this.EC04AO.yearAplyRateColor
        },
        monthAplyRate() {
            return this.EC04AO.monthAplyRate
        },
        monthAplyRateColor() {
            return this.EC04AO.monthAplyRateColor
        },
        yearDelayRate() {
            return this.EC04AO.yearDelayRate
        },
        yearDelayRateColor() {
            return this.EC04AO.yearDelayRateColor
        },
        monthDelayRate() {
            return this.EC04AO.monthDelayRate
        },
        monthDelayRateColor() {
            return this.EC04AO.monthDelayRateColor
        },
        contractSignedData() {
            return {
                signedData01: this.EC01AO.signedData,
                signedData02: this.EC03AO.signedData,
                signedData03: this.EC04AO.signedData,
            }
        },
        isDisabled() {
            return this.EC04AO.contractDetailContentCheckbox && this.isRegType && this.EC04AO.provideSecurityCheckbox &&
                this.EC04AO.signedDataValid && this.EC04AO.nameValid && this.EC04AO.priceValid && this.EC04AO.contractDateValid && this.EC04AO.contractEndDateValid &&
                this.EC04AO.yearAplyRateValid && this.EC04AO.monthAplyRateValid && this.EC04AO.yearDelayRateValid && this.EC04AO.monthDelayRateValid &&
                this.agreementReceived && this.agreementExplanationReceived && this.illegalCommissionReceivedExplained && this.isChecked && this.fillEmailAddress
        },
        isRegType() {
            return (this.contractDetail.regType === '13' || this.contractDetail.regType === '14') ? true : this.EC04AO.standardizedAgreementCheckbox
        },
    },
    watch: {
        isChecked() {
            this.EC04AO.agreementPCI = this.isChecked === true;
        },
        emailID: 'combinedEmail',
        emailDomain: 'combinedEmail',
    },
    methods: {
        combinedEmail() {
            this.emailAddress = this.emailID + this.emailDomain
            this.CONTRACT_EMAIL_CHECKED.email = this.emailAddress
            this.CONTRACT_EMAIL_CHECKED.receiveEmail = 'Y'
        },
        checkFillEmailAddress() {
            const emailRegex = /^[^@]+$/;
            this.fillEmailAddress = emailRegex.test(this.emailID) && this.emailID !== '' && this.emailDomain !== '';
        },
        confirmModal() {
            this.failedContractDateModal ? this.failedContractDateModal = !this.failedContractDateModal : this.failedContractEndDateModal = !this.failedContractEndDateModal
        },
        openSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        closeSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        openContractDetailContent() {
            this.showContractDetailContent = !this.showContractDetailContent
        },
        openStandardizedAgreement() {
            this.showStandardizedAgreement = !this.showStandardizedAgreement
        },
        openProvideSecurity() {
            this.showProvideSecurity = !this.showProvideSecurity
        },
        closeContractDetailContent() {
            window.scrollTo(0, 705)
            this.showContractDetailContent = !this.showContractDetailContent
            this.$store.commit('AoneIndividualContractStore/CONTRACT_DETAIL_CONTENT_MODAL', true)
        },
        closeStandardizedAgreement() {
            window.scrollTo(0, 805)
            this.showStandardizedAgreement = !this.showStandardizedAgreement
            this.$store.commit('AoneIndividualContractStore/STANDARDIZED_AGREEMENT_MODAL', true)
        },
        closeProvideSecurity() {
            this.showProvideSecurity = !this.showProvideSecurity
            this.$store.commit('AoneIndividualContractStore/PROVIDE_SECURITY_MODAL', true)
            this.$nextTick(() => {
                window.scrollTo(0, 1000);
            })
        },
        openContractDatePicker() {
            this.showContractDatePicker = !this.showContractDatePicker
        },
        closeContractDatePicker() {
            this.showContractDatePicker = !this.showContractDatePicker
        },
        openContractEndDatePicker() {
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        closeContractEndDatePicker() {
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        resultContractDatePicker() {
            const result = this.$refs.contractDatePicker.checkDate()
            this.closeContractDatePicker()
            if (result.validResult) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_CONTRACT_DATE_COLOR', false);
                this.$store.commit('AoneIndividualContractStore/EC04AO_SUCCESS_CONTRACT_DATE', {
                    contractDate: result.date,
                    isValid: true
                });
            } else {
                this.$store.commit('AoneIndividualContractStore/EC04AO_FAILED_CONTRACT_DATE', result.date)
                this.failedContractDateModal = !this.failedContractDateModal
            }
        },
        resultContractEndDatePicker() {
            const result = this.$refs.contractEndDatePicker.checkDate()
            this.closeContractEndDatePicker()
            if (result.validResult) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_CONTRACT_END_DATE_COLOR', false);
                this.$store.commit('AoneIndividualContractStore/EC04AO_SUCCESS_END_CONTRACT_DATE', {
                    contractEndDate: result.date,
                    isValid: true
                });
            } else {
                this.$store.commit('AoneIndividualContractStore/EC04AO_FAILED_END_CONTRACT_DATE', result.date)
                this.failedContractEndDateModal = !this.failedContractEndDateModal
            }
        },
        saveSignatureProvideSecurity() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC04AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureReaded = !this.showSignatureReaded
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC04AO_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        isValidPrice(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num)) {
                num = null;
                return;
            }
            e.target.value = num.toLocaleString();
            if (String(this.contractDetail.loanAmt) === String(num)) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_PRICE', {name: e.target.value, isValid: true});
                this.$store.commit('AoneIndividualContractStore/EC04AO_PRICE_COLOR', false);
            }
        },
        isCheckedPrice(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num) || String(num) !== String(this.contractDetail.loanAmt)) {
                this.isLoanAmt = true
            }
        },
        confirmLoanAmtModal() {
            this.isLoanAmt = false
        },
        isValidYearAply(e) {
            if (String(this.contractDetail.aplyRate) === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_YEAR_APLY', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC04AO_YEAR_APLY_COLOR', false)
            }
        },
        isCheckedYearAply(e) {
            if (String(this.contractDetail.aplyRate) !== e.target.value) {
                this.isYearAplyRate = true
            }
        },
        confirmYearAplyRateModal() {
            this.isYearAplyRate = false
        },
        isValidMonthAply(e) {
            if (String(this.divideByTwelve(this.contractDetail.aplyRate)) === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_MONTH_APLY', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC04AO_MONTH_APLY_COLOR', false)
            }
        },
        isCheckedMonthAply(e) {
            if (String(this.divideByTwelve(this.contractDetail.aplyRate)) !== e.target.value) {
                this.isMonthAplyRate = true
            }
        },
        confirmMonthAplyRateModal() {
            this.isMonthAplyRate = false
        },
        isValidYearDelay(e) {
            if (String(this.contractDetail.delayRate) === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_YEAR_DELAY', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC04AO_YEAR_DELAY_COLOR', false)
            }
        },
        isCheckedYearDelay(e) {
            if (String(this.contractDetail.delayRate) !== e.target.value) {
                this.isYearDelayRate = true
            }
        },
        confirmYearDelayRateModal() {
            this.isYearDelayRate = false
        },
        isValidMonthDelay(e) {
            if (String(this.divideByTwelve(this.contractDetail.delayRate)) === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC04AO_MONTH_DELAY', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC04AO_MONTH_DELAY_COLOR', false)
            }
        },
        isCheckedMonthDelay(e) {
            if (String(this.divideByTwelve(this.contractDetail.delayRate)) !== e.target.value) {
                this.isMonthDelayRate = true
            }
        },
        confirmMonthDelayRateModal() {
            this.isMonthDelayRate = false
        },
        validAgreementReceived(value) {
            this.agreementReceived = value
        },
        validAgreementExplain(value) {
            this.agreementExplanationReceived = value
        },
        validIllegalCommission(value) {
            this.illegalCommissionReceivedExplained = value
        },
        checkLoanAmtModal() {
            this.isLoanAmtModal = false
            this.$refs.loanAmt.focus()
        },
        checkCntrctDtModal() {
            this.isCntrctDtModal = false
            this.$refs.cntrctDt.focus()
            this.showContractDatePicker = !this.showContractDatePicker
        },
        checkMatDtModal() {
            this.isMatDtModal = false
            this.$refs.matDt.focus()
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        checkYearAplyRateModal() {
            this.isYearAplyRateModal = false
            this.$refs.yearAplyRate.focus()
        },
        checkMonthAplyRateModal() {
            this.isMonthAplyRateModal = false
            this.$refs.monthAplyRate.focus()
        },
        checkYearDelayRateModal() {
            this.isYearDelayRateModal = false
            this.$refs.yearDelayRate.focus()
        },
        checkMonthDelayRateModal() {
            this.isMonthDelayRateModal = false
            this.$refs.monthDelayRate.focus()
        },
        checkContractDetailContentModal() {
            this.isContractDetailContentModal = !this.isContractDetailContentModal
            this.$refs.contractDetailContent.focus()
        },
        checkStandardizedAgreementModal() {
            this.isStandardizedAgreementModal = !this.isStandardizedAgreementModal
            this.$refs.standardizedAgreement.focus()
        },
        checkProvideSecurityModal() {
            this.isProvideSecurityModal = !this.isProvideSecurityModal
            this.$refs.provideSecurity.focus()
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.$refs.kornNmSignature.focus()
            this.showSignatureReaded = !this.showSignatureReaded
        },
        checkAgreementReceivedModal() {
            this.isAgreementReceivedModal = false
            this.$refs.agreementReceived.focus()
        },
        checkAgreementExplanationReceivedModal() {
            this.isAgreementExplanationReceivedModal = false
            this.$refs.agreementExplanationReceived.focus()
        },
        checkIllegalCommissionReceivedExplainedModal() {
            this.isIllegalCommissionReceivedExplainedModal = false
            this.$refs.illegalCommissionReceivedExplained.focus()
        },
        checkEmailCheckboxModal() {
            this.isEmailCheckboxModal = false
        },
        checkFillEmailAddressModal() {
            this.isFillEmailAddressModal = false
        },
        async completeContract() {
            if (this.priceColor) {
                this.isLoanAmtModal = true
            } else if (this.contractDateColor) {
                this.isCntrctDtModal = true
            } else if (this.contractEndDateColor) {
                this.isMatDtModal = true
            } else if (this.yearAplyRateColor) {
                this.isYearAplyRateModal = true
            } else if (this.monthAplyRateColor) {
                this.isMonthAplyRateModal = true
            } else if (this.yearDelayRateColor) {
                this.isYearDelayRateModal = true
            } else if (this.monthDelayRateColor) {
                this.isMonthDelayRateModal = true
            } else if (!this.contractDetailContentCheckbox) {
                this.isContractDetailContentModal = true
            } else if (!this.standardizedAgreementCheckbox) {
                this.isStandardizedAgreementModal = true
            } else if (!this.provideSecurityCheckbox) {
                this.isProvideSecurityModal = true
            } else if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC04AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else if (!this.agreementReceived) {
                this.isAgreementReceivedModal = true
            } else if (!this.agreementExplanationReceived) {
                this.isAgreementExplanationReceivedModal = true
            } else if (!this.illegalCommissionReceivedExplained) {
                this.isIllegalCommissionReceivedExplainedModal = true
            } else if (!this.isChecked) {
                this.isEmailCheckboxModal = true
            } else if (!this.fillEmailAddress) {
                this.isFillEmailAddressModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
        nextPage() {
            if (this.priceColor) {
                this.isLoanAmtModal = true
            } else if (this.contractDateColor) {
                this.isCntrctDtModal = true
            } else if (this.contractEndDateColor) {
                this.isMatDtModal = true
            } else if (this.yearAplyRateColor) {
                this.isYearAplyRateModal = true
            } else if (this.monthAplyRateColor) {
                this.isMonthAplyRateModal = true
            } else if (this.yearDelayRateColor) {
                this.isYearDelayRateModal = true
            } else if (this.monthDelayRateColor) {
                this.isMonthDelayRateModal = true
            } else if (!this.contractDetailContentCheckbox) {
                this.isContractDetailContentModal = true
            } else if (!this.standardizedAgreementCheckbox) {
                this.isStandardizedAgreementModal = true
            } else if (!this.provideSecurityCheckbox) {
                this.isProvideSecurityModal = true
            } else if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC04AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else if (!this.agreementReceived) {
                this.isAgreementReceivedModal = true
            } else if (!this.agreementExplanationReceived) {
                this.isAgreementExplanationReceivedModal = true
            } else if (!this.illegalCommissionReceivedExplained) {
                this.isIllegalCommissionReceivedExplainedModal = true
            } else if (!this.isChecked) {
                this.isEmailCheckboxModal = true
            } else if (!this.fillEmailAddress) {
                this.isFillEmailAddressModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>