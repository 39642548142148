import CompleteContract from "@/views/common/aone/CompleteContract.vue";
import InicisSuccessComponent from "@/views/common/aone/InicisSuccessComponent.vue";

export default [
    {
        path: '/completeContract',
        component: CompleteContract,
        name: 'CompleteContract',
    },
    {
        path: '/inicisSuccessComponent',
        component: InicisSuccessComponent,
        name: 'InicisSuccessComponent',
    },
]