<template>
   <div class="checkbox_wrap">
       <slot name="agreementCaseCheckbox">
       </slot>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>