<template>
  <div class="container" data-contract-company="tflain" data-html-name="채권양도 및 양도담보제공승낙서">
    <!-- contract : 계약서 / manual : 설명서 -->
    <div class="manual">

      <header-black>
        <template #backBtn>
          <button class="backBtn_icon"></button>
        </template>
        <div slot="title">채권양도∙양도담보제공승낙서</div>
      </header-black>

      <main class="bottom_btn_case">

        <section class="default">
          <article class="alone_article">
            <div class="article_subheading emp_bold">㈜티플레인대부 귀중</div>
            본인은 귀사와 체결된 대부거래 계약에 의해 귀사가 본인에 대하여 갖는 현재 및 장래의 대부 채권, 기타 대부거래 계약 상 본인에 대하여 갖는 일체의 권리(계약해제권을 포함한 계약자로서 갖는 모든 권리)(이하‘본 건 대부 채권, )를 귀사가 제3자에게(이하 ‘양수인, )에게 양도 또는 담보로 제공하는 것에 대해 아무런 이의 없이 승낙합니다. 단, 본건 대부 채권의 이자 및 원금은 양수인의 별도 청구가 있기 전까지는 귀사에 계속하여 납부하겠으며, 양수인의 별도 서면청구가 있는 경우에는 양수인이 서명한 청구서에 기재되어 있는 방법으로 본건 대부 채권의 이자 및 원금을 양수인 또는 양 수인이 지정하는 자에게 직접 납부하도록 하겠습니다. 또한 본인은 위 채권의 양도가 이루어질 경우 본인의 신용 정보가 본인에 대한 신용 판단 또는 채권추심 등을 위한 자료로 활용될 경우에 한하 여 당해 양수인에게 제공되는 것을 동의합니다. 다만, 그 외의 용도로 본인의 신용 정보가 활용되거나 당해 양수인으로부터 별도의 서면청구가 있기 전에 채권추심의 목적이 달성된 경우에는 본인의 신용 정보의 활용 중지, 파기 또는 삭제를 요청할 수 있고 파기 또는 삭제 시 해당 정보가 복구 또는 재생되지 않도록 요청할 수 있습니다.
          </article>    
        </section>

        <!-- main에 bottom_btn_case 추가필요 -->
        <section class="bottomBtn_wrap">
          <BottomBtn>
            <button 
              slot="bottom_btn" 
              class="primary_btn"
              :class="{ active: activeBtn }"
              :disabled="!activeBtn"
              @click="closeProvideSecurity()"
            >
              확인
            </button>
          </BottomBtn>
        </section>

      </main>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    closeProvideSecurity() {
        this.$emit('close')
    },
  },
}
</script>

<style>
  
</style>