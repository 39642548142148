<template>
    <div>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>
<script>
import {mapState} from "vuex";
import {incrementContractSignatureCount} from '@/api/count'
import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import {createCompletedContractPDF,} from "@/api/contract";
import pako from "pako";

export default {
    data() {
        return {
            inicisData: null,
            isLoading: false,
            inicisResult: {
                resultCode: '',
                resultMsg: '',
                providerDevCd: '',
                signedData: '',
                svcCd: '',
                txId: '',
                userCi: '',
                esDate: new Date(),
            },
            mgNo: '',
            url: '',
            filePath: '',
            isLoanAgreement: false,
        }
    },
    components: {
        LoadingSpinner,
    },
    computed: {
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
            inicisStore: state => state.inicisStore,
        }),
    },
    async mounted() {

        let self = this;
        self.isLoading = true
        this.mgNo = this.commonContractStore.contractDetail.mgNo

        const compressData = this.$route.query.compressData

        try {
            const decompressedData = await this.decompressData(compressData);
            this.inicisData = JSON.parse(decompressedData || '{}');
        } catch (error) {
            console.error("Error during data processing:", error);
        }

        await this.handleInicisAuthResult(this.inicisData)

        await incrementContractSignatureCount(this.inicisResult)
            .then(async response => {
                if (response.data.result === true) {
                    await createCompletedContractPDF(
                        this.commonContractStore.contractDetail.mgNo,
                        this.individualContractStore.CONTRACT_EMAIL_CHECKED.signedData,
                        this.individualContractStore.CONTRACT_EMAIL_CHECKED.receiveEmail,
                        this.individualContractStore.CONTRACT_EMAIL_CHECKED.email,
                    )
                        .then(async response => {
                            if (response.data.result === true) {
                                this.url = response.data.contents.url
                                this.filepath = window.btoa(encodeURIComponent(response.data.contents.filePath))
                                this.isLoanAgreement = response.data.contents.loanAgreement
                                await this.$router.replace({
                                    name: 'TflainCompleteContract',
                                    query: {
                                        url: this.url,
                                        filepath: this.filepath,
                                        isLoanAgreement: this.isLoanAgreement,
                                        mgNo: this.mgNo,
                                    }
                                })
                            } else {
                                await this.$router.replace({name: 'ServerErrorComponent'})
                            }
                        })
                } else {
                    alert('전자서명이 실패했습니다 \n고객센터에 문의해주시기 바랍니다')
                }
            })
            .catch(error => console.log(error))
        self.isLoading = false
        localStorage.removeItem('vuex');
        localStorage.removeItem('_secure__ls__metadata')
    },
    methods: {
        async handleInicisAuthResult(data) {
            this.inicisStore.inicisResult.esDate = this.objectToDate(data.esDate)
            this.inicisStore.inicisResult.providerDevCd = data.providerDevCd
            this.inicisStore.inicisResult.resultCode = data.resultCode
            this.inicisStore.inicisResult.resultMsg = data.resultMsg
            this.inicisStore.inicisResult.signedData = data.signedData
            this.inicisStore.inicisResult.svcCd = data.svcCd
            this.inicisStore.inicisResult.txId = data.txId
            this.inicisStore.inicisResult.userCi = data.userCi
            this.inicisStore.inicisResult.loanNo = this.commonContractStore.contractDetail.loanNo
            this.inicisStore.inicisResult.docuNo = this.commonContractStore.contractDetail.docuNo
            this.inicisStore.inicisResult.mgNo = this.commonContractStore.contractDetail.mgNo
            this.inicisStore.inicisResult.bizCode = this.commonContractStore.contractDetail.bizCode
            this.inicisResult = this.inicisStore.inicisResult
        },
        objectToDate(esDate) {
            if (typeof esDate === "undefined" || esDate === null) {
                esDate = new Date()
                return esDate.toISOString()
            }
            const utcDate = new Date(Date.UTC(esDate.date.year, esDate.date.month - 1, esDate.date.day, esDate.time.hour, esDate.time.minute, esDate.time.second));
            return utcDate.toISOString()
        },
        decompressData(compressed) {
            const decodedData = atob(compressed);
            const byteArray = new Uint8Array(decodedData.length);

            for (let i = 0; i < decodedData.length; i++) {
                byteArray[i] = decodedData.charCodeAt(i);
            }

            try {
                return pako.inflate(byteArray, {to: 'string'});
            } catch (error) {
                console.error("Error during decompression:", error);
                throw error;
            }
        }
    }
}
</script>