<template>
    <form ref="saForm" name="saForm" id="saForm">
        <input type="hidden" ref="mid" name="mid" id="mid" value="">
        <input type="hidden" ref="reqSvcCd" name="reqSvcCd" id="reqSvcCd" value="">
        <input type="hidden" ref="identifier" name="identifier" id="identifier" value="">
        <input type="hidden" ref="mTxId" name="mTxId" id="mTxId" value="">
        <input type="hidden" ref="successUrl" name="successUrl" id="successUrl" value="">
        <input type="hidden" ref="failUrl" name="failUrl" id="failUrl" value="">
        <input type="hidden" ref="authHash" name="authHash" id="authHash" value="">
        <input type="hidden" ref="directAgency" name="directAgency" id="directAgency" value="" multiple>
        <input type="hidden" ref="flgFixedUser" name="flgFixedUser" id="flgFixedUser" value="">
        <input type="hidden" ref="userName" name="userName" id="userName" value="">
        <input type="hidden" ref="userPhone" name="userPhone" id="userPhone" value="">
        <input type="hidden" ref="userBirth" name="userBirth" id="userBirth" value="">
        <input type="hidden" ref="userHash" name="userHash" id="userHash" value="">
    </form>
</template>
<script>
import {mapState} from "vuex";

export default {
    data() {
        return {
            inicisDetail: null,
            windowRef: null,
            inicisResult: {
                loanNo: '',
                docuNo: '',
                mgNo: '',
                bizCode: '',
                resultCode: '',
                resultMsg: '',
                providerDevCd: '',
                signedData: '',
                svcCd: '',
                txId: '',
                userCi: '',
                esDate: new Date(),
            },
        }
    },
    computed: {
        ...mapState({
            inicisStore: state => state.inicisStore,
            commonContractStore: state => state.AoneCommonContractStore,
        }),
    },
    methods: {
        async openPopup() {
            this.inicisDetail = this.inicisStore.inicisDetail;
            await this.setFormData(); // 데이터 바인딩

            const urlParams = new URLSearchParams(window.location.search);
            const status = urlParams.get('status');

            if (status === 'success') {
                console.log('Operation successful');
            }

            this.$refs.saForm.setAttribute("target", "_self");
            this.$refs.saForm.setAttribute("method", "post");
            this.$refs.saForm.setAttribute("action", "https://sa.inicis.com/auth");
            this.$refs.saForm.submit();
        },
        async handleInicisAuthResult(data) {
            this.inicisStore.inicisResult.esDate = this.objectToDate(data.esDate)
            this.inicisStore.inicisResult.providerDevCd = data.providerDevCd
            this.inicisStore.inicisResult.resultCode = data.resultCode
            this.inicisStore.inicisResult.resultMsg = data.resultMsg
            this.inicisStore.inicisResult.signedData = data.signedData
            this.inicisStore.inicisResult.svcCd = data.svcCd
            this.inicisStore.inicisResult.txId = data.txId
            this.inicisStore.inicisResult.userCi = data.userCi
            this.inicisStore.inicisResult.loanNo = this.commonContractStore.contractDetail.loanNo
            this.inicisStore.inicisResult.docuNo = this.commonContractStore.contractDetail.docuNo
            this.inicisStore.inicisResult.mgNo = this.commonContractStore.contractDetail.mgNo
            this.inicisStore.inicisResult.bizCode = this.commonContractStore.contractDetail.bizCode
            this.inicisResult = this.inicisStore.inicisResult
        },
        objectToDate(esDate) {
            if (typeof esDate === "undefined" || esDate === null) {
                esDate = new Date()
                return esDate.toISOString()
            }
            const utcDate = new Date(Date.UTC(esDate.date.year, esDate.date.month - 1, esDate.date.day, esDate.time.hour, esDate.time.minute, esDate.time.second));
            return utcDate.toISOString()
        },
        async setFormData() {
            this.$refs.mid.value = this.inicisDetail.inicisMid
            this.$refs.reqSvcCd.value = this.inicisDetail.reqSvcCd
            this.$refs.identifier.value = this.inicisDetail.identifier
            this.$refs.mTxId.value = this.inicisDetail.mtxId
            this.$refs.successUrl.value = this.inicisDetail.successUrl
            this.$refs.failUrl.value = this.inicisDetail.failedUrl
            this.$refs.authHash.value = this.inicisDetail.authHash
            this.$refs.directAgency.value = this.inicisDetail.directAgency
            this.$refs.flgFixedUser.value = this.inicisDetail.figFixedUser
            this.$refs.userName.value = this.inicisDetail.userName
            this.$refs.userPhone.value = this.inicisDetail.userPhone
            this.$refs.userBirth.value = this.inicisDetail.userBirth
            this.$refs.userHash.value = this.inicisDetail.userHash
        },
    }
}
</script>