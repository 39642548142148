<template>
    <div class="container" data-contract-company="tflain" data-html-name="대부거래 계약서_계약서작성">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract" v-if="!showContractDetailContent && !showStandardizedAgreement && !showProvideSecurity">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">대부거래 계약서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">

                    <article>
                        <div class="article_subheading emp_bold">주식회사 티플레인대부 귀중</div>
                        <div class="comment gray none">본인은 주식회사 티플레인대부(이하 “회사”라 함)와 아래의 조건에 따라 대출거래를 함에 있어 “대부거래표준약관”이 적용됨을 승인하며, 아래 각 조항을 성실히 이행할 것을 확약합니다.</div>
                    </article>

                    <article>
                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대부금액</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="priceColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.loanAmt | comma }}원</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    :placeholder="contractDetail.loanAmt | comma"
                                    :class="{ blue: priceColor }"
                                    :disabled="!priceColor"
                                    :value="price"
                                    @input="isValidPrice"
                                    @blur="isCheckedPrice"
                                >
                                <div class="after">원</div>
                            </div>
                        </div>

                        <div class="comment gray mt8 none">
                            * 신규계약 : 채무자가 실제 수령한 금액<br/>
                            * 연장계약 : 잔존 채무잔액 ‘ 추가대출계약 : 기 대출금액 + 채무자가 추가로 실제 수령한 금액
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>월 양적액</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="feeAmtColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.feeAmt | comma }}원</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    :placeholder="contractDetail.feeAmt | comma"
                                    :class="{ blue: feeAmtColor }"
                                    :disabled="!feeAmtColor"
                                    :value="feeAmt"
                                    @input="isValidFeeAmt"
                                    @blur="isCheckedFeeAmt"
                                >
                                <div class="after">원</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>계약일자(대부일자)</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="contractDateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.cntrctDt | formatContractDate }}</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    class="non_cursor"
                                    @click="openContractDatePicker()"
                                    :placeholder="contractDetail.cntrctDt | formatContractDate"
                                    :class="{ blue: contractDateColor }"
                                    :disabled="!contractDateColor"
                                    :value="contractDate"
                                >
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대부기간만료일</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="contractEndDateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.matDt | formatContractDate }}</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    class="non_cursor"
                                    @click="openContractEndDatePicker()"
                                    :placeholder="contractDetail.matDt | formatContractDate"
                                    :class="{ blue: contractEndDateColor }"
                                    :disabled="!contractEndDateColor"
                                    :value="contractEndDate"
                                >
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대부이자율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="yearAplyRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>연 {{ contractDetail.aplyRate }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">연</div>
                                <input
                                    type="text"
                                    :placeholder="contractDetail.aplyRate"
                                    :class="{ blue: yearAplyRateColor }"
                                    :disabled="!yearAplyRateColor"
                                    :value="yearAplyRate"
                                    @input="isValidYearAply"
                                    @blur="isCheckedYearAply"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대부이자율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="monthAplyRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>월 {{ contractDetail.aplyRate | divideByTwelveRounding }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">월</div>
                                <input
                                    type="text"
                                    :placeholder="contractDetail.aplyRate | divideByTwelveRounding"
                                    :class="{ blue: monthAplyRateColor }"
                                    :disabled="!monthAplyRateColor"
                                    :value="monthAplyRate"
                                    @input="isValidMonthAply"
                                    @blur="isCheckedMonthAply"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>연체이자율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="yearDelayRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>연 {{ contractDetail.delayRate }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">연</div>
                                <input
                                    type="text"
                                    :placeholder="contractDetail.delayRate"
                                    :class="{ blue: yearDelayRateColor }"
                                    :disabled="!yearDelayRateColor"
                                    :value="yearDelayRate"
                                    @input="isValidYearDelay"
                                    @blur="isCheckedYearDelay"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>연체이자율</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="monthDelayRateColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>월 {{ contractDetail.delayRate | divideByTwelveRounding }}%</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="before">월</div>
                                <input
                                    type="text"
                                    :placeholder="contractDetail.delayRate | divideByTwelveRounding"
                                    :class="{ blue: monthDelayRateColor }"
                                    :disabled="!monthDelayRateColor"
                                    :value="monthDelayRate"
                                    @input="isValidMonthDelay"
                                    @blur="isCheckedMonthDelay"
                                >
                                <div class="after">%</div>
                            </div>
                        </div>

                        <div class="common_box info_input">
                            <div class="top case_double_side">
                                <div>대부기간</div>
                                <div class="right_of_top">
                                    <div class="checking_msg" v-if="loanTrmColor">일치하지 않음!</div>
                                    <div class="checking_msg" v-else>일치함!</div>
                                    <div>{{ contractDetail.loanTrm }}개월</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <input
                                    type="text"
                                    :placeholder="contractDetail.loanTrm"
                                    :class="{ blue: loanTrmColor }"
                                    :disabled="!loanTrmColor"
                                    :value="loanTrm"
                                    @input="isValidLoanTrm"
                                    @blur="isCheckedLoanTrm"
                                >
                                <div class="after">개월</div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div class="comment dark_blue">대출 계약 체결 상세 내용을 꼭 확인하세요.</div>
                        <CheckboxDefault @show="openContractDetailContent()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <!-- class active 추가시 활성화 -->
                                    <div class="checkbox24_icon"
                                         :class="{ active: contractDetailContentCheckbox }"></div>
                                    <div>대부 계약 체결 상세 내용</div>
                                </div>
                                <button class="seedetail_icon"></button>
                            </template>
                        </CheckboxDefault>
                    </article>

                    <article v-if="contractDetail.regType !== '13' && contractDetail.regType !== '14'">
                        <div class="comment dark_blue">대부거래 표준약관을 꼭 확인하세요.</div>
                        <CheckboxBottomContentCase @show="openStandardizedAgreement()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <input type="checkbox" id="" class="checkbox_24">
                                    <label for="">
                                        <div class="checkbox24_icon"
                                             :class="{ active: standardizedAgreementCheckbox }"></div>
                                        <div>대부거래 표준약관</div>
                                    </label>
                                </div>
                            </template>
                            <button slot="arrow_part" class="seedetail_icon"></button>
                            <template #checkboxContent>
                                <div class="text_case">
                                    본인은 위 대부거래계약의 내용 및 대부거래약관이 적용됨을 승인하고 성실히 이행할 것을 확약합니다.
                                </div>
                            </template>
                        </CheckboxBottomContentCase>
                    </article>

                    <article>
                        <div class="comment dark_blue">채권양도 및 양도담보제공 승낙서를 꼭 확인하세요.</div>

                        <CheckboxBottomContentCase @show="openProvideSecurity()">
                            <template #checkboxMainTitle>
                                <div class="left">
                                    <input type="checkbox" id="" class="checkbox_24">
                                    <label for="">
                                        <div class="checkbox24_icon" :class="{ active: provideSecurityCheckbox }"></div>
                                        <div>채권양도 ∙ 양도담보제공승낙서</div>
                                    </label>
                                </div>
                            </template>

                            <button slot="arrow_part" class="seedetail_icon"></button>

                            <template #checkboxContent>
                                <div class="sign_case">
                                    <SignatureInput>
                                        <template #signature>
                                            <!-- blue : 미일치 / black : 일치 -->
                                            <input
                                                type="text"
                                                id="sign"
                                                class="sign"
                                                v-model="contractDetail.kornNm"
                                                :class="{ blue: signatureColor }"
                                                disabled
                                            >
                                            <label for="sign" @click="openSignReaded()">
                                                <button v-html="signedData"></button>
                                            </label>
                                        </template>
                                    </SignatureInput>
                                </div>
                            </template>
                        </CheckboxBottomContentCase>
                    </article>

                    <article>
                        <div class="common_box check_case">
                            <div class="title">
                                채무자는 다음 사항을 읽고 본인의 의사를 사실에 근거하여 선택해 주십시오.
                            </div>

                            <div>
                                <div class="content">1. 위 계약서 및 대부거래표준약관을 확실히 수령하였습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <!-- checked 시에 활성화 -->
                                        <input type="radio" id="radio_1" name="radioSection_1"
                                               @click="validAgreementReceived(true)"
                                               :checked="agreementReceived === true"
                                        >
                                        <label for="radio_1">
                                            <span>수령함</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_2" name="radioSection_1"
                                               @click="validAgreementReceived(false)"
                                               :checked="agreementReceived === false"
                                        >
                                        <label for="radio_2">
                                            <span>수령하지 않음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="content align">2. 위 계약서 및 대부거래표준약관의 중요한 내용에 대하여 설명을 들었습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_3" name="radioSection_2"
                                               @click="validAgreementExplain(true)"
                                               :checked="agreementExplanationReceived === true"
                                        >
                                        <label for="radio_3">
                                            <span>들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_4" name="radioSection_2"
                                               @click="validAgreementExplain(false)"
                                               :checked="agreementExplanationReceived === false"
                                        >
                                        <label for="radio_4">
                                            <span>못 들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="content align">3. 대부중개업자가 중개 수수료를 채무자로부터 받는 것은 불법이라는 설명을 들었습니까?</div>
                                <div class="radio_group">
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_5" name="radioSection_3"
                                               @click="validIllegalCommission(true)"
                                               :checked="illegalCommissionReceivedExplained === true"
                                        >
                                        <label for="radio_5">
                                            <span>들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                    <div class="radio_wrap">
                                        <input type="radio" id="radio_6" name="radioSection_3"
                                               @click="validIllegalCommission(false)"
                                               :checked="illegalCommissionReceivedExplained === false"
                                        >
                                        <label for="radio_6">
                                            <span>못 들었음</span>
                                            <div class="icon"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <div class="text">
                        대출 직후 불법적인 경로를 통해 접근하는 보이스피싱에 대한 대출사기에 유의하시기 바랍니다.
                    </div>
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>
            </main>
        </div>

        <CommonModal v-if="isLoanAmt" @confirmBtn="confirmLoanAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">대출금액</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isFeeAmt" @confirmBtn="confirmFeeAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">월 양적액</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="failedContractDateModal" @confirmBtn="confirmModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부일자</span>가<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="failedContractEndDateModal" @confirmBtn="confirmModal()">
            <div slot="ModalContent">
                <span class="emp_red">만기일자</span>가<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearAplyRate" @confirmBtn="confirmYearAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthAplyRate" @confirmBtn="confirmMonthAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">정상이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearDelayRate" @confirmBtn="confirmYearDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthDelayRate" @confirmBtn="confirmMonthDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이율</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isLoanTrm" @confirmBtn="confirmLoanTrmModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부기간</span>이<br>
                일치하지 않습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isLoanAmtModal" @confirmBtn="checkLoanAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부금액</span>을<br>
                입력해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isFeeAmtModal" @confirmBtn="checkFeeAmtModal()">
            <div slot="ModalContent">
                <span class="emp_red">월 양적액</span>을<br>
                입력해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isCntrctDtModal" @confirmBtn="checkCntrctDtModal()">
            <div slot="ModalContent">
                <span class="emp_red">계약일자(대부일자)</span>를<br>
                선택해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMatDtModal" @confirmBtn="checkMatDtModal()">
            <div slot="ModalContent">
                <span class="emp_red">대출기간만료일</span>을<br>
                선택해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearAplyRateModal" @confirmBtn="checkYearAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부이자 연이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthAplyRateModal" @confirmBtn="checkMonthAplyRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부이자 월이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isYearDelayRateModal" @confirmBtn="checkYearDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이자 연이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isMonthDelayRateModal" @confirmBtn="checkMonthDelayRateModal()">
            <div slot="ModalContent">
                <span class="emp_red">연체이자 월이율</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isLoanTrmModal" @confirmBtn="checkLoanTrmModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부기간</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isContractDetailContentModal" @confirmBtn="checkContractDetailContentModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부 계약 체결 상세 내용</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isStandardizedAgreementModal" @confirmBtn="checkStandardizedAgreementModal()">
            <div slot="ModalContent">
                <span class="emp_red">대부거래 표준약관</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isProvideSecurityModal" @confirmBtn="checkProvideSecurityModal()">
            <div slot="ModalContent">
                <span class="emp_red">채권양도∙양도담보제공승낙서</span>을<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isAgreementReceivedModal" @confirmBtn="checkAgreementReceivedModal()">
            <div slot="ModalContent">
                <span class="emp_red">수령함</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isAgreementExplanationReceivedModal" @confirmBtn="checkAgreementExplanationReceivedModal()">
            <div slot="ModalContent">
                <span class="emp_red">들었음</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isIllegalCommissionReceivedExplainedModal"
                     @confirmBtn="checkIllegalCommissionReceivedExplainedModal()">
            <div slot="ModalContent">
                <span class="emp_red">들었음</span>으로<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <ContractDetailContent v-if="showContractDetailContent" @close="closeContractDetailContent"/>
        <StandardizedAgreement v-if="showStandardizedAgreement" @close="closeStandardizedAgreement"/>
        <ProvideSecurity v-if="showProvideSecurity" @close="closeProvideSecurity"/>

        <ContractDatePicker v-if="showContractDatePicker" :propsDate="contractDetail.cntrctDt" ref="contractDatePicker"
                            @closeDatePicker="closeContractDatePicker" @resultDatePicker="resultContractDatePicker"/>
        <ContractEndDatePicker v-if="showContractEndDatePicker" :propsDate="contractDetail.matDt"
                               ref="contractEndDatePicker" @closeDatePicker="closeContractEndDatePicker"
                               @resultDatePicker="resultContractEndDatePicker"/>
        <SignatureReaded v-if="showSignatureReaded" ref="signaturePad" @close="closeSignReaded()"
                         @save="saveSignatureProvideSecurity" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex'

import ContractDetailContent from '@/views/common/tflain/ContractDetailContent.vue'
import StandardizedAgreement from '@/views/common/tflain/StandardizedAgreement.vue'
import ProvideSecurity from '@/views/common/tflain/ProvideSecurity.vue'

import ContractDatePicker from '@/components/common/DatePicker.vue'
import ContractEndDatePicker from '@/components/common/DatePicker.vue'
import SignatureReaded from '@/components/common/SignaturePad.vue'

export default {
    components: {
        ContractDatePicker,
        ContractEndDatePicker,
        ContractDetailContent,
        StandardizedAgreement,
        ProvideSecurity,
        SignatureReaded,
    },
    data() {
        return {
            showContractDetailContent: false,
            showStandardizedAgreement: false,
            showProvideSecurity: false,
            failedContractDateModal: false,
            failedContractEndDateModal: false,
            isLoanAmt: false,
            isFeeAmt: false,
            isYearAplyRate: false,
            isMonthAplyRate: false,
            isYearDelayRate: false,
            isMonthDelayRate: false,
            isKornNm: false,
            isLoanTrm: false,
            showBirthdayDatePicker: false,
            showContractDatePicker: false,
            showContractEndDatePicker: false,
            showSignatureReaded: false,
            isLoading: false,
            contractDetail: null,
            agreementReceived: true,
            agreementExplanationReceived: true,
            illegalCommissionReceivedExplained: true,
            isChecked: false,

            // check
            isLoanAmtModal: false,
            isFeeAmtModal: false,
            isCntrctDtModal: false,
            isMatDtModal: false,
            isYearAplyRateModal: false,
            isMonthAplyRateModal: false,
            isYearDelayRateModal: false,
            isMonthDelayRateModal: false,
            isLoanTrmModal: false,
            isContractDetailContentModal: false,
            isStandardizedAgreementModal: false,
            isProvideSecurityModal: false,
            isKornNmSignatureModal: false,
            isAgreementReceivedModal: false,
            isAgreementExplanationReceivedModal: false,
            isIllegalCommissionReceivedExplainedModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC01TF', 'EC02TF', 'EC03TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // 대부계약체결상세내용 체크박스 활성화 Boolean
        contractDetailContentCheckbox() {
            return this.EC03TF.contractDetailContentCheckbox
        },
        // 대부거래 표준약관 체크박스 활성화 Boolean
        standardizedAgreementCheckbox() {
            return this.EC03TF.standardizedAgreementCheckbox
        },
        // 채권양도 ∙ 양도담보제공승낙서 체크박스 활성화 Boolean
        provideSecurityCheckbox() {
            return this.EC03TF.provideSecurityCheckbox
        },
        // 사인 데이터 관련
        signedData() {
            const data = this.EC03TF.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC03TF.name
        },
        signatureColor() {
            return this.EC03TF.nameColorValid
        },
        // 대출금액 관련
        priceColor() {
            return this.EC03TF.priceColor
        },
        price() {
            return this.EC03TF.price
        },
        // 월 양적액
        feeAmtColor() {
            return this.EC03TF.feeAmtColor
        },
        feeAmt() {
            return this.EC03TF.feeAmt
        },
        // 계약일 관련
        contractDateColor() {
            return this.EC03TF.contractDateColor
        },
        contractEndDateColor() {
            return this.EC03TF.contractEndDateColor
        },
        contractDate() {
            return this.EC03TF.contractDate
        },
        contractEndDate() {
            return this.EC03TF.contractEndDate
        },
        yearAplyRate() {
            return this.EC03TF.yearAplyRate
        },
        yearAplyRateColor() {
            return this.EC03TF.yearAplyRateColor
        },
        monthAplyRate() {
            return this.EC03TF.monthAplyRate
        },
        monthAplyRateColor() {
            return this.EC03TF.monthAplyRateColor
        },
        yearDelayRate() {
            return this.EC03TF.yearDelayRate
        },
        yearDelayRateColor() {
            return this.EC03TF.yearDelayRateColor
        },
        monthDelayRate() {
            return this.EC03TF.monthDelayRate
        },
        monthDelayRateColor() {
            return this.EC03TF.monthDelayRateColor
        },
        loanTrm() {
            return this.EC03TF.loanTrm
        },
        loanTrmColor() {
            return this.EC03TF.loanTrmColor
        },
        contractSignedData() {
            return {
                signedData01: this.EC01TF.signedData,
                signedData02: this.EC02TF.signedData,
                signedData03: this.EC03TF.signedData,
            }
        },
        isDisabled() {
            const setPath = this.EC03TF
            return setPath.contractDetailContentCheckbox && this.isRegType && setPath.provideSecurityCheckbox &&
                setPath.signedDataValid && setPath.priceValid && setPath.feeAmtValid && setPath.contractDateValid && setPath.contractEndDateValid &&
                setPath.yearAplyRateValid && setPath.monthAplyRateValid && setPath.yearDelayRateValid && setPath.monthDelayRateValid &&
                this.agreementReceived && this.agreementExplanationReceived && this.illegalCommissionReceivedExplained && setPath.loanTrmValid
        },
        isRegType() {
            return (this.contractDetail.regType === '13' || this.contractDetail.regType === '14') ? true : this.EC03TF.standardizedAgreementCheckbox
        },
        emailAddress() {
            return this.EC03TF.emailAddress
        },
        receiveEmail() {
            return this.isChecked === true ? 'Y' : 'N'
        },
    },
    watch: {
        isChecked() {
            if (this.isChecked === false) {
                this.EC03TF.emailAddress = ''
                this.EC03TF.emailValid = false
            }
        },
    },
    methods: {
        confirmModal() {
            this.failedContractDateModal ? this.failedContractDateModal = !this.failedContractDateModal : this.failedContractEndDateModal = !this.failedContractEndDateModal
        },
        openSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        closeSignReaded() {
            this.showSignatureReaded = !this.showSignatureReaded
        },
        openContractDetailContent() {
            this.showContractDetailContent = !this.showContractDetailContent
        },
        openStandardizedAgreement() {
            this.showStandardizedAgreement = !this.showStandardizedAgreement
        },
        openProvideSecurity() {
            this.showProvideSecurity = !this.showProvideSecurity
        },
        closeContractDetailContent() {
            window.scrollTo(0, 705)
            this.showContractDetailContent = !this.showContractDetailContent
            this.$store.commit('TflainIndividualContractStore/CONTRACT_DETAIL_CONTENT_MODAL', true)
        },
        closeStandardizedAgreement() {
            window.scrollTo(0, 805)
            this.showStandardizedAgreement = !this.showStandardizedAgreement
            this.$store.commit('TflainIndividualContractStore/STANDARDIZED_AGREEMENT_MODAL', true)
        },
        closeProvideSecurity() {
            this.showProvideSecurity = !this.showProvideSecurity
            this.$store.commit('TflainIndividualContractStore/PROVIDE_SECURITY_MODAL', true)
            this.$nextTick(() => {
                window.scrollTo(0, 1000);
            })
        },
        openContractDatePicker() {
            this.showContractDatePicker = !this.showContractDatePicker
        },
        closeContractDatePicker() {
            this.showContractDatePicker = !this.showContractDatePicker
        },
        openContractEndDatePicker() {
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        closeContractEndDatePicker() {
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        resultContractDatePicker() {
            const result = this.$refs.contractDatePicker.checkDate()
            this.closeContractDatePicker()
            if (result.validResult) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_CONTRACT_DATE_COLOR', false);
                this.$store.commit('TflainIndividualContractStore/EC03TF_SUCCESS_CONTRACT_DATE', {
                    contractDate: result.date,
                    isValid: true
                });
            } else {
                this.$store.commit('TflainIndividualContractStore/EC03TF_FAILED_CONTRACT_DATE', result.date)
                this.failedContractDateModal = !this.failedContractDateModal
            }
        },
        resultContractEndDatePicker() {
            const result = this.$refs.contractEndDatePicker.checkDate()
            this.closeContractEndDatePicker()
            if (result.validResult) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_CONTRACT_END_DATE_COLOR', false);
                this.$store.commit('TflainIndividualContractStore/EC03TF_SUCCESS_END_CONTRACT_DATE', {
                    contractEndDate: result.date,
                    isValid: true
                });
            } else {
                this.$store.commit('TflainIndividualContractStore/EC03TF_FAILED_END_CONTRACT_DATE', result.date)
                this.failedContractEndDateModal = !this.failedContractEndDateModal
            }
        },
        saveSignatureProvideSecurity() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC03TF_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureReaded = !this.showSignatureReaded
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('TflainIndividualContractStore/EC03TF_SIGNATURE_COLOR', false)
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        isValidPrice(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num)) {
                num = null;
                return;
            }
            e.target.value = num.toLocaleString();
            if (String(this.contractDetail.loanAmt) === String(num)) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_PRICE', {name: e.target.value, isValid: true});
                this.$store.commit('TflainIndividualContractStore/EC03TF_PRICE_COLOR', false);
            }
        },
        isCheckedPrice(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num)) {
                num = null;
                return;
            }
            if (String(num) !== String(this.contractDetail.loanAmt)) {
                this.isLoanAmt = true
            }
        },
        isValidFeeAmt(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num)) {
                num = null;
                return;
            }
            e.target.value = num.toLocaleString();
            if (String(this.contractDetail.feeAmt) === String(num)) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_FEE_AMT', {name: e.target.value, isValid: true});
                this.$store.commit('TflainIndividualContractStore/EC03TF_FEE_AMT_COLOR', false);
            }
        },
        isCheckedFeeAmt(e) {
            let price = e.target.value
            let num = parseInt(price.replace(/,/g, ''));
            if (isNaN(num)) {
                num = null;
                return;
            }
            if (String(num) !== String(this.contractDetail.feeAmt)) {
                this.isFeeAmt = true
            }
        },
        confirmLoanAmtModal() {
            this.isLoanAmt = false
        },
        confirmFeeAmtModal() {
            this.isFeeAmt = false
        },
        isValidYearAply(e) {
            if (String(this.contractDetail.aplyRate) === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_YEAR_APLY', {name: e.target.value, isValid: true})
                this.$store.commit('TflainIndividualContractStore/EC03TF_YEAR_APLY_COLOR', false)
            }
        },
        isCheckedYearAply(e) {
            if (String(this.contractDetail.aplyRate) !== e.target.value) {
                this.isYearAplyRate = true
            }
        },
        confirmYearAplyRateModal() {
            this.isYearAplyRate = false
        },
        isValidMonthAply(e) {
            if (String(this.divideByTwelveRounding(this.contractDetail.aplyRate)) === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_MONTH_APLY', {name: e.target.value, isValid: true})
                this.$store.commit('TflainIndividualContractStore/EC03TF_MONTH_APLY_COLOR', false)
            }
        },
        isCheckedMonthAply(e) {
            if (String(this.divideByTwelveRounding(this.contractDetail.aplyRate)) !== e.target.value) {
                this.isMonthAplyRate = true
            }
        },
        confirmMonthAplyRateModal() {
            this.isMonthAplyRate = false
        },
        isValidYearDelay(e) {
            if (String(this.contractDetail.delayRate) === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_YEAR_DELAY', {name: e.target.value, isValid: true})
                this.$store.commit('TflainIndividualContractStore/EC03TF_YEAR_DELAY_COLOR', false)
            }
        },
        isCheckedYearDelay(e) {
            if (String(this.contractDetail.delayRate) !== e.target.value) {
                this.isYearDelayRate = true
            }
        },
        confirmYearDelayRateModal() {
            this.isYearDelayRate = false
        },
        isValidMonthDelay(e) {
            if (String(this.divideByTwelveRounding(this.contractDetail.delayRate)) === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_MONTH_DELAY', {name: e.target.value, isValid: true})
                this.$store.commit('TflainIndividualContractStore/EC03TF_MONTH_DELAY_COLOR', false)
            }
        },
        isCheckedMonthDelay(e) {
            if (String(this.divideByTwelveRounding(this.contractDetail.delayRate)) !== e.target.value) {
                this.isMonthDelayRate = true
            }
        },
        isCheckedLoanTrm(e) {
            if (String(parseInt(this.contractDetail.loanTrm)) !== e.target.value) {
                this.isLoanTrm = true
            }
        },
        isValidLoanTrm(e) {
            if (String(parseInt(this.contractDetail.loanTrm)) === e.target.value) {
                this.$store.commit('TflainIndividualContractStore/EC03TF_LOAN_TRM', {name: e.target.value, isValid: true})
                this.$store.commit('TflainIndividualContractStore/EC03TF_LOAN_TRM_COLOR', false)
            }
        },
        confirmMonthDelayRateModal() {
            this.isMonthDelayRate = false
        },
        confirmLoanTrmModal() {
            this.isLoanTrm = false
        },
        validAgreementReceived(value) {
            this.agreementReceived = value
        },
        validAgreementExplain(value) {
            this.agreementExplanationReceived = value
        },
        validIllegalCommission(value) {
            this.illegalCommissionReceivedExplained = value
        },
        checkLoanAmtModal() {
            this.isLoanAmtModal = false
        },
        checkFeeAmtModal() {
            this.isFeeAmtModal = false
        },
        checkCntrctDtModal() {
            this.isCntrctDtModal = false
            this.showContractDatePicker = !this.showContractDatePicker
        },
        checkMatDtModal() {
            this.isMatDtModal = false
            this.showContractEndDatePicker = !this.showContractEndDatePicker
        },
        checkYearAplyRateModal() {
            this.isYearAplyRateModal = false
        },
        checkMonthAplyRateModal() {
            this.isMonthAplyRateModal = false
        },
        checkYearDelayRateModal() {
            this.isYearDelayRateModal = false
        },
        checkMonthDelayRateModal() {
            this.isMonthDelayRateModal = false
        },
        checkLoanTrmModal() {
            this.isLoanTrmModal = false
        },
        checkContractDetailContentModal() {
            this.isContractDetailContentModal = false
        },
        checkStandardizedAgreementModal() {
            this.isStandardizedAgreementModal = false
        },
        checkProvideSecurityModal() {
            this.isProvideSecurityModal = false
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.showSignatureReaded = !this.showSignatureReaded
        },
        checkAgreementReceivedModal() {
            this.isAgreementReceivedModal = false
        },
        checkAgreementExplanationReceivedModal() {
            this.isAgreementExplanationReceivedModal = false
        },
        checkIllegalCommissionReceivedExplainedModal() {
            this.isIllegalCommissionReceivedExplainedModal = false
        },
        nextPage() {
            if (this.priceColor) {
                this.isLoanAmtModal = true
            } else if (this.feeAmtColor) {
                this.isFeeAmtModal = true
            } else if (this.contractDateColor) {
                this.isCntrctDtModal = true
            } else if (this.contractEndDateColor) {
                this.isMatDtModal = true
            } else if (this.yearAplyRateColor) {
                this.isYearAplyRateModal = true
            } else if (this.monthAplyRateColor) {
                this.isMonthAplyRateModal = true
            } else if (this.yearDelayRateColor) {
                this.isYearDelayRateModal = true
            } else if (this.monthDelayRateColor) {
                this.isMonthDelayRateModal = true
            } else if (this.loanTrmColor) {
                this.isLoanTrmModal = true
            } else if (!this.contractDetailContentCheckbox) {
                this.isContractDetailContentModal = true
            } else if (!this.standardizedAgreementCheckbox) {
                this.isStandardizedAgreementModal = true
            } else if (!this.provideSecurityCheckbox) {
                this.isProvideSecurityModal = true
            } else if (!this.EC03TF.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else if (!this.agreementReceived) {
                this.isAgreementReceivedModal = true
            } else if (!this.agreementExplanationReceived) {
                this.isAgreementExplanationReceivedModal = true
            } else if (!this.illegalCommissionReceivedExplained) {
                this.isIllegalCommissionReceivedExplainedModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>