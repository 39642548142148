<template>
    <div class="modal_wrap" v-if="modal">
<!--        <div class="backdrop" @click="closeModal()"></div>-->
        <div class="backdrop"></div>
        <div class="modal_content_wrap">
<!--            <button class="cancelBtn_icon" @click="closeModal()"></button>-->
            <div class="modal_content">
                <slot name="ModalContent">
                    default
                </slot>
            </div>
            <button class="modal_btn" @click="confirmModal">확인</button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            modal: true,
        }
    },
    methods: {
        closeModal() {
            this.modal = false;
        },
        confirmModal() {
            this.$emit('confirmBtn')
        } 
    },
    mounted() {
        // prevent background scroll
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = null;
    },
}
</script>
<style>

</style>