import Vue from 'vue'
import App from './App.vue'
import {router} from '@/route'
import {store} from '@/store'
import GlobalComponent from '@/plugins/GlobalComponents.js'
import mixin from '@/mixins/index.js'
import scrollMixin from '@/mixins/scrollMixin'
import workTimeMixin from "@/mixins/workTimeMixin";
import VueScrollPicker from "vue-scroll-picker"

Vue.config.productionTip = false;

Vue.use(VueScrollPicker)
Vue.mixin(mixin)
Vue.mixin(scrollMixin)
Vue.mixin(workTimeMixin)

new Vue({
  render: h => h(App),
  router,
  store,
  components: { GlobalComponent },
}).$mount('#app')