export default {
    name: 'mixin',
    props: {
        nextComponent: String,
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        nextPage() {
            if (this.nextComponent === null) {
                alert('에러 발생');
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
        convertToString(value) {
            return value === true ? 'Y' : 'N';
        },
        integerDateToString({year, month, day}) {
            return `${year}년 ${month}월 ${day}일`
        },
        divideByTwelve(value) {
            const result = value / 12;
            return Math.floor(result * 100) / 100
        },
        divideByTwelveRounding(value) {
            const result = value / 12;
            return Math.round(result * 100) / 100
        },
    },
    filters: {
        formatDate(cntrctDt) {
            if (!cntrctDt) return;
            const year = cntrctDt.slice(0, 4)
            const month = cntrctDt.slice(4, 6)
            const day = cntrctDt.slice(6, 8)
            return `${year}.${month}.${day}`
        },
        formatContractDate(date) {
            if (!date) return;
            const year = date.slice(0, 4)
            const month = date.slice(4, 6)
            const day = date.slice(6, 8)
            return `${year}년 ${month}월 ${day}일`
        },
        formatHyphenContractDate(date) {
            if (!date) return;
            const year = date.slice(0, 4)
            const month = date.slice(4, 6)
            const day = date.slice(6, 8)
            return `${year}-${month}-${day}일`
        },
        formatGender(gender) {
            if (!gender) return;
            return gender === 'M' ? '남' : '여'
        },
        formatPhoneNr(PhoneNr) {
            if (!PhoneNr) return;
            const firstNr = PhoneNr.slice(0, 3)
            const secondNr = PhoneNr.slice(3, 7)
            const thirdNr = PhoneNr.slice(7, 11)
            return `${firstNr}-${secondNr}-${thirdNr}`
        },
        comma(price) {
            if (!price) return;
            return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        divideByTwelve(value) {
            const result = value / 12;
            return Math.floor(result * 100) / 100
        },
        divideByTwelveRounding(value) {
            const result = value / 12;
            return Math.round(result * 100) / 100
        },
        amountInKorean(value) {
            const units = ['', '십', '백', '천', '만', '십만', '백만', '천만', '억', '십억', '백억', '천억', '조'];
            const digits = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];

            const number = parseInt(value, 10);
            const length = value.length;

            if (isNaN(number) || length === 0) {
                return value;
            }

            let result = '';

            for (let i = 0; i < length; i++) {
                const digit = parseInt(value[i], 10);
                if (digit !== 0) {
                    result += digits[digit] + units[length - i - 1];
                }
            }

            return result;
        },
    },
}




