<template>
  <div class="common_box">
    <div class="checkbox_wrap" @click="eventEmit">
      <slot name="checkboxMainTitle">
        <div class="left">
          <input type="checkbox" id="" class="checkbox_24">
          <label for="">
            <div class="checkbox24_icon"></div>
            <div>default</div>
          </label>
        </div>
      </slot>
      <slot name="arrow_part">
      </slot>
    </div>
    <div class="bottom_content">
      <slot name="checkboxContent">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    eventEmit() {
      this.$emit('show')
    }
  }
}
</script>
<style>
  
</style>