<template>
    <div class="container" data-contract-company="aone" data-html-name="대부 계약 체결 상세 내용">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">대부 계약 체결 상세 내용</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="manual_article row">
                        <div class="manual_article_title">대부업법에 따른 법정최고이자율</div>
                        <div class="row_emp">연 {{ contractDetail.rateTop }}%</div>
                    </article>

                    <article class="manual_article row">
                        <div class="manual_article_title">이자납입일</div>
                        <div class="row_emp">매월 {{ contractDetail.pymtDd }}일</div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">이자 계산방법 예시</div>
                        <div>
                            (대부잔액 × 연 이자율 ÷ 365(윤년의 경우 366)) × 이용일 수<br/>
                            (대부잔액 × 연 이자율 ÷ 12) × 이용개월 수
                        </div>
                        <div class="manual_comment">
                            <div>※ 월 이율은 소수점 2자리를 반올림하여 표시</div>
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">변제방법</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_1" class="terms_depth1_checkbox"
                                       :checked="freeAmortization"
                                       disabled>
                                <label for="depth1_index_1">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>자유상환</div>
                                </label>
                            </div>

                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_2" class="terms_depth1_checkbox"
                                       :checked="installmentPayment"
                                       disabled>
                                <label for="depth1_index_2">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>분할상환</div>
                                </label>
                            </div>

                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_3" class="terms_depth1_checkbox"
                                       :checked="maturityPayment"
                                       disabled>
                                <label for="depth1_index_3">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>만기일 일시상환</div>
                                </label>
                            </div>
                        </div>

                        <div>
                            1. 약정이자율에 의하여 산출된 이자는 매월 정한 납입일에 후급한다.<br/>
                            2. 대출금의 상환 및 이자의 지급은 비용, 이자, 원금순으로 충당한다.<br/>
                            3. 원리금의 납입은 은행계좌이체 방식에 의한다.
                        </div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_4" class="terms_depth1_checkbox" disabled>
                                <label for="depth1_index_4">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>대출금상환전용 은행가상계좌</div>
                                </label>
                            </div>
                        </div>

                        <div>
                            <div class="account_box">{{ contractDetail.cusRepayAccBankNm }} {{ contractDetail.vaAcntNo }}</div>
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">기한이익상실</div>

                        <div>
                            ‘대부거래표준약관 제 12조’에 규정한 바에 따른다.
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">부대비용</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_5" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_5">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_6" class="terms_depth1_checkbox"
                                       disabled>
                                <label for="depth1_index_6">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>신용조회비(￦ {{ contractDetail.feeAmt | comma }})</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_7" class="terms_depth1_checkbox"
                                       disabled>
                                <label for="depth1_index_7">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>담보권설정비(￦ {{ contractDetail.collateralSettingFee | comma }})</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_comment">
                            <div>단, 담보권 해지 시 해지 비용은 채무자가 부담한다.</div>
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">조기상환조건</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_8" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_8">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">대부금지급방법</div>

                        <div>
                            “을”은 입금자명을 (주)에이원대부캐피탈로 하여 “갑”이 지정한 “갑” 명의의 은행계좌로 이체한다.
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">채무 및 보증 채무증명서 발급조건</div>

                        <div class="emp">- 발급비용</div>

                        <div class="manual_checkbox_wrap mb8">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_9" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_9">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>
                        </div>

                        <div class="emp">- 발급기한</div>

                        <div>
                            1. 현장신청시 : 신청당일<br/>
                            2. 전화·우편접수등 : 신청 익일까지 우편, 팩스발송
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">특약사항</div>

                        <div>
                            <textarea placeholder="내용을 입력해주세요." disabled></textarea>
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeContractDetailContent()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
        }),
        freeAmortization() {
            return this.contractDetail.repayMthd === '01'
        },
        installmentPayment() {
            const repaymentMethods = ['04', '05', '06'];
            return repaymentMethods.includes(this.contractDetail.repayMthd);
        },
        maturityPayment() {
            return this.contractDetail.repayMthd === '03'
        },
        additionalCost() {
            return (this.contractDetail.feeAmt === null || this.contractDetail.feeAmt === '') && (this.contractDetail.collateralSettingFee === null || this.contractDetail.collateralSettingFee === '')
        },
    },
    data() {
        return {
            contractDetail: null,
        }
    },
    methods: {
        closeContractDetailContent() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>