<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보 수집·이용·조회·제공 동의서_필수">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract" v-if="!showAgreementFirst && !showAgreementSecond && !showAgreementThird && !showAgreementFourth">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">개인신용정보에 관한 동의서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default" @change="toggleAllHasFalse">

                    <article class="agreement_intro_article">
                        <div class="article_subheading emp_bold">㈜티플레인대부 귀중</div>
                        <div class="comment gray none">귀사와의 (금융)거래와 관련하여 귀사가 본인의 개인(신용)정보를 수집 - 이용·조회·제공하고자 하는 경우에는『개인정보보호법』제15조, 제17조, 제22조 및 제 24조 '정보통신망 이용촉진 및 정보보호 등에 관한 법률」제22조, 제24조의2, 제25조 및 제26조의2「신용정보의 이용 및 보호에 관한 법률」제32조, 제33조 및 제34조에 따라 동의를 얻어야 합니다. 이에 본인은 귀사가 아래의 내용과 같이 본인의 개인(신용)정보를 처리하는데 동의합니다.</div>

                        <div class="article_subheading emp_bold">필수 동의사항</div>
                        <div class="comment gray none">필수 동의사항에 대한 동의는 계약의 체결 및 이행을 위하여 필수적이므로, 아래 사항에 동의하셔야만 대부거래 관계의 설정 또는 유지가 가능합니다.</div>
                    </article>

                    <article>
                        <div class="checkbox_wrap agreement_all">
                            <input
                                type="checkbox"
                                id="agreement_depth1_index_ALL"
                                class="checkbox_24 agreement all"
                                v-model="selectAll"
                                @change="toggleAll"
                            >
                            <label for="agreement_depth1_index_ALL">
                                <div class="checkbox_agreement_unactive_icon"></div>
                                <div class="agreement_content">전체동의</div>
                            </label>
                        </div>

                        <div class="agreement_type">
                            <div>2-1. 개인(신용)정보의 필수적 수집·이용 동의</div>
                            <div class="watch_agreement_detail" @click="openAgreementFirst()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_1" class="checkbox_24 agreement" v-model="agreements[0].checked">
                                <label for="agreement_depth1_index_1">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_2" class="checkbox_24 agreement" v-model="agreements[1].checked">
                                <label for="agreement_depth1_index_2">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">
                                        본인은 귀사가 상기 목적으로 본인의 고유식별정보를 처리하는 것에 동의합니다.
                                        <div class="comment gray none">※고유식별정보 : 주민등록번호, 운전면허번호, 여권번호, 외국인등록번호</div>
                                    </div>
                                </label>
                            </template>
                        </AgreementCase>
                    </article>

                    <article>
                        <div class="agreement_type">
                            <div>2-2. 개인(신용)정보 조회 동의</div>
                            <div class="watch_agreement_detail" @click="openAgreementSecond()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_3" class="checkbox_24 agreement" v-model="agreements[2].checked">
                                <label for="agreement_depth1_index_3">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_4" class="checkbox_24 agreement" v-model="agreements[3].checked">
                                <label for="agreement_depth1_index_4">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">
                                        본인은 귀사가 상기 목적으로 본인의 고유식별정보를 처리하는 것에 동의합니다.
                                        <div class="comment gray none">※고유식별정보 : 주민등록번호, 운전면허번호, 여권번호, 외국인등록번호</div>
                                    </div>
                                </label>
                            </template>
                        </AgreementCase>
                    </article>

                    <article>
                        <div class="agreement_type">
                            <div>2-3. 개인(신용)정보 필수적 제공 동의</div>
                            <div class="watch_agreement_detail" @click="openAgreementThird()">보기</div>
                        </div>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_5" class="checkbox_24 agreement" v-model="agreements[4].checked">
                                <label for="agreement_depth1_index_5">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">본인은 귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</div>
                                </label>
                            </template>
                        </AgreementCase>

                        <AgreementCase>
                            <template #agreementCaseCheckbox>
                                <input type="checkbox" id="agreement_depth1_index_6" class="checkbox_24 agreement" v-model="agreements[5].checked">
                                <label for="agreement_depth1_index_6">
                                    <div class="checkbox_agreement_unactive_icon"></div>
                                    <div class="agreement_content">
                                        본인은 귀사가 상기 목적으로 본인의 고유식별정보를 처리하는 것에 동의합니다.
                                        <div class="comment gray none">※고유식별정보 : 주민등록번호, 운전면허번호, 여권번호, 외국인등록번호</div>
                                    </div>
                                </label>
                            </template>
                        </AgreementCase>

                        <div data-sign="sign_field" class="mt16">
                            <div class="label_case">성명</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColorAgreementFirst }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignatureAgreementFirst()">
                                        <button v-html="signedDataAgreementFirst"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                    <article>
                        <div class="agreement_type">
                            <div>3. 불법중개수수료 및 권리 안내</div>
                            <div class="watch_agreement_detail" @click="openAgreementFourth()">보기</div>
                        </div>

                        <div data-sign="sign_field">
                            <div class="label_case">성명</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        v-model="contractDetail.kornNm"
                                        :class="{ blue: signatureColorAgreementSecond }"
                                        disabled
                                    >
                                    <label for="sign" @click="openSignatureAgreementSecond()">
                                        <button v-html="signedDataAgreementSecond"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap" v-if="nextComponent === 'complete'">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>
                <section class="bottomBtn_wrap" v-else>
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            다음
                        </button>
                    </BottomBtn>
                </section>
            </main>
        </div>

        <CommonModal v-if="isSelectAllModal" @confirmBtn="checkSelectAllModal()">
            <div slot="ModalContent">
                <span class="emp_red">필수 동의사항</span>을<br>
                체크해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureFirstModal" @confirmBtn="checkKornNmSignatureFirstModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureSecondModal" @confirmBtn="checkKornNmSignatureSecondModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <AgreementFirst v-if="showAgreementFirst" @close="closeAgreementFirst" />
        <AgreementSecond v-if="showAgreementSecond" @close="closeAgreementSecond" />
        <AgreementThird v-if="showAgreementThird" @close="closeAgreementThird" />
        <AgreementFourth v-if="showAgreementFourth" @close="closeAgreementFourth" />
        <SignatureAgreementFirst v-if="showSignatureAgreementFirst" ref="signaturePad" @close="closeSignatureAgreementFirst()"
                         @save="saveSignatureAgreementFirst" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <SignatureAgreementSecond v-if="showSignatureAgreementSecond" ref="signaturePad" @close="closeSignatureAgreementSecond()"
                         @save="saveSignatureAgreementSecond" :propsName="contractDetail.kornNm" :propsBizCode="contractDetail.bizCode"/>
        <InicisComponent ref="openPopup"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex'

import SignatureAgreementFirst from '@/components/common/SignaturePad.vue'
import SignatureAgreementSecond from "@/components/common/SignaturePad.vue";
import InicisComponent from "@/components/InicisComponent.vue";
import AgreementFirst from "@/views/common/tflain/Agreement-2-1.vue";
import AgreementSecond from "@/views/common/tflain/Agreement-2-2.vue";
import AgreementThird from "@/views/common/tflain/Agreement-2-3.vue";
import AgreementFourth from "@/views/common/tflain/Agreement-2-4.vue";

export default {
    components: {
        AgreementSecond, AgreementFirst, AgreementThird, AgreementFourth,
        SignatureAgreementFirst,
        SignatureAgreementSecond,
        InicisComponent,
    },
    props: {
        nextComponent: String,
    },
    data() {
        return {
            contractDetail: null,
            showAgreementFirst: false,
            showAgreementSecond: false,
            showAgreementThird: false,
            showAgreementFourth: false,
            selectAll: false,
            agreements: [
                { checked: false },
                { checked: false },
                { checked: false },
                { checked: false },
                { checked: false },
                { checked: false },
            ],
            showSignatureAgreementFirst: false,
            showSignatureAgreementSecond: false,
            isLoading: false,

            // check
            isKornNmSignatureFirstModal: false,
            isKornNmSignatureSecondModal: false,
            isSelectAllModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('TflainIndividualContractStore', ['EC01TF', 'EC02TF', 'EC03TF', 'EC04TF', 'EC05TF']),
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
            individualContractStore: state => state.TflainIndividualContractStore,
        }),
        // Agreement First 사인 데이터 관련
        signedDataAgreementFirst() {
            const data = this.EC05TF.signedDataAgreementFirst
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        signatureColorAgreementFirst() {
            return this.EC05TF.nameColorValidAgreementFirst
        },
        // Agreement Second 사인 데이터 관련
        signedDataAgreementSecond() {
            const data = this.EC05TF.signedDataAgreementSecond
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        signatureColorAgreementSecond() {
            return this.EC05TF.nameColorValidAgreementSecond
        },
        isDisabled() {
            const setPath = this.EC05TF
            return this.selectAll && setPath.signedDataValidAgreementFirst && setPath.signedDataValidAgreementSecond
        },
        contractSignedData() {
            return {
                signedData01: this.EC01TF.signedData,
                signedData02: this.EC02TF.signedData,
                signedData03: this.EC03TF.signedData,
                signedData04: this.EC04TF.signedData,
                signedData05: this.EC05TF.signedDataAgreementFirst,
                signedData06: this.EC05TF.signedDataAgreementSecond,
                PCICollectionCheckbox: this.convertToString(this.EC04TF.PCICollectionCheckbox),
                PCIProvideCheckbox: this.convertToString(this.EC04TF.PCIProvideCheckbox),
                marketingChannelPhoneCheckbox: this.convertToString(this.EC04TF.marketingChannelPhoneCheckbox),
                marketingChannelSNSCheckbox: this.convertToString(this.EC04TF.marketingChannelSNSCheckbox),
                marketingChannelWritingCheckbox: this.convertToString(this.EC04TF.marketingChannelWritingCheckbox),
                marketingChannelEmailCheckbox: this.convertToString(this.EC04TF.marketingChannelEmailCheckbox),
                kakaoAlertingServiceCheckbox: this.convertToString(this.EC04TF.kakaoAlertingServiceCheckbox)
            }
        },
    },
    methods: {
        // 동의서 보기
        openAgreementFirst() {
            this.showAgreementFirst = !this.showAgreementFirst
        },
        closeAgreementFirst() {
            this.showAgreementFirst = !this.showAgreementFirst
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        openAgreementSecond() {
            this.showAgreementSecond = !this.showAgreementSecond
        },
        closeAgreementSecond() {
            this.showAgreementSecond = !this.showAgreementSecond
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        openAgreementThird() {
            this.showAgreementThird = !this.showAgreementThird
        },
        closeAgreementThird() {
            this.showAgreementThird = !this.showAgreementThird
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        openAgreementFourth() {
            this.showAgreementFourth = !this.showAgreementFourth
        },
        closeAgreementFourth() {
            this.showAgreementFourth = !this.showAgreementFourth
            this.$nextTick(() => {
                window.scrollTo(0, 893)
            })
        },
        // 전체동의 체크박스 관련 메소드
        toggleAll() {
            for (const agreement of this.agreements) {
                agreement.checked = this.selectAll;
                if (Array.isArray(agreement.belowChecked)) {
                    agreement.belowChecked = agreement.belowChecked.map(() => this.selectAll);
                }
            }
        },
        toggleAllHasFalse() {
            for(let i = 0; i < this.agreements.filter(item => item.checked).length; i++) {
                const hasUnchecked = this.agreements.some(item => item.checked === false);
                if (hasUnchecked) {
                    this.selectAll = false
                } else {
                    this.selectAll = true
                }
            }
        },
        // Agreement First 사인 데이터 관련
        openSignatureAgreementFirst() {
            this.showSignatureAgreementFirst = !this.showSignatureAgreementFirst
        },
        closeSignatureAgreementFirst() {
            this.showSignatureAgreementFirst = false
            this.$nextTick(() => {
                window.scrollTo(0, 1104)
            });
        },
        saveSignatureAgreementFirst() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC05TF_SIGNATURE_AGREEMENT_FIRST', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureAgreementFirst = !this.showSignatureAgreementFirst
        },
        // Agreement Second 사인 데이터 관련
        openSignatureAgreementSecond() {
            this.showSignatureAgreementSecond = !this.showSignatureAgreementSecond
        },
        closeSignatureAgreementSecond() {
            this.showSignatureAgreementSecond = false
            this.$nextTick(() => {
                window.scrollTo(0, 1104)
            });
        },
        saveSignatureAgreementSecond() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('TflainIndividualContractStore/EC05TF_SIGNATURE_AGREEMENT_SECOND', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureAgreementSecond = !this.showSignatureAgreementSecond
        },
        checkSelectAllModal() {
            this.isSelectAllModal = false
        },
        checkKornNmSignatureFirstModal() {
            this.isKornNmSignatureFirstModal = false
            this.showSignatureAgreementFirst = !this.showSignatureAgreementFirst
        },
        checkKornNmSignatureSecondModal() {
            this.isKornNmSignatureSecondModal = false
            this.showSignatureAgreementSecond = !this.showSignatureAgreementSecond
        },
        async completeContract() {
            if (!this.selectAll) {
                this.isSelectAllModal = true
            } else if (!this.EC05TF.signedDataValidAgreementFirst) {
                this.isKornNmSignatureFirstModal = true
            } else if (!this.EC05TF.signedDataValidAgreementSecond) {
                this.isKornNmSignatureSecondModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('TflainIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            await this.$refs.openPopup.openPopup()
                        }
                    });
            }
        },
        nextPage() {
            if (!this.selectAll) {
                this.isSelectAllModal = true
            } else if (!this.EC05TF.signedDataValidAgreementFirst) {
                this.isKornNmSignatureFirstModal = true
            } else if (!this.EC05TF.signedDataValidAgreementSecond) {
                this.isKornNmSignatureSecondModal = true
            } else {
                this.$router.push({name: this.nextComponent})
            }
        },
    }
}
</script>

<style>

</style>