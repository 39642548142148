<template>
    <div class="container" data-contract-company="aone" data-html-name="추가약정서_부동산담보대출용">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="contract">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon show" @click="goBack()"></button>
                </template>
                <div slot="title">추가약정서</div>
            </header-blue>

            <main class="bottom_btn_case">
                <section class="default">
                    <article class="confirmation_article">
                        <div class="confirmation_title">(부동산담보대출용)</div>
                        <div class="subtext">주식회사 에이원대부캐피탈 귀중</div>
                    </article>

                    <article>
                        <div>본인은 당사와 체결한 {{ contractDetail.addLoanDate | formatContractDate }}자 금전소비자대차계약서에 추가하여 다음과 같이 약정합니다.</div>
                    </article>

                    <article class="signature_article">
                        <div data-sign="sign_field">
                            <div class="label_case">채무자</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        ref="kornNm"
                                        :placeholder="contractDetail.kornNm"
                                        :value="returnName"
                                        :class="{ blue: signatureColor }"
                                        :disabled="!signatureColor"
                                        @input="isValidName"
                                        @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData" ref="kornNmSignature"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>
                    
                    <article class="user_info_article">
                        <div class="common_box">
                            <div class="user_info_title">주소</div>
                            <div>{{ contractDetail.addDebtorAddress }}</div>
                        </div>
                    </article>

                    <div class="title_wrap">
                        <div class="article_title">제1조 [개요]</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div class="area_block">
                            <div>본인은 부동산담보대출을 신청함에 있어 대출상품을 충분히 이해하고 있으며, 대부거래 표준약관 및 추가약정서에 따라 채무자로서의 의무를 성실히 이행할 것을 확약합니다.
                            </div>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="common_box">
                            <div class="user_info_title">담보 목적물<br />소재지</div>
                            <div>{{ contractDetail.addCollateralPropertyAddress }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">담보설정<br />금액</div>
                            <div>{{ contractDetail.addMortgageAmount | comma }}원</div>
                        </div>
                    </article>

                    <div class="title_wrap">
                        <div class="article_title">제2조 [담보부동산 근저당권설정]</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div>
                            <div>① 본인은 당사와 금전소비대차계약서 및 근저당권 설정계약을 체결함에 있어서, 위 담보 목적물 소재지에 근저당권을 설정하기로 하였고, 이에 본인은 당사가 근저당권을
                                설정하는 데에 있어서 필요한 모든 사항을 지원할 것이며, 근저당권 설정이 완료되기 전에 타 금융기관으로부터 금전물을 차용하거나 또는 기타 사유로 인하여 당사가
                                근저당권 설정을 하지 못하는 경우에 대출금 전액을 상환하기로 한다.</div>
                            <div>② 본인은 현재 위 담보 목적물 소재지에 임대인으로서 제3자와 임대차계약을 체결한 사실이 없음을 확인하고, 당사와 근저당권 설정 이후에도 제3자에게 위 목적물
                                소재지에 대하여 임대차계약을 체결하는 등의 사유로 인하여 당사가 설정한 근저당권의 권리가 침해 받는 사실이 발생하지 않도록 할 것임을 확약하며, 추후 위 확약과 다른
                                사실이 확인되는 경우 발생하는 모든 민•형사상 책임은 본인이 부담함을 확약합니다.</div>
                        </div>
                    </article>

                    <div class="title_wrap">
                        <div class="article_title">제3조 [기한이익의 상실]</div>
                    </div>
                    <article class="terms_article layout_setting_others">
                        <div>본인은 대부거래 표준 약관에서 정한 기한이익의 상실 조항 이외에 다음의 경우 기한의 이익을 상실하여 곧 대출금을 갚아야 할 의무를 집니다.</div>
                        <div>
                            ① 담보 부동산에 임대차계약이 있으나, 허위로 서류를 작성하여 임대차 금액이 없는 것으로 제출한 때<br />
                            ② 개인회생 절차가 있을 때<br />
                            ③ 채무자 또는 보증인의 책임 있는 사유로 신용이 악화되거나 담보물의 가액 감소가 현저한 경우
                        </div>
                    </article>
                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="completeContract()"
                            :class="{ active: isDisabled }"
                        >
                            계약서 제출 완료
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmModal" @confirmBtn="checkKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">채무자</span>를<br>
                기재해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isKornNmSignatureModal" @confirmBtn="checkKornNmSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()" @save="saveSignatureDebtor" :propsName="contractDetail.kornNm"/>
        <InicisComponent ref="openPopup"/>
        <LoadingSpinner v-if="isLoading"/>
    </div>
</template>

<script>
import {mapGetters, mapState,} from 'vuex'
import SignatureDebtor from '@/components/common/SignaturePad.vue'
import InicisComponent from "@/components/InicisComponent.vue";
import {contractCancel, contractWorkTimeChecker} from "@/api/contract";

export default {
    components: {
        SignatureDebtor,
        InicisComponent,
    },
    props: {
        nextComponent: String,
    },
    data() {
        return {
            contractDetail: null,
            showSignatureDebtor: false,
            isLoading: false,
            isKornNm: false,

            // check
            isKornNmModal: false,
            isKornNmSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC01AO', 'EC03AO', 'EC04AO', 'EC07AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        signedData() {
            const data = this.EC07AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='50' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC07AO.name
        },
        signatureColor() {
            return this.EC07AO.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            return this.EC07AO.nameValid && this.EC07AO.signedDataValid
        },
        contractSignedData() {
            return {
                signedData01: this.EC01AO.signedData,
                signedData02: this.EC03AO.signedData,
                signedData03: this.EC04AO.signedData,
                signedData04: this.EC07AO.signedData,
            }
        },
        receiveEmail() {
            return this.EC04AO.emailValid === true ? 'Y' : 'N'
        },
        emailAddress() {
            return this.EC04AO.emailAddress
        },
    },
    methods: {
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC07AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC07AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC07AO_SIGNATURE_COLOR', false)
            }
        },
        checkKornNmModal() {
            this.isKornNmModal = false
            this.$refs.kornNm.focus()
        },
        checkKornNmSignatureModal() {
            this.isKornNmSignatureModal = false
            this.$refs.kornNmSignature.focus()
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        async completeContract() {
            if (this.signatureColor) {
                this.isKornNmModal = true
            } else if (!this.EC07AO.signedDataValid) {
                this.isKornNmSignatureModal = true
            } else {
                await this.$store.dispatch('inicisStore/FETCH_INICIS', this.commonContractStore.contractDetail.mgNo)
                    .then(async response => {
                        if (response.data.result !== true || response.data.contents.userBirth.length !== 8) {
                            await this.$router.replace({name: 'InicisFailedComponent'});
                        } else {
                            this.$store.commit('AoneIndividualContractStore/SIGNED_DATA', {
                                signedData: this.contractSignedData
                            })
                            contractWorkTimeChecker(this.contractDetail.mgNo)
                                .then(response => {
                                    if (response.data.result === false) {
                                        if (response.data.clientErrorResponseDto.code === '3335') {
                                            this.$router.replace({name: 'ContractExpiredError'});
                                            contractCancel(this.contractDetail.mgNo);
                                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                                            this.$router.replace({name: 'ResentContractComponent'});
                                        }
                                    } else {
                                        this.$refs.openPopup.openPopup()
                                    }
                                })
                                .catch(error => console.log(error))
                        }
                    });
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
    }
}
</script>

<style></style>