const individualContractStore = {
    namespaced: true,
    state: {
        EC01TF: {
            productInstructionCheckbox: false, // 대출 상품 설명서 체크박스

            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC02TF: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC03TF: {
            contractDetailContentCheckbox: false, // 대부계약체결상세내용 체크박스
            standardizedAgreementCheckbox: false, // 대부거래 표준약관 체크박스
            provideSecurityCheckbox: false, // 채권양도 ∙ 양도담보제공승낙서 체크박스

            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크

            price: '', // 대출금액
            priceValid: false, // 대출금액체크
            priceColor: true, // 대출금액 클래스 체크

            feeAmt: '', // 대출금액
            feeAmtValid: false, // 대출금액체크
            feeAmtColor: true, // 대출금액 클래스 체크

            contractDate: '', // 계약일
            contractDateValid: false, // 계약일 체크
            contractDateColor: true, // 계약일 클래스 체크

            contractEndDate: '', // 만기일
            contractEndDateValid: false, // 만기일 체크
            contractEndDateColor: true, // 만기일 클래스 체크

            yearAplyRate: '', // 연정상이율
            yearAplyRateValid: false, // 연정상이율 체크
            yearAplyRateColor: true,  // 연정상이율 클래스 체크

            monthAplyRate: '', // 월정상이율
            monthAplyRateValid: false, // 월정상이율 체크
            monthAplyRateColor: true,  // 월정상이율 클래스 체크

            yearDelayRate: '', // 연연체이율
            yearDelayRateValid: false, // 연연체이율 체크
            yearDelayRateColor: true,  // 연연체이율 클래스 체크

            monthDelayRate: '', // 월연체이율
            monthDelayRateValid: false, // 월연체이율 체크
            monthDelayRateColor: true,  // 월연체이율 클래스 체크

            loanTrm: '', // 대부기간
            loanTrmValid: false, // 대부기간 체크
            loanTrmColor: true,  // 대부기간 클래스 체크
        },
        EC04TF: {
            PCICollectionCheckbox: false, // 1-1. 개인(신용)정보의 선택적 수집·이용 동의 체크박스 값
            PCIProvideCheckbox: false, // 1-2. 개인(신용)정보의 선택적 제공에 관한 사항 체크박스 값
            marketingChannelPhoneCheckbox: false, // 상품이용권유 수단 동의 전화 체크박스 값
            marketingChannelSNSCheckbox: false, // 상품이용권유 수단 동의 문자 체크박스 값
            marketingChannelWritingCheckbox: false, // 상품이용권유 수단 동의 서면 체크박스 값
            marketingChannelEmailCheckbox: false, // 상품이용권유 수단 동의 이메일 체크박스 값
            kakaoAlertingServiceCheckbox: false, // 1-3. 카카오 알림톡 서비스 이용에 관한 사항 체크박스 값

            signedData: '서명하기', // 개인신용정보 동의서 선택적 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 개인신용정보 동의서 선택적 채무자사인 데이터 체크
            nameColorValid: true, // 개인신용정보 동의서 선택적 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC05TF: {
            signedDataAgreementFirst: '서명하기', // 개인신용정보 동의서 필수적 채무자사인 이미지 데이터 소켓
            signedDataValidAgreementFirst: false, // 개인신용정보 동의서 필수적 채무자사인 데이터 체크
            nameColorValidAgreementFirst: true, // 개인신용정보 동의서 필수적 채무자본인 이름작성 클래스 :red boolean

            nameAgreementFirst: '', // 개인신용정보 동의서 필수적 채무자 본인 서명
            nameValidAgreementFirst: false, // 개인신용정보 동의서 필수적 채무자 본인 서명 체크

            signedDataAgreementSecond: '서명하기', // 개인신용정보 동의서 필수적 채무자사인 이미지 데이터 소켓
            signedDataValidAgreementSecond: false, // 개인신용정보 동의서 필수적 채무자사인 데이터 체크
            nameColorValidAgreementSecond: true, // 개인신용정보 동의서 필수적 채무자본인 이름작성 클래스 :red boolean

            nameAgreementSecond: '', // 개인신용정보 동의서 필수적 채무자 본인 서명
            nameValidAgreementSecond: false, // 개인신용정보 동의서 필수적 채무자 본인 서명 체크
        },
        EC06TF: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC07TF: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC08TF: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        EC09TF: {
            signedData: '서명하기', // 대부거래 계약서 채무자사인 이미지 데이터 소켓
            signedDataValid: false, // 대부거래 계약서 채무자사인 데이터 체크
            nameColorValid: true, // 채무자본인 이름작성 클래스 :red boolean

            name: '', // 채무자 본인 서명
            nameValid: false, // 채무자 본인 서명 체크
        },
        CONTRACT_EMAIL_CHECKED: {
            signedData: null,
        },
        completedContract: false,
    },
    getters: {
        EC01TF(state) {
            return state.EC01TF
        },
        EC02TF(state) {
            return state.EC02TF
        },
        EC03TF(state) {
            return state.EC03TF
        },
        EC04TF(state) {
            return state.EC04TF
        },
        EC05TF(state) {
            return state.EC05TF
        },
        EC06TF(state) {
            return state.EC06TF
        },
        EC07TF(state) {
            return state.EC07TF
        },
        EC08TF(state) {
            return state.EC08TF
        },
        EC09TF(state) {
            return state.EC09TF
        },
    },
    mutations: {
        // EC01TF
        EC01TF_SIGNATURE_DEBTOR(state, value) {
            state.EC01TF.signedData = value.data
            state.EC01TF.signedDataValid = value.isValid
        },
        EC01TF_SIGNATURE_NAME(state, value) {
            state.EC01TF.name = value.name
            state.EC01TF.nameValid = value.isValid
        },
        EC01TF_SIGNATURE_COLOR(state, value) {
            state.EC01TF.nameColorValid = value
        },
        PRODUCT_INSTRUCTION_MODAL(state, value) {
            state.EC01TF.productInstructionCheckbox = value
        },
        // EC02TF
        EC02TF_SIGNATURE_DEBTOR(state, value) {
            state.EC02TF.signedData = value.data
            state.EC02TF.signedDataValid = value.isValid
        },
        EC02TF_SIGNATURE_NAME(state, value) {
            state.EC02TF.name = value.name
            state.EC02TF.nameValid = value.isValid
        },
        EC02TF_SIGNATURE_COLOR(state, value) {
            state.EC02TF.nameColorValid = value
        },
        // EC03TF
        EC03TF_SIGNATURE_DEBTOR(state, value) {
            state.EC03TF.signedData = value.data
            state.EC03TF.signedDataValid = value.isValid
        },
        EC03TF_SIGNATURE_NAME(state, value) {
            state.EC03TF.name = value.name
            state.EC03TF.nameValid = value.isValid
        },
        EC03TF_SIGNATURE_COLOR(state, value) {
            state.EC03TF.nameColorValid = value
        },
        CONTRACT_DETAIL_CONTENT_MODAL(state, value) {
            state.EC03TF.contractDetailContentCheckbox = value
        },
        STANDARDIZED_AGREEMENT_MODAL(state, value) {
            state.EC03TF.standardizedAgreementCheckbox = value
        },
        PROVIDE_SECURITY_MODAL(state, value) {
            state.EC03TF.provideSecurityCheckbox = value
        },
        EC03TF_PRICE_COLOR(state, value) {
            state.EC03TF.priceColor = value
        },
        EC03TF_PRICE(state, value) {
            state.EC03TF.price = value.name
            state.EC03TF.priceValid = value.isValid
        },
        EC03TF_FEE_AMT_COLOR(state, value) {
            state.EC03TF.feeAmtColor = value
        },
        EC03TF_FEE_AMT(state, value) {
            state.EC03TF.feeAmt = value.name
            state.EC03TF.feeAmtValid = value.isValid
        },
        EC03TF_CONTRACT_DATE_COLOR(state, value) {
            state.EC03TF.contractDateColor = value
        },
        EC03TF_SUCCESS_CONTRACT_DATE(state, value) {
            state.EC03TF.contractDate = value.contractDate
            state.EC03TF.contractDateValid = value.isValid
        },
        EC03TF_FAILED_CONTRACT_DATE(state, value) {
            state.EC03TF.contractDate = value
        },
        EC03TF_CONTRACT_END_DATE_COLOR(state, value) {
            state.EC03TF.contractEndDateColor = value
        },
        EC03TF_SUCCESS_END_CONTRACT_DATE(state, value) {
            state.EC03TF.contractEndDate = value.contractEndDate
            state.EC03TF.contractEndDateValid = value.isValid
        },
        EC03TF_FAILED_END_CONTRACT_DATE(state, value) {
            state.EC03TF.contractEndDate = value
        },
        EC03TF_YEAR_APLY(state, value) {
            state.EC03TF.yearAplyRate = value.name
            state.EC03TF.yearAplyRateValid = value.isValid
        },
        EC03TF_YEAR_APLY_COLOR(state, value) {
            state.EC03TF.yearAplyRateColor = value
        },
        EC03TF_MONTH_APLY(state, value) {
            state.EC03TF.monthAplyRate = value.name
            state.EC03TF.monthAplyRateValid = value.isValid
        },
        EC03TF_MONTH_APLY_COLOR(state, value) {
            state.EC03TF.monthAplyRateColor = value
        },
        EC03TF_YEAR_DELAY(state, value) {
            state.EC03TF.yearDelayRate = value.name
            state.EC03TF.yearDelayRateValid = value.isValid
        },
        EC03TF_YEAR_DELAY_COLOR(state, value) {
            state.EC03TF.yearDelayRateColor = value
        },
        EC03TF_MONTH_DELAY(state, value) {
            state.EC03TF.monthDelayRate = value.name
            state.EC03TF.monthDelayRateValid = value.isValid
        },
        EC03TF_MONTH_DELAY_COLOR(state, value) {
            state.EC03TF.monthDelayRateColor = value
        },
        EC03TF_LOAN_TRM(state, value) {
            state.EC03TF.loanTrm = value.name
            state.EC03TF.loanTrmValid = value.isValid
        },
        EC03TF_LOAN_TRM_COLOR(state, value) {
            state.EC03TF.loanTrmColor = value
        },
        // EC04TF
        EC04TF_PCI_COLLECTION_CHECKBOX(state, value) {
            state.EC04TF.PCICollectionCheckbox = value;
        },
        EC04TF_PCI_PROVIDE_CHECKBOX(state, value) {
            state.EC04TF.PCIProvideCheckbox = value;
        },
        EC04TF_MARKETING_CHANNEL_PHONE_CHECKBOX(state, value) {
            state.EC04TF.marketingChannelPhoneCheckbox = value;
        },
        EC04TF_MARKETING_CHANNEL_SNS_CHECKBOX(state, value) {
            state.EC04TF.marketingChannelSNSCheckbox = value;
        },
        EC04TF_MARKETING_CHANNEL_WRITING_CHECKBOX(state, value) {
            state.EC04TF.marketingChannelWritingCheckbox = value;
        },
        EC04TF_MARKETING_CHANNEL_EMAIL_CHECKBOX(state, value) {
            state.EC04TF.marketingChannelEmailCheckbox = value;
        },
        EC04TF_KAKAO_ALERT_SERVICE_CHECKBOX(state, value) {
            state.EC04TF.kakaoAlertingServiceCheckbox = value;
        },
        EC04TF_SIGNATURE_DEBTOR(state, value) {
            state.EC04TF.signedData = value.data
            state.EC04TF.signedDataValid = value.isValid
        },
        EC04TF_SIGNATURE_NAME(state, value) {
            state.EC04TF.name = value.name
            state.EC04TF.nameValid = value.isValid
        },
        EC04TF_SIGNATURE_COLOR(state, value) {
            state.EC04TF.nameColorValid = value
        },
        // EC05TF
        EC05TF_SIGNATURE_AGREEMENT_FIRST(state, value) {
            state.EC05TF.signedDataAgreementFirst = value.data
            state.EC05TF.signedDataValidAgreementFirst = value.isValid
        },
        EC05TF_SIGNATURE_NAME_AGREEMENT_FIRST(state, value) {
            state.EC05TF.nameAgreementFirst = value.name
            state.EC05TF.nameValidAgreementFirst = value.isValid
        },
        EC05TF_SIGNATURE_COLOR_AGREEMENT_FIRST(state, value) {
            state.EC05TF.nameColorValidAgreementFirst = value
        },
        EC05TF_SIGNATURE_AGREEMENT_SECOND(state, value) {
            state.EC05TF.signedDataAgreementSecond = value.data
            state.EC05TF.signedDataValidAgreementSecond = value.isValid
        },
        EC05TF_SIGNATURE_NAME_AGREEMENT_SECOND(state, value) {
            state.EC05TF.nameAgreementSecond = value.name
            state.EC05TF.nameValidAgreementSecond = value.isValid
        },
        EC05TF_SIGNATURE_COLOR_AGREEMENT_SECOND(state, value) {
            state.EC05TF.nameColorValidAgreementSecond = value
        },
        SIGNED_DATA(state, value) {
            state.CONTRACT_EMAIL_CHECKED.signedData = value.signedData
        },
        // EC06TF
        EC06TF_SIGNATURE_DEBTOR(state, value) {
            state.EC06TF.signedData = value.data
            state.EC06TF.signedDataValid = value.isValid
        },
        EC06TF_SIGNATURE_NAME(state, value) {
            state.EC06TF.name = value.name
            state.EC06TF.nameValid = value.isValid
        },
        EC06TF_SIGNATURE_COLOR(state, value) {
            state.EC06TF.nameColorValid = value
        },
        // EC07TF
        EC07TF_SIGNATURE_DEBTOR(state, value) {
            state.EC07TF.signedData = value.data
            state.EC07TF.signedDataValid = value.isValid
        },
        EC07TF_SIGNATURE_NAME(state, value) {
            state.EC07TF.name = value.name
            state.EC07TF.nameValid = value.isValid
        },
        EC07TF_SIGNATURE_COLOR(state, value) {
            state.EC07TF.nameColorValid = value
        },
        // EC08TF
        EC08TF_SIGNATURE_DEBTOR(state, value) {
            state.EC08TF.signedData = value.data
            state.EC08TF.signedDataValid = value.isValid
        },
        EC08TF_SIGNATURE_NAME(state, value) {
            state.EC08TF.name = value.name
            state.EC08TF.nameValid = value.isValid
        },
        EC08TF_SIGNATURE_COLOR(state, value) {
            state.EC08TF.nameColorValid = value
        },
        // EC09TF
        EC09TF_SIGNATURE_DEBTOR(state, value) {
            state.EC09TF.signedData = value.data
            state.EC09TF.signedDataValid = value.isValid
        },
        EC09TF_SIGNATURE_NAME(state, value) {
            state.EC09TF.name = value.name
            state.EC09TF.nameValid = value.isValid
        },
        EC09TF_SIGNATURE_COLOR(state, value) {
            state.EC09TF.nameColorValid = value
        },
        // 계약 완료
        COMPLETED_CONTRACT(state, value) {
            state.completedContract = value
        },
        RESET(state) {
            // EC01TF
            state.EC01TF.productInstructionCheckbox = false
            state.EC01TF.name = ''
            state.EC01TF.nameValid = false
            state.EC01TF.nameColorValid = true
            state.EC01TF.signedData = '서명하기'
            state.EC01TF.signedDataValid = false
            // EC02TF
            state.EC02TF.signedData = '서명하기'
            state.EC02TF.signedDataValid = false
            state.EC02TF.nameColorValid = true
            state.EC02TF.name = ''
            state.EC02TF.nameValid = false
            // EC03TF
            state.EC03TF.contractDetailContentCheckbox = false
            state.EC03TF.standardizedAgreementCheckbox = false
            state.EC03TF.provideSecurityCheckbox = false
            state.EC03TF.signedData = '서명하기'
            state.EC03TF.signedDataValid = false
            state.EC03TF.nameColorValid = true
            state.EC03TF.name = ''
            state.EC03TF.nameValid = false
            state.EC03TF.price = ''
            state.EC03TF.priceValid = false
            state.EC03TF.priceColor = true
            state.EC03TF.feeAmt = ''
            state.EC03TF.feeAmtValid = false
            state.EC03TF.feeAmtColor = true
            state.EC03TF.contractDate = ''
            state.EC03TF.contractDateValid = false
            state.EC03TF.contractDateColor = true
            state.EC03TF.contractEndDate = ''
            state.EC03TF.contractEndDateValid = false
            state.EC03TF.contractEndDateColor = true
            state.EC03TF.yearAplyRate = ''
            state.EC03TF.yearAplyRateValid = false
            state.EC03TF.yearAplyRateColor = true
            state.EC03TF.monthAplyRate = ''
            state.EC03TF.monthAplyRateValid = false
            state.EC03TF.monthAplyRateColor = true
            state.EC03TF.yearDelayRate = ''
            state.EC03TF.yearDelayRateValid = false
            state.EC03TF.yearDelayRateColor = true
            state.EC03TF.monthDelayRate = ''
            state.EC03TF.monthDelayRateValid = false
            state.EC03TF.monthDelayRateColor = true
            state.EC03TF.loanTrm = ''
            state.EC03TF.loanTrmValid = false
            state.EC03TF.loanTrmColor = true
            // EC04TF
            state.EC04TF.PCICollectionCheckbox = 'false'
            state.EC04TF.PCIProvideCheckbox = 'false'
            state.EC04TF.marketingChannelPhoneCheckbox = 'false'
            state.EC04TF.marketingChannelSNSCheckbox = 'false'
            state.EC04TF.marketingChannelWritingCheckbox = 'false'
            state.EC04TF.marketingChannelEmailCheckbox = 'false'
            state.EC04TF.kakaoAlertingServiceCheckbox = 'false'
            state.EC04TF.signedData = '서명하기'
            state.EC04TF.signedDataValid = false
            state.EC04TF.nameColorValid = true
            state.EC04TF.name = ''
            state.EC04TF.nameValid = false
            // EC05TF
            state.EC05TF.signedDataAgreementFirst = '서명하기'
            state.EC05TF.signedDataValidAgreementFirst = false
            state.EC05TF.nameColorValidAgreementFirst = true
            state.EC05TF.nameAgreementFirst = ''
            state.EC05TF.nameValidAgreementFirst = false
            state.EC05TF.signedDataAgreementSecond = '서명하기'
            state.EC05TF.signedDataValidAgreementSecond = false
            state.EC05TF.nameColorValidAgreementSecond = true
            state.EC05TF.nameAgreementSecond = ''
            state.EC05TF.nameValidAgreementSecond = false
            // EC06TF
            state.EC06TF.signedData = '서명하기'
            state.EC06TF.signedDataValid = false
            state.EC06TF.nameColorValid = true
            state.EC06TF.name = ''
            state.EC06TF.nameValid = false
            // EC07TF
            state.EC07TF.signedData = '서명하기'
            state.EC07TF.signedDataValid = false
            state.EC07TF.nameColorValid = true
            state.EC07TF.name = ''
            state.EC07TF.nameValid = false
            // EC08TF
            state.EC08TF.name = ''
            state.EC08TF.nameValid = false
            state.EC08TF.nameColorValid = true
            state.EC08TF.signedData = '서명하기'
            state.EC08TF.signedDataValid = false
            // EC09TF
            state.EC09TF.name = ''
            state.EC09TF.nameValid = false
            state.EC09TF.nameColorValid = true
            state.EC09TF.signedData = '서명하기'
            state.EC09TF.signedDataValid = false
            // common
            state.CONTRACT_EMAIL_CHECKED.signedData = null
        },
    }
}

export default individualContractStore