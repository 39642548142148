<template>
    <div class="container" data-contract-company="tflain" data-html-name="대부 계약 체결 상세 내용">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">대부 계약 체결 상세 내용</div>
            </header-black>

            <main class="bottom_btn_case">

                <section class="default">
                    <article class="manual_article row">
                        <div class="manual_article_title">대부업법에 따른 법정최고이자율</div>
                        <div class="row_emp">연 {{ contractDetail.aplyRate }}%</div>
                    </article>

                    <article class="manual_article row">
                        <div class="manual_article_title">상환약정일</div>
                        <div class="row_emp">매월 {{ contractDetail.pymtDd }}일</div>
                    </article>


                    <article class="manual_article column">
                        <div class="manual_article_title">이자 계산방법 예시</div>
                        <div>
                            (대부잔액 × 연 이자율 ÷ 365(윤년의 경우 366)) × 이용일 수<br/>
                            (대부잔액 × 연 이자율 ÷ 12) × 이용개월 수
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">조기상환조건</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_1" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_1">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>

                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_2" class="terms_depth1_checkbox"
                                       disabled>
                                <label for="depth1_index_2">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>있음</div>
                                </label>
                            </div>
                        </div>

                        <div>
                            ※ 조기상환수수료 : 중도상환대부금액 × (3%) × (잔여일수  ÷ 대부기간)<br/>
                            ※ 대부이자, 연체이자, 중도상환수수료 합계금액은 법정최고금리 연 20% 이내 임
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">변제방법</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_3" class="terms_depth1_checkbox"
                                       :checked="contractDetail.repayMthd === '11304'"
                                       disabled>
                                <label for="depth1_index_3">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>원리금 균등 분할상환</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_comment">
                            매월 약정일에 월 약정액을 상환하는 방법(단, 정상적으로 약정일에 월 약정액을 납부한 경우)<br/>
                            대부기간 만료일에는 월 약정액보다 적은 금액(원금잔액 및 그에 대한 이자금액을 납부하게 됨)
                        </div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_4" class="terms_depth1_checkbox"
                                       :checked="(contractDetail.regType === '01') || (contractDetail.repayMthd === '11301')"
                                       disabled>
                                <label for="depth1_index_4">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>원금 자유상환</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_comment">
                            매월 약정일은 원금잔액에 대한 최소 이자금액을 납부(중도 상환 가능)하며 계약 체결일로부터 약정기간 만기일에 잔여 원금을 일시납으로 상환하는 방법
                        </div>

                        <div>
                            대부금 상환순서 : 대출금의 상환 및 이자의 지급은 은행 송금 등 당사자가 약정한 방법에 의하여 비용, 이자, 원금 순으로 충당한다. 대출금의 상환 및 이자의 지급은 은행 송금(채권자 입금계좌)등 당사자가 약정한 방법에 의한다.
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">부대비용의 내용 및 금액</div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_5" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_5">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>
                        </div>

                        <div class="manual_checkbox_wrap">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_6" class="terms_depth1_checkbox" disabled>
                                <label for="depth1_index_6">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>있음 (금<span class="underline">{{ '  ' }}원</span>)</div>
                                </label>
                            </div>
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">담보제공</div>

                        <div>
                            담보 제공자는 회사와 별도로 근저당설정 계약을 체결한다.
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">부채증명서 발급 조건</div>

                        <div class="emp">- 발급비용</div>

                        <div class="manual_checkbox_wrap mb8">
                            <div class="checkbox_group">
                                <input type="checkbox" id="depth1_index_7" class="terms_depth1_checkbox"
                                       checked
                                       disabled>
                                <label for="depth1_index_7">
                                    <div class="depth1_checkbox_icon"></div>
                                    <div>없음</div>
                                </label>
                            </div>
                        </div>

                        <div class="emp">- 발급기한</div>

                        <div>
                            방문신청시(즉시발급), 전화·우편신청 등(신청 익일까지 우편 발송) 단, 토,일,공휴일이 익일일 경우 차기영업일 발송
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">기한이익상실</div>

                        <div>
                            ‘대부거래표준약관 제 12조’에 규정한 바에 따른다.
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">대부금지급방법</div>

                        <div>
                            채무자명의의 채무자 지정계좌(입금자 : ㈜티플레인대부)
                        </div>
                    </article>

                    <article class="manual_article column">
                        <div class="manual_article_title">대부금지급방법</div>

                        <div>
                            <div class="account_box">{{ contractDetail.cusRepayAccBankNm }} {{ contractDetail.vaAcntNo }}</div>
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeContractDetailContent()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapState({
            commonContractStore: state => state.TflainCommonContractStore,
        }),
        freeAmortization() {
            return this.contractDetail.repayMthd === '01'
        },
        installmentPayment() {
            const repaymentMethods = ['04', '05', '06'];
            return repaymentMethods.includes(this.contractDetail.repayMthd);
        },
        maturityPayment() {
            return this.contractDetail.repayMthd === '03'
        },
        additionalCost() {
            return (this.contractDetail.feeAmt === null || this.contractDetail.feeAmt === '') && (this.contractDetail.collateralSettingFee === null || this.contractDetail.collateralSettingFee === '')
        },
    },
    data() {
        return {
            contractDetail: null,
        }
    },
    methods: {
        closeContractDetailContent() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>