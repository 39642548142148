import {contractCancel, contractWorkTimeChecker} from '@/api/contract'

export default {
    name: 'workTimeMixin',
    methods: {
        async contractWorkTimeChecked(mgNo) {
            contractWorkTimeChecker(mgNo)
                .then(async response => {
                    if (response.data.result === false) {
                        if (response.data.clientErrorResponseDto.code === '3335') {
                            await contractCancel(mgNo);
                            await this.$router.replace({name: 'ContractExpiredError'});
                        } else if (response.data.clientErrorResponseDto.code === '3336') {
                            await this.$router.replace({name: 'ResentContractComponent'});
                        }
                    }
                })
                .catch(error => console.log(error))
        },
    },
}


