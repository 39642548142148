import { fetchContractDetail, } from "@/api/contract";

const commonContractStore = {
    namespaced: true,
    state: {
        contractDetail: {
            no: null,
            mgNo: "",
            bizCode: "",
            loanNo: "",
            docuNo: "",
            regType: "",
            kornNm: "",
            gender: "",
            hpNo: "",
            birthday: "",
            addr: "",
            info01: "",
            info02: "",
            info03: "",
            loanTrm: "",
            cntrctDt: "",
            matDt: "",
            loanAmt: "",
            aplyRate: 0,
            delayRate: 0,
            pymtDd: "",
            repayCatNo: "",
            repaySsn: "",
            nationalBondPurchaseAmt: "",
            judicialScrivenerFee: "",
            otherCostAmt: "",
            feeAmt: "",
            addCollateralPropertyAddress: "",
            addDocumentDate: "",
            lessor: "",
            lessorAddress: "",
            assignor: "",
            securityDeposit: "",
            leaseSecurityDepositClaim: "",
            transferDate: "",
            creditor: "",
            debtor: "",
            debtorBirthday: "",
            guaranteeDeposit: "",
            addMortgageAmount: "",
            repaymentDate: "",
            leaseAddress: "",
            seniorLender: "",
            seniorAmount: "",
            amountDebtAssignment: "",
            pwdCheck: 0,
        },
    },
    mutations: {
        CONTRACT_DETAIL(state, payload) {
            state.contractDetail = { ...payload }
            console.log(state.contractDetail)
        },
    },
    actions: {
        async FETCH_CONTRACT({ commit }, payload) {
            const response = await fetchContractDetail(payload);
            if (response.data.result === true) {
                commit('CONTRACT_DETAIL', response.data.contents);
            }
            return response
        },
    },
}

export default commonContractStore