<template>
    <div>

    </div>
</template>
<script>
export default {
    name: 'AuthenticationFailure',
    data() {
        return {
            message: '본인인증 검증이 실패된 계약입니다. \n고객센터에 문의해주세요.',
        };
    },
    created() {
        alert(this.message);
        window.close()
    },
};
</script>