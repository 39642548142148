<template>
  <div class="container" data-contract-company="aone" data-html-name="채권양도 및 양도담보제공승낙서">
    <!-- contract : 계약서 / manual : 설명서 -->
    <div class="manual">

      <header-black>
        <template #backBtn>
          <button class="backBtn_icon"></button>
        </template>
        <div slot="title">채권양도∙양도담보제공승낙서</div>
      </header-black>

      <main class="bottom_btn_case">

        <section class="default">
          <article class="alone_article">
            본 승낙서는 (주)에이원대부캐피탈과 채무자인 본인 간에 체결된 위 대부거래약정(금전소비대차계약)과 관련됩니다. 본인은 대부거래 표준약관 제17조에 의거, (주)에이원대부캐피탈과 현재 또는 장래에 자신이 부담하는 일체의 채무를 담보하기 위하여 위 대부거래약정과 관련하여 본인에 대하여 갚게 되는 일체의 금전채권을 제3자에게 양도 또는 담보 제공하는 것에 승낙하고 이에 대하여 아무런 이의를 제기하지 아니합니다. 다만, 위 금전채권의 이자 및 원금을 당해 양수인으로부터 별도의 사전 청구가 있기 전까지는 (주)에이원대부캐피탈에게 납입하도록 하겠습니다. 또한, 본인은 위 채권양도가 이뤄지는 경우 본인의 신용정보가 본인에 대한 신용판단 또는 채권추심 등을 위한 자료로 활용될 경우에 한하여 당해 양수인 및 양도담보권자에게 제공되는 것에 대해서도 동의합니다.<br/>
            다만, 그 외의 용도로 본인의 신용정보가 활용되거나 목적이 달성될 경우에는 본인의 신용정보의 활용, 통지, 파기, 또는 삭제를 요구할 수 있습니다.
          </article>    
        </section>

        <!-- main에 bottom_btn_case 추가필요 -->
        <section class="bottomBtn_wrap">
          <BottomBtn>
            <button 
              slot="bottom_btn" 
              class="primary_btn"
              :class="{ active: activeBtn }"
              :disabled="!activeBtn"
              @click="closeProvideSecurity()"
            >
              확인
            </button>
          </BottomBtn>
        </section>

      </main>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    closeProvideSecurity() {
        this.$emit('close')
    },
  },
}
</script>

<style>
  
</style>