import {store} from "@/store";

import regType20_01 from "@/views/aone/EC06AO_COOWNER.vue";

export default [
    {
        path: '/contract/vehicle/coowner',
        component: regType20_01,
        name: 'regType20_01',
        props: {nextComponent: 'CompleteContract'},
        beforeEnter: async (to, from, next) => {
            try {
                const response = await store.dispatch('AoneCommonContractStore/FETCH_CONTRACT', to.query.q)
                localStorage.removeItem('vuex');
                store.commit('AoneIndividualContractStore/RESET');
                if (response.data.result === true) {
                    next();
                } else {
                    localStorage.removeItem('vuex');
                    localStorage.removeItem('_secure__ls__metadata');
                    location.replace('/duplicateContractError');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
]