<template>
    <div class="container" data-contract-company="tflain" data-html-name="개인(신용)정보 조회 동의">
        <!-- contract : 계약서 / manual : 설명서 -->
        <div class="manual">

            <header-black>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">개인(신용)정보 조회 동의</div>
            </header-black>

            <main class="bottom_btn_case">
                <section class="default">

                    <article class="terms_article">
                        <div>
                            본인은「신용정보의 이용 및 보호에 관한 법률」제32조 제2항 및「개인정보 보호법」제24조에 따라 귀사가 본인이 신청한 대부거래에 관하여 아래와 같은 내용으 로 신용조회회사, 신용정보집중기관, 공공기관 및 통신회사로부터 본인의 신용정보를 조회하는 것에 대하여 동의합니다.
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">조회할 신용정보의 내용</div>
                        <div>
                            개인식별정보, 신용거래정보, 신용능력정보, 신용도 판단정보, 공공기관 정보 등
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">조회 목적</div>
                        <div>
                            계약 등 대부거래의 체결 - 유지 - 이행·관리
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">조회처</div>
                        <div>
                            신용조회회사, 신용정보집중기관, 공공기관 및 통신회사
                        </div>
                    </article>

                    <article class="terms_article">
                        <div class="terms_title">조회동의 효력기간</div>
                        <div>
                            상기 동의는 당해 거래종료일(채권 - 채무관계가 해소된 시점)까지 효력이 유지되나, 본인이 신청한 대부거래가 귀사에 의해 거절된 경우에는 그 시점까지 유효합니다.
                        </div>
                    </article>

                    <article class="terms_article">
                        <div>
                            ※ 신용정보법 제32조 제2항에 따라 상기 회사가 신용조회회사를 통하여 귀하의 신용정보를 조회한 기록에 의해 신용등급이 하락할 수 있음을 알려드립니다.<br/>
                            단, 본 신용조회는 신용평가 목적으로 타 금융기관에 제공되지 않으며, 무등급자의 신용평가 목적 이외에는 신용등급 산정에 반영되고 있지 않습니다.
                        </div>
                    </article>

                </section>

                <!-- main에 bottom_btn_case 추가필요 -->
                <section class="bottomBtn_wrap">
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            :class="{ active: activeBtn }"
                            :disabled="!activeBtn"
                            @click="closeStandardizedAgreement()"
                        >
                            확인
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        closeStandardizedAgreement() {
            this.$emit('close')
        },
    },
}
</script>

<style>

</style>