<template>
    <div>
        <h1>{{ test }}</h1>
        <div>
            <button style="background: #888888" @click="inicisTest()">
                <p>이니시스 테스트모드</p>
            </button>
        </div>
        <InicisComponent ref="openPopup" @electronicSignatureComplete="requestConsentAgreement()" />
    </div>
</template>
<script>
import InicisComponent from "@/components/InicisComponent.vue";
export default {
    components: {
        InicisComponent,
    },
    async created() {
        await this.$store.dispatch('inicisStore/FETCH_INICIS', '230502-ECM-0007')
    },
    data() {
        return {
            test: 'inicis test component'
        }
    },
    methods: {
        requestConsentAgreement() {

        },
        inicisTest() {
            this.$refs.openPopup.openPopup()
        },
    },
};
</script>