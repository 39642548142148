<template>
    <div class="container" data-contract-company="aone" data-html-name="대부거래 계약서_상품설명서 서명">
        <div class="contract" v-if="!showProductInstruction">

            <header-blue>
                <template #backBtn>
                    <button class="backBtn_icon"></button>
                </template>
                <div slot="title">대부거래 계약서</div>
            </header-blue>

            <main class="bottom_btn_case">

                <section class="default">
                    <article class="user_info_article confirmation">
                        <CheckboxDefault @show="openProductInstruction()">
                            <template #checkboxMainTitle>
                                <div class="left" ref="productInstruction">
                                    <div class="checkbox24_icon" :class="{active: productInstructionCheckbox }"></div>
                                    <div>대출 상품 설명서</div>
                                </div>

                                <button class="seedetail_icon" ref="productInstruction"></button>
                            </template>
                        </CheckboxDefault>
                        <div>
                            본인은 에이원대부캐피탈과 대부거래를 함에 있어 계약 체결 전에 대부회사 직원과 상담하여 아래에서 설명한 내용을 포함하여 대부거래의 주요내용 및 대부이용자가 부담하는
                            비용에 대하여 충분히 설명을 듣고 이해하였음을 확인합니다.
                            <br/><br/>
                            설명내용을 제대로 이해하지 못하였음에도 불구하고 설명을 이해했다는 서명을 하거나 녹취기록을 남기시는 경우, 추후 해당 내용과 관련한 권리구제가 어려울 수 있습니다.
                        </div>
                    </article>

                    <article>
                        <div class="label_case">상담일</div>
                        <div class="common_box single_data_case">
                            <div class="single_data">{{ contractDetail.cntrctDt | formatDate }}</div>
                        </div>
                    </article>

                    <article class="signature_article mb28">
                        <div data-sign="sign_field">
                            <div class="label_case">채무자(본인)</div>
                            <SignatureInput>
                                <template #signature>
                                    <input
                                        ref="inputField"
                                        type="text"
                                        id="sign"
                                        class="sign"
                                        :placeholder="contractDetail.kornNm"
                                        :value="returnName"
                                        :disabled="!signatureColor"
                                        :class="{ blue: signatureColor }"
                                        @input="isValidName"
                                        @blur="isValidKornNm"
                                    >
                                    <label for="sign" @click="openSignDebtor()">
                                        <button v-html="signedData"></button>
                                    </label>
                                </template>
                            </SignatureInput>
                        </div>
                    </article>

                    <article class="user_info_article">
                        <div class="common_box">
                            <div class="user_info_title">직위</div>
                            <div>{{ '심사역' }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">성명</div>
                            <div>{{ contractDetail.workerId }}</div>
                        </div>
                        <div class="common_box">
                            <div class="user_info_title">연락처</div>
                            <div>{{ '1566-0700' }}</div>
                        </div>
                    </article>

                </section>

                <section class="bottomBtn_wrap">
                    <div class="text">
                        대출거래 계약서로 이동합니다.<br>
                        내용을 확인하시고 입력해 주세요
                    </div>
                    <BottomBtn>
                        <button
                            slot="bottom_btn"
                            class="primary_btn"
                            @click="nextPage()"
                            :class="{ active: isDisabled }"
                        >
                            대출거래 계약하기
                        </button>
                    </BottomBtn>
                </section>

            </main>
        </div>

        <CommonModal v-if="isKornNm" @confirmBtn="confirmKornNmModal()">
            <div slot="ModalContent">
                <span class="emp_red">이름</span>을<br>
                잘못 입력하셨습니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isProductInstructionModal" @confirmBtn="checkProductInstructionModal()">
            <div slot="ModalContent">
                <span class="emp_red">대출 상품 설명서</span>를<br>
                확인해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isNameModal" @confirmBtn="checkNameModal()">
            <div slot="ModalContent">
                <span class="emp_red">채무자(본인)</span>을<br>
                작성해주셔야 합니다.
            </div>
        </CommonModal>

        <CommonModal v-if="isNameSignatureModal" @confirmBtn="checkNameSignatureModal()">
            <div slot="ModalContent">
                <span class="emp_red">본인서명</span>을<br>
                해주셔야 합니다.
            </div>
        </CommonModal>

        <ProductInstruction v-if="showProductInstruction" @close="closeProductInstruction"/>
        <SignatureDebtor v-if="showSignatureDebtor" ref="signaturePad" @close="closeSignatureDebtor()"
                         @save="saveSignatureDebtor" :propsName="contractDetail.kornNm"/>

    </div>
</template>

<script>
import {mapState, mapGetters,} from 'vuex'
import ProductInstruction from '@/views/common/aone/ProductInstruction.vue'
import SignatureDebtor from '@/components/common/SignaturePad.vue'

export default {
    components: {
        ProductInstruction,
        SignatureDebtor,
    },
    data() {
        return {
            contractDetail: null,
            showProductInstruction: false,
            showSignatureDebtor: false,
            name: '',
            isKornNm: false,

            // check
            isProductInstructionModal: false,
            isNameModal: false,
            isNameSignatureModal: false,
        }
    },
    created() {
        this.contractDetail = this.commonContractStore.contractDetail
        this.contractWorkTimeChecked(this.contractDetail.mgNo)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters('AoneIndividualContractStore', ['EC01AO']),
        ...mapState({
            commonContractStore: state => state.AoneCommonContractStore,
            individualContractStore: state => state.AoneIndividualContractStore,
        }),
        // 대출상품설명서 체크박스 활성화 Boolean
        productInstructionCheckbox() {
            return this.EC01AO.productInstructionCheckbox
        },
        // 사인 데이터 관련
        signedData() {
            const data = this.EC01AO.signedData
            if (data.startsWith('data:image')) {
                return `<img width='80' height='38' src='${data}' alt="">`
            } else {
                return data
            }
        },
        returnName() {
            return this.EC01AO.name
        },
        signatureColor() {
            return this.EC01AO.nameColorValid
        },
        // 다음페이지로 넘어가기 위한 조건
        isDisabled() {
            const setPath = this.EC01AO
            return setPath.productInstructionCheckbox && setPath.nameValid && setPath.signedDataValid
        },
    },
    methods: {
        openProductInstruction() {
            this.showProductInstruction = !this.showProductInstruction
        },
        closeProductInstruction() {
            this.showProductInstruction = !this.showProductInstruction
            this.$store.commit('AoneIndividualContractStore/PRODUCT_INSTRUCTION_MODAL', true)
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            })
        },
        openSignDebtor() {
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        closeSignatureDebtor() {
            this.showSignatureDebtor = false
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            });
        },
        saveSignatureDebtor() {
            const result = this.$refs.signaturePad.saveSignature()
            if (result.isEmpty) {
                alert('서명이 비어 있습니다');
            } else {
                this.$store.commit('AoneIndividualContractStore/EC01AO_SIGNATURE_DEBTOR', {
                    data: `${result.data}`,
                    isValid: true
                })
            }
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        isValidName(e) {
            if (this.contractDetail.kornNm === e.target.value) {
                this.$store.commit('AoneIndividualContractStore/EC01AO_SIGNATURE_NAME', {
                    name: e.target.value,
                    isValid: true
                })
                this.$store.commit('AoneIndividualContractStore/EC01AO_SIGNATURE_COLOR', false)
                this.$refs.inputField.blur();
            }
        },
        isValidKornNm(e) {
            if (this.contractDetail.kornNm !== e.target.value) {
                this.isKornNm = true
            }
        },
        confirmKornNmModal() {
            this.isKornNm = false
        },
        checkProductInstructionModal() {
            this.isProductInstructionModal = false
            this.$refs.productInstruction.focus();
        },
        checkNameModal() {
            this.isNameModal = false
            this.$refs.inputField.focus()
        },
        checkNameSignatureModal() {
            this.isNameSignatureModal = false
            this.showSignatureDebtor = !this.showSignatureDebtor
        },
        nextPage() {
            if (!this.productInstructionCheckbox) {
                this.isProductInstructionModal = true
            } else if (this.signatureColor) {
                this.isNameModal = true
            } else if (!this.EC01AO.signedDataValid) {
                this.isNameSignatureModal = true
            } else {
                this.$router.push({name: this.nextComponent});
            }
        },
    }
}
</script>

<style>

</style>